  <div class="p-grid p-nogutter">
    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="addform p-grid p-nogutter">
        <div class="overlay" *ngIf="addloading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="innerdiv p-md-9 p-lg-7 p-xl-6">
          <h4 *ngIf="!itemEditID">Rent Now, Earn Money</h4>
          <h4 *ngIf="itemEditID">Listing Edit</h4>
          <br>
          <form class="p-mx-md-6" [formGroup]=" informationHolder" (ngSubmit)="insertItem()">
            <!-- Title-->
            <div class="form-group">
              <label>Title *</label>
              <input [class.is-invalid]="title.invalid && title.touched" formControlName="title" type="text"
                class="form-control" pInputText maxlength="50" placeholder="Enter Title">
              <div *ngIf="title.invalid && title.touched">
                <small *ngIf="title.errors?.required" class="text-danger">Title is required</small>
              </div>
            </div>
            <!-- Location -->
            <div class="form-group">
              <label>Zip Code *</label>
              <input (input)="onchange()" [class.is-invalid]="location.touched && !latitude" formControlName="location" type="text"
                class="form-control" pInputText #search autocorrect="off" autocapitalize="off" spellcheck="off">
              <div *ngIf="location.invalid && location.touched">
                <small *ngIf="location.errors?.required" class="text-danger">Zip Code is required</small>
              </div>
            </div>
            <!-- Type -->
            <div class="form-group">
              <label>Type *</label>
              <p-dropdown *ngIf="!itemEditID" [options]="types" formControlName="type" [style]="{'width':'100%'}"
                placeholder="Select the Type" optionLabel="categoryName">
              </p-dropdown>
              <p-dropdown *ngIf="itemEditID" [options]="types" formControlName="type" [style]="{'width':'100%'}"
              autoDisplayFirst="true" optionLabel="categoryName">
              </p-dropdown>
              <div *ngIf="type.invalid && type.touched">
                <small *ngIf="type.errors?.required" class="text-danger">Type of product is required</small>
              </div>
            </div>
            <!-- Category -->
            <div class="form-group">
              <label>Category *</label>
              <div class="flexedSpaceAround">
                <p-checkbox value="men" label="Men" [formControl]="informationHolder.controls['category']">
                </p-checkbox>
                <p-checkbox value="women" label="Women" [formControl]="informationHolder.controls['category']">
                </p-checkbox>
                <p-checkbox value="children" label="Children" [formControl]="informationHolder.controls['category']">
                </p-checkbox>
              </div>
              <div *ngIf="category.invalid && category.touched">
                <small *ngIf="category.errors?.required" class="text-danger">Category of product is required</small>
              </div>
            </div>
            <!-- Dates -->
            <div class="form-group">
              <label>Available Dates *</label><br>
              <p-calendar [minDate]="minStartDate" showIcon="true" [style]="{'width':'50%'}" (onSelect)="startDateSelect()"  formControlName="startDate"
                placeholder='Available From'></p-calendar>
              <p-calendar [minDate]="minEndDate" showIcon="true" [style]="{'width':'50%'}" formControlName="endDate"
                placeholder='Available Till'></p-calendar>
                <!-- <small *ngIf="!informationHolder.get('endDate').value" class="warn">Note: If you don't select dates, your product will expire after a month!</small> -->
            </div>
            <!-- Price -->
            <div class="form-group">
              <label>Price *</label>
              <input [class.is-invalid]="price.invalid && price.touched" formControlName="price" type="number"
                class="form-control" pInputText>
              <div *ngIf="price.invalid && price.touched">
                <small *ngIf="price.errors?.required" class="text-danger">Price is required</small>
                <small *ngIf="price.errors?.max" class="text-danger">Price is too much</small>
                <small *ngIf="price.errors?.min" class="text-danger">Price must be greater than
                  zero</small>
              </div>
            </div>
            <!-- Selection for other day different price -->
            <div class="form-group">
              <p-checkbox formControlName='nextdayPrice' [binary]="true" (onChange)='onOtherPriceClick($event)'
                label="Use different Price for other days">
              </p-checkbox>
            </div>
            <!-- Price For other Days -->
            <div formArrayName="differentPrice" *ngFor="let price of differentPrice.controls; let i=index">
              <div class="form-group">
                <label>Price for Other Days *</label>
                <input [class.is-invalid]="differentPrice.touched && differentPrice.invalid" [formControlName]="i"
                  type="number" class="form-control" pInputText>
                <div *ngIf="differentPrice.invalid && differentPrice.touched">
                  <small class="text-danger">Price is required and must be greater Than zero</small>
                </div>
              </div>
            </div>
            <!-- Descriptions -->
            <div class="form-group">
              <label>Description *</label>
              <textarea [class.is-invalid]="description.invalid && description.touched" formControlName="description"
                type="text" class="form-control" pInputTextarea autoResize="autoResize"></textarea>
              <div *ngIf="description.invalid && description.touched">
                <small *ngIf="description.errors?.required" class="text-danger">Description is required</small>
                <small *ngIf="description.errors?.maxlength" class="text-danger">Description cannot be greater than 1000
                  characters</small>
              </div>
            </div>

            <!-- image uploader -->

            <div class="upload form-group">
              <label>Choose Images *</label>
              <div class="picItem" *ngIf="itemEditID">
                <div class="outerPrevPics">
                  <div *ngFor="let pic of previousPhotos">
                    <div (click)="removeImage(pic)" class="prevPic" [style.backgroundImage]="'url('+ pic +')'"><div class="imgHover"><i class="pi pi-times crossBtn"></i></div></div>
                </div>
                </div>
              </div>
              <p-fileUpload [style]="{'background-color':'#ee6956','border':'#ee6956'}" [fileLimit]="fileLimit" [files]="files"
                invalidFileTypeMessageDetail="Allowed File Types: {0}"
                invalidFileLimitMessageDetail = "Limit is 5 at most"
                invalidFileTypeMessageSummary="{0}: Invalid File Type" customUpload="true" mode="advanced"
                accept=".jpg, .png, .jpeg" [showUploadButton]="false" multiple="multiple"
                (onSelect)="picUploader($event)" (onRemove)="onRemove()" (onClear)="onClear()">
              </p-fileUpload>
            </div>
            <br>

            <!-- Submit Button -->
            <button type="submit" class="btn" label="Submit" [disabled]="!informationHolder.valid || isMoreFiles || !longitude || !latitude || !filesLength"
              pButton></button>
          </form>
        </div>
      </div>
    </div>
  </div>
