import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { LoginsignupService } from '../../services/loginsignup.service';
import { Router } from '@angular/router';
import { SignupComponent } from '../signup/signup.component';
import { ForgetPasswordComponent } from '../../shared/forget-password/forget-password.component';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [DialogService],
})
export class LoginComponent implements OnInit {
  //Picture Url
  pictureUrl: '../../../assets/images/dp.png';

  email;
  pass;
  errmsg: string = null;
  errmsgemail: string = null;
  typePassword: string = 'password';
  checkDisable: boolean = false;

  constructor(
    private _service: LoginsignupService,
    private router: Router,
    private dialodeServies: DialogService,
    public _toast: SearchKeywordService
  ) {}

  ngOnInit(): void {}

  showDialog() {
    this.dialodeServies.open(SignupComponent, {
      contentStyle: { height: 'fit-content', overflow: 'auto' },
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
    });
  }

  signin() {
    this.errmsg = '';
    this.errmsgemail = '';
    if (this.email === undefined || this.email == '') {
      this.errmsgemail = 'Email is required';
    } else if (this.pass === undefined || this.pass == '') {
      this.errmsg = 'Password is required';
    } else if (!this.ValidateEmail(this.email)) {
      this.errmsgemail = 'Please enter correct Email';
    } else {
      this.checkDisable = true;
      this._service
        .emailsignin(this.email, this.pass)
        .then(() => {
          this.checkDisable = false;
        })
        .catch((err) => {
          this.errmsg = '';
          this._toast.nextMessage(err.message, 'error');
          this.checkDisable = false;
        });
    }
  }

  fblogin() {
    this._service.fbsu(true);
  }

  glogin() {
    this._service.googlesu(true);
  }
  ValidateEmail(input) {
    var mailformat = /^[[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]/;
    if (input.match(mailformat)) {
      return true;
    }
    return false;
  }
  forgot() {
    this.dialodeServies.open(ForgetPasswordComponent, {
      contentStyle: { 'max-height': 'fit-content', overflow: 'auto' },
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
    });
  }
  routeHome() {
    this.router.navigateByUrl('/home');
  }

  passView() {
    if (this.typePassword == 'password') {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }

  backHome() {
    this.router.navigateByUrl('/home');
  }
}
