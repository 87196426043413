import { Component, OnInit } from '@angular/core';
import { LoginsignupService } from '../../services/loginsignup.service';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { Router } from '@angular/router';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { SignupComponent } from 'src/app/client/signup/signup.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService],
})
export class HeaderComponent implements OnInit {
  islogin;
  dp;
  user;
  items: MegaMenuItem[];
  menuitems: MenuItem[];
  searchkeyword;
  ifSeen: boolean;
  loading: boolean = true;
  subscriptions;

  constructor(
    public _chat: ChatService,
    private _service: LoginsignupService,
    private dialodeServies: DialogService,
    private _fetch: FetchitemsService,
    private router: Router,
    public _toast: SearchKeywordService
  ) {
    this.subscriptions = new Array<Subscription>();
    this.dp = '../../../assets/images/dp.png';

    this.subscriptions.push(
      (this.islogin = _service.isSignin().subscribe((data) => {
        if (data) {
          this.subscriptions.push(
            _chat.ifUnseen(data.uid).subscribe((ifseen) => {
              this.ifSeen = ifseen;
              this.loading = false;
            })
          );

          this.user = this._fetch.fetchuserbyIdasync(data.uid);
          this.islogin = true;
          this.menuitems = [
            {
              label: 'About',
              command: () => {
                window.open('https://saagsharetribe.wordpress.com/tag/saag/','_blank');
              },
            },
            {
              label: 'Blog',
              command: () => {
                window.open('https://saagsharetribe.wordpress.com/','_blank');
              },
            },
            { label: 'Contact', routerLink: '/feedback' },
            { label: 'Become a Supplier', routerLink: '/additem' },
          ];
        } else {
          this.islogin = false;
          this.menuitems = [
            {
              label: 'About',
              command: () => {
                window.open('https://saagsharetribe.wordpress.com/tag/saag/','_blank');
              },
            },
            {
              label: 'Blog',
              command: () => {
                window.open('https://saagsharetribe.wordpress.com/','_blank');
              },
            },
            { label: 'Contact', routerLink: '/feedback' },
            {
              label: 'Register',
              command: () => {
                this.showDialog();
              },
            },
          ];
          this.loading = false;
        }
      }))
    );

    this.items = [
      {
        icon: 'pi pi-align-center',
        items: [
          [
            {
              items: [
                { label: 'Profile', routerLink: '/profile' },
                { label: 'My Items', routerLink: '/myitems/ordered' },
                {
                  label: 'Chats',
                  routerLink: '/chats',
                  icon: 'pi pi-circle-on',
                },
                { label: 'List your Gear', routerLink: '/additem' },
                { label: 'Feedback', routerLink: '/feedback' },
                {
                  label: 'Logout',
                  command: () => this.logout(),
                  routerLink: '/login',
                },
              ],
            },
          ],
        ],
      },
    ];
  }

  showDialog() {
    this.dialodeServies.open(SignupComponent, {
      contentStyle: { height: 'fit-content', overflow: 'auto' },
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
    });
  }

  ngOnInit(): void {}

  logout() {
    this._service.signout();
  }

  imgLogo() {
    this.router.navigateByUrl('/home');
  }

  dpClick() {
    this.router.navigateByUrl('/profile');
  }

  warning() {
    this._toast.nextMessage('You need to Login first!', 'warn');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
