<div class="bg">

  <div class="p-grid p-nogutter bigdiv">
    <div class="main-login p-col-12 p-md-12 p-lg-12">
      <div class="loginform">
        <!-- <div> -->

        <!-- logo -->
        <div class="flex-container">
          <i (click)="backHome()" class="fa fa-arrow-left backHome"></i>
          <!-- logo on page  -->
          <div class="pic " (click)="routeHome()">
          </div>
        </div>
        <div class="p-mt-3">
          <label>Email:</label>
          <input [(ngModel)]="email" maxlength="50" type="email" pInputText placeholder="Email" (keyup.enter)="signin()"
            [style]="{'width':'100%'}">
        </div>
        <small class="error" *ngIf="errmsgemail">{{ errmsgemail }}</small><br>
        <div class="passField">
          <label>Password:</label>
          <i *ngIf='typePassword=="password"' class="fa fa-eye-slash" id="togglePassword" (click)="passView()"></i>
          <i *ngIf='typePassword=="text"' class="fa fa-eye" id="togglePassword" (click)="passView()"></i>
          <input [(ngModel)]="pass" maxlength="50" [type]="typePassword" pInputText placeholder="Password"
            (keyup.enter)="signin()" [style]="{'width':'100%'}">
        </div>
        <div *ngIf="errmsg"><small class="error">{{ errmsg }}</small><br></div><br>

        <div class="p-grid">
          <div class="p-col-6">
            <a style="color: #ee6956;" (click)="forgot()"><small>Forgot Password?</small></a><br>
          </div>
          <div class="register p-col-6">
            <a (click)="showDialog()"><small>New? </small><small style="color: #ee6956;">Register
                here.</small></a><br>
          </div>
        </div>

        <!-- login Button -->
        <div class="">
          <button [disabled]="checkDisable" pButton type="button" class="loginBtn p-button-rounded" label="Login"
            (click)="signin()" [style]="{'width':'100%'}"><i *ngIf="checkDisable" class="pi pi-spin pi-spinner"></i><i
              *ngIf="!checkDisable" class="fa fa-sign-in"></i></button>
        </div>
        <small>Or, login with</small>
        <br>
        <!-- goolge login button -->
        <button (click)="fblogin()" label="Sign in with Facebook" pButton type="button" icon='fa fa-facebook'
          [style]="{'width':'100%'}" class=" p-mt-3 facebook p-button-rounded"></button>

        <!-- goolge login button -->
        <button (click)="glogin()" pButton type="button" class="fa-google" label="Sign in with Google"
          [style]="{'width':'100%'}" class="googleBtn p-mt-3 p-button-rounded">
          <div class="googleIcon"> <i class="fa fa-google "></i></div>
        </button>

      </div>
    </div>
  </div>
</div>