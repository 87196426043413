import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { SearchKeywordService } from './search-keyword.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import *as Template from '../shared/email-templates/templates.js'

@Injectable({
  providedIn: 'root',
})
export class UpdateItemService {
  subscriptions;

  constructor(
    private _fireStore: AngularFirestore,
    private _fireStorage: AngularFireStorage,
    private _http: HttpClient,
    private _toast: SearchKeywordService,
  ) {
    this.subscriptions = new Array<Subscription>();
  }

  async insertItem(item_details) {
    return await this._fireStore.collection('ItemList').add(item_details);
  }

  async EditItem(item_details, id) {
    return await this._fireStore
      .collection('ItemList')
      .doc(id)
      .update(item_details);
  }

  async insertComplaint(item_details) {
    return await this._fireStore.collection('Complaints').add(item_details);
  }

  async insertComplaintSupplier(item_details) {
    return await this._fireStore.collection('Complaints').add(item_details);
  }

  completeOrder(id) {
    this._fireStore
      .collection('OrderItems')
      .doc(id)
      .update({ isCompleted: true });
  }

  approveOrder(id) {
    this._fireStore
      .collection('OrderItems')
      .doc(id)
      .update({isConfirmed: true}).then(() => {
      this._toast.nextMessage(
        'The order has been approved. Email has been sent to you and the renter',
        'success',
      );
    })
      .catch((err) => {
        this._toast.nextMessage(
          err.message,
          'error',
        );
      });
  }

  async denyOrder(id) {
    try {
      const orders = await (this._fireStore
        .collection('OrderItems', (ref) => ref.where('orderID', '==', id))
        .get().toPromise());
      const order = orders.docs[0].data();
      const payment = await (this._http.post(
        `${environment.firebaseFunctionUrl}/refundStripe`,
        {
          charge_id: order.paymentObject['paymentObject'].id,
          amount: order.totalPrice * 100 * 1.25,
        },
      ).toPromise());
      await this._fireStore
        .collection('OrderItems')
        .doc(order.orderID)
        .update({
          isCompleted: true,
          canceledPaymentObject: payment,
          isCanceled: true,
          canceledAfter48: false,
          canceledBefor48: true,
        });
      this._toast.nextMessage(
        'The order has been denied. Email has been sent to you and the renter',
        'success',
      );
      return true;
    } catch (err) {
      console.error(err.message);
      this._toast.nextMessage(
        'Something went wrong. Please try again later',
        'error',
      );
      return false;
    }
  }

  userRating(userID, rating) {
    this.subscriptions.push(
      this._fireStore
        .collection('Users', (ref) => ref.where('uid', '==', userID))
        .get()
        .subscribe((data) => {
          let docID = data.docs[0].id;
          let supplierRating = data.docs[0].get('supplierRating');
          this._fireStore
            .collection('Users')
            .doc(docID)
            .update({
              supplierRating:
                (supplierRating * 5 + parseInt(rating)) / (supplierRating + 1),
            });
        })
    );
  }

  supplierReviewed(id) {
    this._fireStore
      .collection('OrderItems')
      .doc(id)
      .update({ supplierReview: true });
  }

  renterReviewed(id) {
    this._fireStore
      .collection('OrderItems')
      .doc(id)
      .update({ renterReview: true });
  }

  userRatingfromSupplier(userID, rating) {
    this.subscriptions.push(
      this._fireStore
        .collection('Users', (ref) => ref.where('uid', '==', userID))
        .get()
        .subscribe((data) => {
          let docID = data.docs[0].id;
          let renterRating = data.docs[0].get('renterRating');
          this._fireStore
            .collection('Users')
            .doc(docID)
            .update({
              renterRating:
                (renterRating * 5 + parseInt(rating)) / (renterRating + 1),
            });
        })
    );
  }

  itemRating(itemID, rating) {
    this.subscriptions.push(
      this._fireStore
        .collection('ItemList', (ref) => ref.where('id', '==', itemID))
        .get()
        .subscribe((data) => {
          let docID = data.docs[0].id;
          let totalRating = data.docs[0].get('totalRating');
          this._fireStore
            .collection('ItemList')
            .doc(docID)
            .update({
              totalRating:
                (totalRating * 5 + parseInt(rating)) / (totalRating + 1),
            });
        })
    );
  }

  async uploadnewItem(images) {
    let promises = [];
    if (images) {
      for (let file of images) {
        let name = new Date();
        let storageRef = this._fireStorage.ref('files/' + name.valueOf());
        await promises.push(
          await storageRef.put(file).then((snapshot) => {
            return snapshot.ref.getDownloadURL();
          })
        );
      }
    }
    return Promise.all(promises);
  }

  uploadFeedback(obj) {
    return this._fireStore
      .collection('FeedBacks')
      .add(obj)
      .then((docRef) => {
        docRef.update({ id: docRef.id });
      });
  }

  updateItem(uid) {
    return this._fireStore.collection('ItemList').doc(uid).update({ id: uid });
  }

  updateComplaint(uid) {
    return this._fireStore
      .collection('Complaints')
      .doc(uid)
      .update({ complainID: uid });
  }

  updateComplaintSupplier(uid) {
    return this._fireStore
      .collection('Complaints')
      .doc(uid)
      .update({ complainID: uid });
  }

  async upadateuser(newData, userData) {
    this._fireStore
      .collection('Users')
      .ref.where('uid', '==', userData.uid)
      .get()
      .then((docref) => {
        var docid = docref.docs[0].id;
        return this._fireStore.collection('Users').doc(docid).update(newData);
      });
  }

  upadteUserProfile(file, name) {
    return this._fireStorage.ref('' + name).put(file);
  }
  deletepreviousPicture(imgUrl) {
    this._fireStorage.storage.refFromURL(imgUrl).delete();
  }
  putOrderItem(obj) {
    return this._fireStore
      .collection('OrderItems', (ref) =>
        ref.where('paymentObject.id', '==', obj.paymentObject.paymentObject.id)
      )
      .get()
      .pipe(
        map((orders) => {
          if (!orders.docs.length) {
            return this._fireStore
              .collection('OrderItems')
              .add(obj)
              .then((snapshot) => {
                snapshot.update({ orderID: snapshot.id });
              });
          } else {
            return Promise.resolve();
          }
        })
      );
  }

  addComment(comment, userID, itemID) {
    this.subscriptions.push(
      this._fireStore
        .collection('Users', (ref) => ref.where('uid', '==', userID))
        .get()
        .subscribe((user) => {
          this._fireStore
            .collection('Comments')
            .add({
              comment: comment,
              userID: user.docs[0].get('uid'),
              itemID: itemID,
              commentDate: new Date(),
            })
            .then((docRef) => {
              docRef.update({ commentID: docRef.id });
            });
        })
    );
  }

  // pickedUpTransaction(orderid) {
  //   let myOrder;
  //   let response;
  //   let username;
  //   let password;
  //   return this._fireStore
  //     .collection('Environment')
  //     .doc('variables')
  //     .get()
  //     .pipe(
  //       map((vars) => {
  //         username = vars.data().paypalUsername;
  //         password = vars.data().paypalPassword;
  //         return this._fireStore
  //           .collection('OrderItems', (ref) =>
  //             ref.where('orderID', '==', orderid)
  //           )
  //           .get()
  //           .pipe(
  //             map((orders) => {
  //               myOrder = orders.docs[0].data();
  //               return this._fireStore
  //                 .collection('Users', (ref) =>
  //                   ref.where('uid', '==', myOrder.supplierID)
  //                 )
  //                 .get()
  //                 .pipe(
  //                   map((users) => {
  //                     console.log("Users are -----",users);
  //                     var data = 'grant_type=client_credentials';
  //                     var headers_object = new HttpHeaders();
  //                     headers_object = headers_object.append(
  //                       'Content-Type',
  //                       'application/x-www-form-urlencoded'
  //                     );
  //                     headers_object = headers_object.append(
  //                       'Authorization',
  //                       'Basic ' + btoa(username + ':' + password)
  //                     );
  //                     return this._http
  //                       .post(
  //                         `${environment.paypalBaseUrl}/oauth2/token`,
  //                         data,
  //                         { headers: headers_object }
  //                       )
  //                       .pipe(
  //                         map((data) => {
  //                           response = data;
  //                           const header = new HttpHeaders()
  //                             .set('Content-Type', 'application/json')
  //                             .set(
  //                               'Authorization',
  //                               'Bearer ' + response.access_token
  //                             );
  //                           return this._http
  //                             .post(
  //                               `${environment.paypalBaseUrl}/payments/payouts`,
  //                               {
  //                                 sender_batch_header: {
  //                                   sender_batch_id: new Date().valueOf(),
  //                                   email_subject: 'You have a payout!',
  //                                   email_message:
  //                                     'You have received a payout! Thanks for using our service!',
  //                                 },
  //                                 items: [
  //                                   {
  //                                     recipient_type: 'EMAIL',
  //                                     amount: {
  //                                       value: myOrder.totalPrice,
  //                                       currency: 'USD',
  //                                     },
  //                                     note: 'Thanks for your patronage!',
  //                                     receiver: users.docs[0].data()
  //                                       .paypalEmail,
  //                                   },
  //                                 ],
  //                               },
  //                               { headers: header }
  //                             )
  //                             .pipe(
  //                               map((payment) => {
  //                                 return this._fireStore
  //                                   .collection('OrderItems')
  //                                   .doc(myOrder.orderID)
  //                                   .update({
  //                                     isCompleted: true,
  //                                     completedPaymentObject: payment,
  //                                   });
  //                               })
  //                             );
  //                         })
  //                       );
  //                   })
  //                 );
  //             })
  //           );
  //       })
  //     );
  // }

  // stripe_user_id
  pickedUpStripTransaction(orderid) {
    console.log("pickedUpStripTransaction,,,,",orderid)
    let myOrder;
    let response;
      return this._fireStore
            .collection('OrderItems', (ref) =>
              ref.where('orderID', '==', orderid)
            )
            .get()
            .pipe(
              map((orders) => {
                myOrder = orders.docs[0].data();
                console.log("orders====",orders.docs[0].data())
                return this._fireStore
                  .collection('Users', (ref) =>
                    ref.where('uid', '==', myOrder.supplierID)
                  )
                  .get()
                  .pipe(
                    map((users) => {
                      console.log("users=========",users.docs[0].data());
                      return this._http.post(`${environment.firebaseFunctionUrl}/stripeTransferPayment`,
                      {
                        account_id: users.docs[0].data().stripe_user_id,
                        amount:myOrder.totalPrice
                      })
                      .pipe(
                        map((payment) => {
                          console.log("paymmmenttt...",payment)
                          return this._fireStore
                                    .collection('OrderItems')
                                    .doc(myOrder.orderID)
                                    .update({
                                      isCompleted: true,
                                      completedPaymentObject: payment,
                                    })
                        }))
                    }))
              }))
  }
  pickedUpStripeErrorTransaction(orderid,errorMessage) {
    let myOrder;
    let response;
      return this._fireStore
            .collection('OrderItems', (ref) =>
              ref.where('orderID', '==', orderid)
            )
            .get()
            .pipe(
              map((orders) => {
                myOrder = orders.docs[0].data();
                console.log("orders====",orders.docs[0].data())
                return this._fireStore
                  .collection('Users', (ref) =>
                    ref.where('uid', '==', myOrder.supplierID)
                  )
                  .get()
                  .pipe(
                    map((users) => {
                      console.log("users=========",users.docs[0].data());
                      var obj = {
                        errorMsg: errorMessage,
                        amount: myOrder.totalPrice,
                        orderID: myOrder.orderID,
                        supplierID: myOrder.supplierID,
                        startDate: myOrder.startDate,
                        endDate: myOrder.endDate,
                        supplierEmail: users.docs[0].data().email,
                        stripe_user_id: users.docs[0].data().stripe_user_id
                      }
                      console.log("objwcc",obj);
                      var dataEmail={
                        email:"info@saag.io",
                        message: Template.ErrorSupplierTransfer(obj)
                      }
                      return this._http.post(`${environment.firebaseFunctionUrl}/sendEmail`,
                      {
                        data1:dataEmail
                      })
                      .pipe(
                        map((payment) => {
                          console.log("paymmmenttt...",payment)
                          return this._fireStore
                          .collection('OrderItems')
                          .doc(myOrder.orderID)
                          .update({
                            isCompleted: true,
                            completedPaymentObject: true,
                          })
                        }))
                    }))
              }))
  }

  // cancelOrder(orderid) {
  //   let mySupplier;
  //   let links;
  //   let response;
  //   let username;
  //   let password;

  //   return this._fireStore
  //     .collection('Environment')
  //     .doc('variables')
  //     .get()
  //     .pipe(
  //       map((variables) => {
  //         password = variables.data().paypalPassword;
  //         username = variables.data().paypalUsername;
  //         return this._fireStore
  //           .collection('OrderItems')
  //           .doc(orderid)
  //           .get()
  //           .pipe(
  //             map((order) => {
  //               links = order.data().paymentObject.transactions[0]
  //                 .related_resources[0].sale.links;
  //               let i = this.findRefund(JSON.stringify(links));
  //               var data = 'grant_type=client_credentials';
  //               var headers_object = new HttpHeaders();
  //               headers_object = headers_object.append(
  //                 'Content-Type',
  //                 'application/x-www-form-urlencoded'
  //               );
  //               headers_object = headers_object.append(
  //                 'Authorization',
  //                 'Basic ' + btoa(username + ':' + password)
  //               );
  //               return this._fireStore
  //                 .collection('Users', (ref) =>
  //                   ref.where('uid', '==', order.data().supplierID)
  //                 )
  //                 .valueChanges()
  //                 .pipe(
  //                   map((supplier) => {
  //                     mySupplier = supplier[0];
  //                     return this._http
  //                       .post(
  //                         `${environment.paypalBaseUrl}/oauth2/token`,
  //                         data,
  //                         { headers: headers_object }
  //                       )
  //                       .pipe(
  //                         map((data) => {
  //                           response = data;
  //                           if (
  //                             moment(
  //                               new Date(order.data().startDate.seconds * 1000)
  //                             ).diff(moment(new Date()), 'days') < 2
  //                           ) {
  //                             const header = new HttpHeaders()
  //                               .set('Content-Type', 'application/json')
  //                               .set(
  //                                 'Authorization',
  //                                 'Bearer ' + response.access_token
  //                               );
  //                             return this._http
  //                               .post(
  //                                 `${environment.paypalBaseUrl}/payments/payouts`,
  //                                 {
  //                                   sender_batch_header: {
  //                                     sender_batch_id: new Date().valueOf(),
  //                                     email_subject: 'You have a payout!',
  //                                     email_message:
  //                                       'You have received a payout! Thanks for using our service!',
  //                                   },
  //                                   items: [
  //                                     {
  //                                       recipient_type: 'EMAIL',
  //                                       amount: {
  //                                         value: order.data().totalPrice / 2,
  //                                         currency: 'USD',
  //                                       },
  //                                       note: 'Thanks for your patronage!',
  //                                       receiver: mySupplier.paypalEmail,
  //                                     },
  //                                     {
  //                                       recipient_type: 'EMAIL',
  //                                       amount: {
  //                                         value: order.data().totalPrice / 2,
  //                                         currency: 'USD',
  //                                       },
  //                                       note: 'Thanks for your patronage!',
  //                                       receiver: order.data().paymentObject
  //                                         .payer.payer_info.email,
  //                                     },
  //                                   ],
  //                                 },
  //                                 { headers: header }
  //                               )
  //                               .pipe(
  //                                 map((payment) => {
  //                                   return this._fireStore
  //                                     .collection('OrderItems')
  //                                     .doc(order.data().orderID)
  //                                     .update({
  //                                       isCompleted: true,
  //                                       canceledPaymentObject: payment,
  //                                       isCanceled: true,
  //                                       canceledAfter48: true,
  //                                       canceledBefor48: false,
  //                                     })
  //                                     .then(() => {
  //                                       this._toast.nextMessage(
  //                                         'Your order has been cancelled. Check your email for the cancelled order',
  //                                         'success'
  //                                       );
  //                                     })
  //                                     .catch((err) => {
  //                                       this._toast.nextMessage(
  //                                         err.message,
  //                                         'error'
  //                                       );
  //                                     });
  //                                 })
  //                               );
  //                           } else {
  //                             const header = new HttpHeaders()
  //                               .set('Content-Type', 'application/json')
  //                               .set(
  //                                 'Authorization',
  //                                 'Bearer ' + response.access_token
  //                               );
  //                             return this._http
  //                               .post(i.href, {}, { headers: header })
  //                               .pipe(
  //                                 map((payment) => {
  //                                   return this._fireStore
  //                                     .collection('OrderItems')
  //                                     .doc(order.data().orderID)
  //                                     .update({
  //                                       isCompleted: true,
  //                                       canceledPaymentObject: payment,
  //                                       isCanceled: true,
  //                                       canceledAfter48: false,
  //                                       canceledBefor48: true,
  //                                     })
  //                                     .then(() => {
  //                                       this._toast.nextMessage(
  //                                         'Your order has been cancelled. Check your email for the cancelled order',
  //                                         'success'
  //                                       );
  //                                     })
  //                                     .catch((err) => {
  //                                       this._toast.nextMessage(
  //                                         err.message,
  //                                         'error'
  //                                       );
  //                                     });
  //                                 })
  //                               );
  //                           }
  //                         })
  //                       );
  //                   })
  //                 );
  //             })
  //           );
  //       })
  //     );
  // }
  cancelRefundOrder(orderid){
    let myOrder;
    let response;
      return this._fireStore
            .collection('OrderItems', (ref) =>
              ref.where('orderID', '==', orderid)
            )
            .get()
            .pipe(
              map((orders) => {
                myOrder = orders.docs[0].data();
                console.log("orders====",orders.docs[0].data())
                return this._fireStore
                  .collection('Users', (ref) =>
                    ref.where('uid', '==', myOrder.supplierID)
                  )
                  .get()
                  .pipe(
                    map((users) => {
                      if (
                        moment(
                          new Date(myOrder.startDate.seconds * 1000)
                        ).diff(moment(new Date()), 'days') < 2
                      ){
                      console.log("users=========",users.docs[0].data());
                      console.log("myOrder.paymentObject-------------",myOrder.paymentObject);
                      return this._http.post(
                        `${environment.firebaseFunctionUrl}/refundStripe`,
                      {
                        charge_id: myOrder.paymentObject['paymentObject'].id,
                        amount: myOrder.totalPrice * 100
                      }
                      )
                      .pipe(
                        map((payment) => {
                          return this._fireStore
                            .collection('OrderItems')
                            .doc(myOrder.orderID)
                            .update({
                              isCompleted: true,
                              canceledPaymentObject: payment,
                              isCanceled: true,
                              canceledAfter48: false,
                              canceledBefor48: true,
                            })
                            .then(() => {
                              this._toast.nextMessage(
                                'Your order has been cancelled. Check your email for the cancelled order',
                                'success'
                              );
                            })
                            .catch((err) => {
                              this._toast.nextMessage(
                                err.message,
                                'error'
                              );
                            });
                        })
                      );
                      }else{
                      console.log("users=========",users.docs[0].data());
                      console.log("myOrder.paymentObject-------------",myOrder.paymentObject);
                      return this._http.post(`${environment.firebaseFunctionUrl}/refundStripe`,
                      {
                        charge_id: myOrder.paymentObject['paymentObject'].id,
                        amount: myOrder.totalPrice * 100
                      }
                      )
                      .pipe(
                        map((payment) => {
                          return this._fireStore
                            .collection('OrderItems')
                            .doc(myOrder.orderID)
                            .update({
                              isCompleted: true,
                              canceledPaymentObject: payment,
                              isCanceled: true,
                              canceledAfter48: false,
                              canceledBefor48: true,
                            })
                            .then(() => {
                              this._toast.nextMessage(
                                'Your order has been cancelled. Check your email for the cancelled order',
                                'success'
                              );
                            })
                            .catch((err) => {
                              this._toast.nextMessage(
                                err.message,
                                'error'
                              );
                            });
                        })
                      );
                      }

                    }))
              }))
  }

  findRefund(obj) {
    let links = JSON.parse(obj);
    for (let i of links) {
      if (i.rel == 'refund') {
        return i;
      }
    }
  }

  increaseViewCount(itemID, userID = 'null') {
    this._fireStore
      .collection('ItemList')
      .doc(itemID)
      .get()
      .subscribe((item) => {
        if (
          userID == 'null' ||
          (userID != 'null' && item.data().userID != userID)
        ) {
          this._fireStore
            .collection('ItemList')
            .doc(itemID)
            .update({ viewCount: parseInt(item.data().viewCount) + 1 });
        }
      });
  }

  increaseOrderCount(itemID) {
    this._fireStore
      .collection('ItemList')
      .doc(itemID)
      .get()
      .subscribe((item) => {
        this._fireStore
          .collection('ItemList')
          .doc(itemID)
          .update({ ordersCount: parseInt(item.data().ordersCount) + 1 });
      });
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
