import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailServiceService {

  constructor(private http: HttpClient) {
  }

  emailNotification(data: any) {
    return this.http.post(`${environment.firebaseFunctionUrl}/sendEmail`,
      {
        data1: data,
      },
    );
  }
}
