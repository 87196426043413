import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { CardModule } from 'primeng/card';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DataViewModule } from 'primeng/dataview';
import { ButtonModule } from 'primeng/button';
import { RatingModule } from 'primeng/rating';
import { ItemdetailComponent } from './itemdetail/itemdetail.component';
import { GalleriaModule } from 'primeng/galleria';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AdditemComponent } from './additem/additem.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { CarouselModule } from 'primeng/carousel';
import { MenubarModule } from 'primeng/menubar';
import { MegaMenuModule } from 'primeng/megamenu';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DropdownModule } from 'primeng/dropdown';
import { LoginSecurityComponent } from './login-security/login-security.component';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ChatComponent } from './chat/chat.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SliderModule } from 'primeng/slider';
import { PaymentComponent } from './payment/payment.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { StripeRedirectComponent } from './stripe-redirect/stripe-redirect.component';

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    SignupComponent,
    ItemdetailComponent,
    AdditemComponent,
    ProfileInfoComponent,
    LoginSecurityComponent,
    ChatComponent,
    FeedbackComponent,
    PaymentComponent,
    StripeRedirectComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    DataViewModule,
    ButtonModule,
    RatingModule,
    GalleriaModule,
    CalendarModule,
    FormsModule,
    InputTextModule,
    DialogModule,
    CheckboxModule,
    DynamicDialogModule,
    InputNumberModule,
    InputTextareaModule,
    FileUploadModule,
    CarouselModule,
    MenubarModule,
    MegaMenuModule,
    RippleModule,
    SplitButtonModule,
    DropdownModule,
    HttpClientModule,
    PasswordModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    SliderModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebase.apiKey,
      libraries: ['places'],
    }),
  ],
  exports: [HomeComponent],
})
export class ClientModule {}
