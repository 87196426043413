<div class="overlay" *ngIf="addloading">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="bigdiv">
    <div class="header flexed">
        <h4>{{ headerHeading }}</h4>
        <i class="fa fa-close" (click)="close()"></i>
    </div>
    <div class="body">
        <div *ngIf="!disabled">
            <div class="p-grid p-nogutter">
                <div class="p-col-12 p-md-6">
                    <h6>Rate User</h6>
                    <p-rating [(ngModel)]="userRating" [cancel]="false"></p-rating>
                </div>
                <div class="p-col-12 p-md-6">
                    <h6>Rate Item</h6>
                    <p-rating [(ngModel)]="itemRating" [cancel]="false"></p-rating>
                </div>
            </div><br>
            <h6>Was the Item Delivered as Describe?</h6><br>
            <div class="flexed spacing">
                <p-radioButton name="groupname" value="yes" label="Yes" [(ngModel)]="selectedValue"></p-radioButton>
                <p-radioButton name="groupname" value="no" label="No" [(ngModel)]="selectedValue"></p-radioButton>
            </div><br>
            <div class="flexedend"><button [disabled]="!selectedValue" (click)="continue()" pButton
                    label="Continue"></button>
            </div>
        </div>
        <div *ngIf="disabled">
            <div class="form-group">
                <label>Description:</label>
                <textarea [(ngModel)]="description" type="text" class="form-control" pInputTextarea
                    autoResize="autoResize"></textarea>
            </div>
            <label>Choose Images:</label>
            <p-fileUpload [style]="{'background-color':'#ee6956','border':'#ee6956'}" fileLimit="5" customUpload="true"
                invalidFileTypeMessageDetail="Allowed File Types: {0}"
                invalidFileTypeMessageSummary="{0}: Invalid File Type" mode="advanced" accept=".jpg, .png, .jpeg"
                [showUploadButton]="false" multiple="multiple" (onSelect)="picUploader($event)"
                (onRemove)="onRemove()" (onClear)="onClear()">
            </p-fileUpload><br>
            <div class="flexedend"><button (click)="submit()" pButton label="Submit Complaint"></button>
            </div>
        </div>
    </div>
</div>