import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchKeywordService {
  private keyword = new BehaviorSubject('');
  private severity = new BehaviorSubject('');
  private orderObj = new BehaviorSubject('');
  private itemEdit = new BehaviorSubject('');
  private filterObj = new BehaviorSubject('');

  sharedKeyword = this.keyword.asObservable();
  sharedSeverity = this.severity.asObservable();
  sharedOrderObj = this.orderObj.asObservable();
  sharedItemEdit = this.itemEdit.asObservable();
  sharedFilterObj = this.filterObj.asObservable();

  constructor() {}

  nextMessage(word: string, severity: string) {
    this.keyword.next(word);
    this.severity.next(severity);
  }

  nextOrder(obj: string) {
    this.orderObj.next(obj);
  }

  nextItemEdit(obj: string) {
    this.itemEdit.next(obj);
  }

  nextFilterObj(obj: string) {
    this.filterObj.next(obj);
  }
}
