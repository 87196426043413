import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-supplier',
  templateUrl: './new-supplier.component.html',
  styleUrls: ['./new-supplier.component.scss'],
})
export class NewSupplierComponent implements OnInit {
  termsContent: boolean = false;
  terms: boolean = false;
  email;
  id;
  addloading: boolean = false;
  errmsgemail;
  subscription;
  client_id:any;
  myVariables:any;

  constructor(
    private _fetch: FetchitemsService,
    private _toast: SearchKeywordService,
    public config: DynamicDialogConfig,
    private dialodeServies: DialogService,
    private router: Router
  ) {
    this.subscription = new Array<Subscription>();
    this.id = config.data.id;
    this.subscription.push(
      _fetch.getEnvironmentVariables().subscribe((vars) => {
        this.myVariables = vars;
        this.client_id = this.myVariables.client_id;
      }))
  }

  ngOnInit(): void {}

  isTerms() {
    if (this.termsContent) {
      this.termsContent = false;
    } else {
      this.termsContent = true;
    }
  }

  submit() {
    if (this.email != '' && this.terms) {
      if (this.ValidateEmail(this.email)) {
        this.addloading = true;
        this.subscription.push(
          this._fetch
            .updatePaypalEmail(this.email, this.id)
            .subscribe((prom) => {
              prom
                .then(() => {
                  this.dialodeServies.dialogComponentRefMap.forEach((item) =>
                    item.destroy()
                  );
                })
                .catch((err) => {
                  this._toast.nextMessage(err.message, 'error');
                });
            })
        );
      } else {
        this.errmsgemail = 'Please enter correct Email';
      }
    }
  }

  ValidateEmail(input) {
    var mailformat = /^[[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]/;
    if (input.match(mailformat)) {
      return true;
    }
    return false;
  }

  newAcc() {
    window.open('https://www.paypal.com/tc/webapps/mpp/account-selection');
  }
  stripeOauth() {
    const url = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${this.client_id}`;
    window.open(url, '_self');
  }
}
