<br><br>
<div class="container">
    <agm-map *ngIf="!mainLoading" [fitBounds]="true">
        <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng" [agmFitBounds]="true">
            <agm-info-window [isOpen]="true">
                <a [href]="'/itemdetail/'+marker.itemId">
                  <strong>{{marker.label}}</strong>
                </a>
            </agm-info-window>
        </agm-marker>
    </agm-map>
    <p-dataView [value]="mydata" [paginator]="true" [rows]="24" [lazy]="true" [loading]="mainLoading"
        (onLazyLoad)="loadData($event)" [totalRecords]="totalRecords" layout="grid" (onPage)="onPage()">
        <p-header>
            <div class="flexed">
                <div class="divInlineBlock flexedcenter">
                    <input class="marginTop" type="search" pInputText placeholder="Search" [(ngModel)]="searchWord" (input)='filter()'>
                    <button (click)="searchFilters()" pButton label=" Filters" type="button"
                        class="marginLeft divInlineBlock btn"><i class="pi pi-sliders-v"></i></button>
                </div>
                <div>
                    <p-dataViewLayoutOptions class="marginLeft marginBottom divInlineBlock"></p-dataViewLayoutOptions>
                </div>
            </div>
        </p-header>
        <ng-template let-data pTemplate="listItem">
            <div class="caroitemList">
                <div class="row">
                    <div class="col-sm-3">
                        <img *ngIf='data.payload.doc.get("photo")' class="itemimgList"
                            (click)='detail(data.payload.doc.get("id"))'
                            [src]='data.payload.doc.get("photo")[0]?data.payload.doc.get("photo")[0]:pic' alt="image">
                        <img *ngIf='!data.payload.doc.get("photo")' class="itemimgList" [src]='pic' alt="image"
                            (click)='detail(data.payload.doc.get("id"))'>
                    </div>
                    <div class="col-sm-5">
                        <div (click)='detail(data.payload.doc.get("id"))' style="cursor: pointer;">
                            <h5 class="itemtitleList textOverflowList">{{ data.payload.doc.get('title') }}</h5>
                        </div>
                        <div class="rating">
                            <p class="location"><i class="fa fa-star starIcon"></i>
                                {{ data.payload.doc.get('totalRating') | number: '1.0-0' }}</p>
                        </div>
                        <div>
                            <p class="locationList"><i class="fa fa-map-marker locationIcon"></i>
                                {{ data.payload.doc.get('location') }}</p>
                        </div>
                        <div class="detailPreview">
                            <i (click)='copyMessage(data.payload.doc.get("id"))' class="fa fa-share-alt"></i>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <p class="price">${{data.payload.doc.get('price1')}}</p>
                        <button pButton
                            [ngClass]='{ likebtnRed : ifLiked(data.payload.doc.get("id")), likebtn: !ifLiked(data.payload.doc.get("id")) }'
                            (click)='savedbtn(data.payload.doc.get("id"))' type="button" icon='fa fa-heart'
                            type="button" icon='fa fa-heart' class="p-button-rounded"></button>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template let-data pTemplate="gridItem">
            <div class="p-xs-12 p-sm-6 p-md-4 p-lg-4 p-xl-3 p-nogutter">
                <div *ngIf="data">
                    <br>
                    <div class="outerCaroItem">
                        <div class="caroitem">
                            <div>
                                <p class="price">${{data.payload.doc.get('price1')}}</p>
                                <button pButton
                                    [ngClass]='{ likebtnRed : ifLiked(data.payload.doc.get("id")), likebtn: !ifLiked(data.payload.doc.get("id")) }'
                                    (click)='savedbtn(data.payload.doc.get("id"))' type="button" icon='fa fa-heart'
                                    class="p-button-rounded"></button>
                            </div>
                            <div (click)='detail(data.payload.doc.get("id"))' style="cursor: pointer;">
                                <img *ngIf='data.payload.doc.get("photo")' class="itemimg"
                                [src]='data.payload.doc.get("photo")[0]?data.payload.doc.get("photo")[0]:pic'
                                alt="image">
                                <img *ngIf='!data.payload.doc.get("photo")' class="itemimg" [src]='pic' alt="image">
                                <h5 class="itemtitle textOverflow">{{ data.payload.doc.get('title') }}</h5>
                            </div>
                            <div class="p-grid p-nogutter itemfooter">
                                <div class="p-col-3 rating">
                                    <p class="location"><i class="fa fa-star starIcon"></i>
                                        {{ data.payload.doc.get('totalRating') | number: '1.0-0' }}</p>
                                </div>
                                <div class="p-col-8">
                                    <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                                        {{ data.payload.doc.get('location') }}</p>
                                </div>
                                <div class="p-col-1 detailPreview">
                                    <i (click)='copyMessage(data.payload.doc.get("id"))' class="fa fa-share-alt"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>
