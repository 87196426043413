import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { UpdateItemService } from 'src/app/services/update-item.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
})
export class ProfileViewComponent implements OnInit {
  userID;
  user;
  loading: boolean = true;
  supplied;
  noSupplied: boolean = true;
  pic;
  responsiveOptionswanted;
  signedIn;
  savedItems: Array<any> = [''];
  savedItemstemp;
  savedID;
  subscriptions;
  isLogin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _fetch: FetchitemsService,
    private _login: LoginsignupService,
    public _toast: SearchKeywordService,
    private _put: UpdateItemService
  ) {
    this.subscriptions = new Array<Subscription>();

    this.userID = this.route.snapshot.paramMap.get('id');
    this.pic = '../../../assets/images/noImage.jpg';

    this.responsiveOptionswanted = [
      {
        breakpoint: '960px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '668px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this._fetch.fetchuserbyIdasync(this.userID).subscribe((data) => {
        this.user = data[0];
        this.subscriptions.push(
          this._fetch.fetchSupplied(this.userID).subscribe((sup) => {
            if (sup.length) {
              this.noSupplied = false;
            }
            this.supplied = sup;
          })
        );
        this.subscriptions.push(
          this._login.isSignin().subscribe((uid) => {
            if (uid) {
              this.signedIn = uid.uid;
              this.isLogin = true;
              var temp = this._fetch.fetchSavedArray(uid.uid);
              this.subscriptions.push(
                temp.subscribe((data) => {
                  if (data[0]) {
                    this.savedItemstemp = data[0];
                    this.savedItems = this.savedItemstemp.saved;
                    this.savedID = this.savedItemstemp.savedID;
                    this.loading = false;
                  }
                })
              );
            } else {
              this.loading = false;
            }
          })
        );
      })
    );
  }

  detail(id) {
    if (this.isLogin) {
      this._put.increaseViewCount(id, this.signedIn);
    } else {
      this._put.increaseViewCount(id);
    }
    this.router.navigate(['/itemdetail', id]);
  }

  savedbtn(id) {
    if (this.signedIn) {
      if (this.savedItems.indexOf(id) >= 0) {
        this.savedItems.splice(this.savedItems.indexOf(id), 1);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      } else {
        this.savedItems.push(id);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      }
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  ifLiked(id) {
    if (this.savedItems.indexOf(id) >= 0) {
      return true;
    } else return false;
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + '/itemdetail/' + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toast.nextMessage('Link Copied to Clipboard', 'success');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
