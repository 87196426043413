import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeconnectService {
  constructor(private http: HttpClient) {}
  stripeConnect(data: any) {
    return this.http.post(`${environment.firebaseFunctionUrl}/stripeConnectAccount`, 
    {
      data1: data,
    });
  }
}
