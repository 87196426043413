<div class="overlay" *ngIf="addloading">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="bigdiv">
    <div class="header flexed">
        <h4>Filters</h4>
        <i class="fa fa-close" (click)="close()"></i>
    </div>
    <div class="body">
        <h6>Price Range</h6>
        <p class="rangeValues">${{ rangeValues[0] }} - ${{ rangeValues[1] }}</p>
        <div class="flexedcenter">
            <p-slider class="range" [(ngModel)]="rangeValues" [range]="true" [animate]="true" [min]="min" [max]="max">
            </p-slider>
        </div>
        <hr>
        <h6>Adventurer</h6>
        <div class="flexedSpaceAround">
            <p-checkbox value="men" label="Men" [(ngModel)]="Men">
            </p-checkbox>
            <p-checkbox value="women" label="Women" [(ngModel)]="Women">
            </p-checkbox>
            <p-checkbox value="children" label="Children" [(ngModel)]="Children">
            </p-checkbox>
        </div>
        <hr>
        <h6>Ratings</h6>
        <div class="flexedcenter">
            <p-rating [(ngModel)]="userRating" [cancel]="false"></p-rating>
        </div>
        <hr>
        <h6>Location</h6>
        <input #filtersloc [style]="{'width': '100%'}" (input)="onchange()" (focusin)="focused()" type="text"
            [(ngModel)]="searchLocation" maxlength="50" pInputText placeholder="Enter Location">
        <hr>
        <h6>Categories</h6>
        <p-dropdown *ngIf="!typeEDIT" [options]="types" [(ngModel)]="type" [style]="{'width':'100%'}"
            placeholder="Select the Type" optionLabel="categoryName">
        </p-dropdown>
        <p-dropdown *ngIf="typeEDIT" [options]="types" [(ngModel)]="type" [style]="{'width':'100%'}"
            autoDisplayFirst="true" optionLabel="categoryName">
        </p-dropdown>
        <hr>
        <h6>Availability</h6>
        <p-calendar [minDate]="minStartDate" showIcon="true" [style]="{'width':'50%'}" (onSelect)="startDateSelect()"
            [(ngModel)]="startDate" placeholder='From'></p-calendar>
        <p-calendar [minDate]="minEndDate" showIcon="true" [style]="{'width':'50%'}" [(ngModel)]="endDate"
            placeholder='To'></p-calendar>
        <br><br>
        <div class="flexedend">
            <button class="marginRight" (click)="clear()" pButton label="Reset"></button>
            <button (click)="submit()" pButton label="Search"></button>
        </div>
    </div>
</div>