import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LoginsignupService } from '../../services/loginsignup.service';
import { FetchitemsService } from '../../services/fetchitems.service';
import { UpdateItemService } from '../../services/update-item.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { Subscription } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import * as moment from 'moment';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnInit {
  //For loading
  displayInformation = false;
  mainLoading = false; //main div loadin
  loading = false; //save button Loading
  nameloading = false; //save button Loading
  genderloading = false; //save button Loading
  dateloading = false; //save button Loading
  phoneloading = false; //save button Loading
  langloading = false; //save button Loading
  locloading = false; //save button Loading
  workloading = false; //save button Loading
  aboutloading = false; //save button Loading
  @ViewChild('profileloc') searchElementRef: ElementRef;

  //Picture Link and flag
  downloadURL;
  isPictureSelected: boolean = false;
  previousPicture;
  autofill: boolean = false;

  //error
  err = 'none';

  //user data
  userData;

  //user data object
  userDetails; //to store data of user from db
  user = {
    firstName: '',
    gender: '',
    dob: '',
    email: '',
    phone: '',
    language: '',
    location: '',
    work: '',
    about: '',
  };
  //Picture File For Upload
  picture;

  //Dialogue  display
  displayDialogueView: boolean = false;
  displaydialogueUpdate: boolean = false;
  //upload dialogue
  uploadPicture;
  uploadloading: boolean = false;
  //Input value date
  DOB;
  //input value gender
  gender;
  genders = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
    { id: 3, name: 'Other' },
  ];

  //Ui Edit Buttons Variable
  //name
  editNameDisplay = 'none';
  namebtnLabel = 'Edit';
  namelabel;
  //gender
  genderlabel;
  editGenderDisplay = 'none';
  genderbtnLabel = 'Edit';
  //date variable
  datebtnLabel = 'Edit';
  datelabel;
  editDateDisplay = 'none';
  //Email
  email;
  //phone
  phonebtnLabel = 'Edit';
  phonelabel;
  editPhoneDisplay = 'none';

  //languagelabel
  langbtnLabel = 'Edit';
  langlabel;
  editlangDisplay = 'none';

  //locationlabel
  locbtnLabel = 'Edit';
  loclabel;
  editlocDisplay = 'none';

  //aboutlabel
  aboutbtnLabel = 'Edit';
  aboutlabel;
  editaboutDisplay = 'none';

  //languagelabel
  workbtnLabel = 'Edit';
  worklabel;
  editworkDisplay = 'none';

  subscriptions;
  //Contructor
  constructor(
    //Services
    public _loginServies: LoginsignupService,
    private _fetchUser: FetchitemsService,
    private _upadteUserServies: UpdateItemService,
    public _toast: SearchKeywordService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.subscriptions = new Array<Subscription>();
    this.mainLoading = true; //Enable Loading
    //Servies call get user uid
    this.subscriptions.push(
      _loginServies.isSignin().subscribe((data) => {
        this.userData = data;
        //servies call to get data of user
        if (data) {
          this._fetchUser.getUserByUID(data.uid).then((data) =>
            this.subscriptions.push(
              data.subscribe((data) => {
                if (data) {
                  this.userDetails = data;

                  this.user.firstName = this.userDetails.firstName
                    ? this.userDetails.firstName
                    : '';
                  this.user.gender = this.userDetails.gender
                    ? this.userDetails.gender
                    : '';
                  this.gender = this.genders[
                    this.genders
                      .map((e) => {
                        return e.name;
                      })
                      .indexOf(this.user.gender)
                  ];
                  this.user.dob = this.userDetails.DOB
                    ? this.userDetails.DOB
                    : '';
                  this.DOB = moment(new Date(this.user.dob)).format(
                    'YYYY-MM-DD'
                  );
                  this.user.email = this.userDetails.email;
                  this.user.phone = this.userDetails.phone
                    ? this.userDetails.phone
                    : '';
                  this.user.language = this.userDetails.userLanguage
                    ? this.userDetails.userLanguage
                    : '';
                  this.user.location = this.userDetails.userLocation
                    ? this.userDetails.userLocation
                    : '';
                  this.user.work = this.userDetails.userWork
                    ? this.userDetails.userWork
                    : '';
                  this.user.about = this.userDetails.userDetail
                    ? this.userDetails.userDetail
                    : '';
                  //PROFILE PIC OF USER
                  this.downloadURL = this.userDetails.photo
                    ? this.userDetails.photo
                    : '../../../assets/images/dp.png';
                  //to show in upload dialogue
                  this.uploadPicture = this.userDetails.photo
                    ? this.userDetails.photo
                    : '../../../assets/images/dp.png';
                  //To get previous picture
                  this.previousPicture = this.userDetails.photo;
                  this.mainLoading = false; //disable Loading
                  this.displayInformation = true; //Enable display
                }
              })
            )
          );
        }
      })
    );
  }

  ngOnInit(): void { }

  //To display Dialogues and it functionality
  //dispaly Profile Pic btn
  displayProfilePic() {
    this.displayDialogueView = true;
  }
  //Upload Profile Picture
  uploadProfilePic() {
    this.displaydialogueUpdate = true;
  }
  //On Discard of update ProfileDialogue
  onDiscardClick() {
    this.displaydialogueUpdate = false;
  }

  //Save button Clicks
  async onSaveName() {
    if (this.user.firstName != '') {
      this.nameloading = true;
      let newName = {
        firstName: this.user.firstName,
      };
      await this._upadteUserServies
        .upadateuser(newName, this.userData)
        .then(() => (this.nameloading = false));
      this.onEditClick();
    }
  }
  async onSavePhone() {
    if (this.user.phone != '') {
      this.phoneloading = true;
      let newPhone = {
        phone: this.user.phone,
      };
      await this._upadteUserServies
        .upadateuser(newPhone, this.userData)
        .then(() => (this.phoneloading = false));
      this.onPhoneEdit();
    }
  }
  async onSaveDOB() {
    if (this.DOB) {
      this.dateloading = true;
      let newDOB = {
        DOB: moment(new Date(this.DOB)).format('YYYY-MM-DD'),
      };
      await this._upadteUserServies
        .upadateuser(newDOB, this.userData)
        .then(() => (this.dateloading = false));
      this.ondateEdit();
    }
  }
  async onSaveGender() {
    if (this.gender) {
      this.genderloading = true;

      await this._upadteUserServies
        .upadateuser({ gender: this.gender.name }, this.userData)
        .then(() => (this.genderloading = false));
      this.onGenderEdit();
    }
  }
  async onSaveLanguage() {
    if (this.user.language != '') {
      this.langloading = true;
      let newLang = {
        userLanguage: this.user.language,
      };
      await this._upadteUserServies
        .upadateuser(newLang, this.userData)
        .then(() => (this.langloading = false));
      this.onlanguageEdit();
    }
  }
  async onSaveLocation() {
    if (this.user.location != '') {
      this.locloading = true;
      let newLoc = {
        userLocation: this.user.location,
      };
      await this._upadteUserServies
        .upadateuser(newLoc, this.userData)
        .then(() => (this.locloading = false));
      this.onLocationEdit();
    }
  }
  async onSaveWork() {
    if (this.user.work != '') {
      this.workloading = true;
      let newwork = {
        userWork: this.user.work,
      };
      await this._upadteUserServies
        .upadateuser(newwork, this.userData)
        .then(() => (this.workloading = false));
      this.onWorkEdit();
    }
  }
  async onSaveAbout() {
    if (this.user.about != '') {
      this.aboutloading = true;
      let newAbout = {
        userDetail: this.user.about,
      };
      await this._upadteUserServies
        .upadateuser(newAbout, this.userData)
        .then(() => (this.aboutloading = false));
      this.onAboutEdit();
    }
  }
  //OnUpload picture Button

  //pic Upload Handler
  picUploader(event) {
    this.isPictureSelected = true;
    this.picture = event.files[0];
    this.uploadPicture =
      event.files[0].objectURL.changingThisBreaksApplicationSecurity;
    this.err = 'none';
    if (this.isPictureSelected) {
      //loading
      this.uploadloading = true;
      let nameImage = new Date().valueOf();
      //to store the image
      this._upadteUserServies
        .upadteUserProfile(this.picture, nameImage)
        .then((ref) => {
          ref.ref.getDownloadURL().then((url) => {
            this.downloadURL = url;
            let newPhoto = {
              photo: url,
            };
            this._upadteUserServies
              .upadateuser(newPhoto, this.userData)
              .then(() => {
                if (this.previousPicture) {
                  this._upadteUserServies.deletepreviousPicture(
                    this.previousPicture
                  );
                }
                this.loading = false;
                this.uploadloading = false;
                this.displaydialogueUpdate = false;
              });
          });
        })
        .catch((err) => this._toast.nextMessage(err.message, 'error'));
    }
  }

  onCancel() {
    this.uploadPicture = this.userDetails.photo
      ? this.userDetails.photo
      : '../../../assets/images/dp.png';
  }

  onSelect(event) {
    if (event.currentFiles[0]) {
      this.uploadPicture =
        event.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity;
    }
  }

  //Edit Clicks
  onEditClick() {
    if (this.namebtnLabel === 'Cancel') {
      if (this.user.firstName != this.userDetails.firstName) {
        this.user.firstName = this.userDetails.firstName;
      }
      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';
    } else {
      this.namebtnLabel = 'Cancel';
      this.editNameDisplay = '';
      this.namelabel = 'none';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }
  onGenderEdit() {
    if (this.genderbtnLabel === 'Cancel') {
      if (this.user.gender != this.userDetails.gender) {
        this.user.gender = this.userDetails.gender;
      }
      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';
    } else {
      this.genderbtnLabel = 'Cancel';
      this.editGenderDisplay = '';
      this.genderlabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }
  ondateEdit() {
    if (this.datebtnLabel === 'Cancel') {
      if (this.user.dob != this.userDetails.DOB) {
        this.user.dob = this.userDetails.DOB;
      }
      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';
    } else {
      this.datebtnLabel = 'Cancel';
      this.editDateDisplay = 'inline';
      this.datelabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }

  onPhoneEdit() {
    if (this.phonebtnLabel === 'Cancel') {
      if (this.user.phone != this.userDetails.phone) {
        this.user.phone = this.userDetails.phone;
      }
      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';
    } else {
      this.phonebtnLabel = 'Cancel';
      this.editPhoneDisplay = 'inline';
      this.phonelabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }

  onlanguageEdit() {
    if (this.langbtnLabel === 'Cancel') {
      if (this.user.language != this.userDetails.userLanguage) {
        this.user.language = this.userDetails.userLanguage;
      }
      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';
    } else {
      this.langbtnLabel = 'Cancel';
      this.editlangDisplay = 'inline';
      this.langlabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }

  onLocationEdit() {
    if (this.locbtnLabel === 'Cancel') {
      if (this.user.location != this.userDetails.userLocation) {
        this.user.location = this.userDetails.userLocation;
      }
      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';
    } else {
      if (!this.autofill) {
        this.autofill = true;
        this.mapsAPILoader.load().then(() => {
          let autocomplete = new google.maps.places.Autocomplete(
            this.searchElementRef.nativeElement
          );
          autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              this.user.location = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
            });
          });
        });
      }

      this.locbtnLabel = 'Cancel';
      this.editlocDisplay = 'inline';
      this.loclabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }

  onWorkEdit() {
    if (this.workbtnLabel === 'Cancel') {
      if (this.user.work != this.userDetails.userWork) {
        this.user.work = this.userDetails.userWork;
      }
      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';
    } else {
      this.workbtnLabel = 'Cancel';
      this.editworkDisplay = 'inline';
      this.worklabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    }
  }

  onAboutEdit() {
    if (this.aboutbtnLabel === 'Cancel') {
      if (this.user.about != this.userDetails.userDetail) {
        this.user.about = this.userDetails.userDetail;
      }
      this.aboutbtnLabel = 'Edit';
      this.editaboutDisplay = 'none';
      this.aboutlabel = '';
    } else {
      this.aboutbtnLabel = 'Cancel';
      this.editaboutDisplay = 'inline';
      this.aboutlabel = 'none';

      this.namebtnLabel = 'Edit';
      this.editNameDisplay = 'none';
      this.namelabel = '';

      this.genderbtnLabel = 'Edit';
      this.editGenderDisplay = 'none';
      this.genderlabel = '';

      this.datebtnLabel = 'Edit';
      this.editDateDisplay = 'none';
      this.datelabel = '';

      this.phonebtnLabel = 'Edit';
      this.editPhoneDisplay = 'none';
      this.phonelabel = '';

      this.langbtnLabel = 'Edit';
      this.editlangDisplay = 'none';
      this.langlabel = '';

      this.locbtnLabel = 'Edit';
      this.editlocDisplay = 'none';
      this.loclabel = '';

      this.workbtnLabel = 'Edit';
      this.editworkDisplay = 'none';
      this.worklabel = '';
    }
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
