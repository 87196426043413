<div class="overlay" *ngIf="uploadloading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="main">

  <div class="container">

    <h2>Personal Info</h2><br>

    <span class="loader" [class.spinner]="mainLoading"></span>


    <div id="DiaplyInformation" *ngIf="displayInformation">

      <!-- Profile Picture and its related-->
      <div class="flex-container">
        <!-- ProfilePicture Show -->
        <div class="imgDiv">
          <!-- show pic on page  -->
          <div class="pic p-shadow-2" [style.background-image]="'url(' + downloadURL + ')'">
            <!-- Transition Button  or on hover-->
            <div class="picBtns">
              <button pButton type="button" class=" p-button-rounded pbtn" icon="pi pi-eye"
                (click)="displayProfilePic()">
              </button>
              <button pButton type="button" class=" p-button-rounded pbtn p-ml-4" icon="pi pi-upload"
                (click)="uploadProfilePic()"></button>
            </div>
          </div>
        </div>

        <!-- Show Picture Dialouge -->
        <p-dialog draggable="false" dismissableMask="true" [(visible)]="displayDialogueView" styleClass="myClass"
          modal=false class="viewProfilePic">
          <p-header>
            Profile Picture
          </p-header>
          <!-- image veiw -->
          <div class="picDisplay" [style.background-image]="'url(' + downloadURL + ')'">
          </div>
        </p-dialog>

        <!-- Upload Picture Dialogue -->
        <p-dialog styleClass="mydialog" dismissableMask="true" modal=false resizable=true draggable="false"
          [(visible)]="displaydialogueUpdate" class="viewProfilePic">
          <p-header>
            Upload Picture
          </p-header>
          <br>

          <div class="p-grid">
            <!-- image veiw -->
            <div class="pic p-col-12 p-md-3 p-ml-3 p-shadow-11" [style.background-image]="'url(' + uploadPicture+ ')'">
            </div>
            <!-- Descripttion -->
            <div class=" p-col-12 p-md-9 p-ml-3 ">
              <p>Upload the picture and click on save button to change your Profile Picture</p>
              <!-- upload Button -->
              <p-fileUpload [style]="{'background-color':'#ee6956','border':'#ee6956'}" customUpload="true"
                mode="advanced" accept=".jpg, .png, .jpeg" invalidFileTypeMessageDetail="Allowed File Types: {0}"
                invalidFileTypeMessageSummary="{0}: Invalid File Type" (uploadHandler)="picUploader($event)"
                (onClear)="onCancel()" (onSelect)="onSelect($event)" (onRemove)="onCancel()">
              </p-fileUpload><br>
              <!-- error label -->
              <label class="error" [style.display]="err">Please upload the picture</label>
            </div>
          </div>
          <p-footer>
            <button pButton label=Discard type="button" class="p-button-raised pbtn" (click)="onDiscardClick()">
            </button>
          </p-footer>
        </p-dialog>

      </div>

      <br>
      <!-- Name -->
      <div id=" Name">
        <div class=" p-grid">
          <h5 class="p-col-9">Name</h5>
          <button pButton [label]="namebtnLabel" type="button" class="btn p-col-3" (click)="onEditClick()"></button>
          <label class="p-col-12" [style.display]="namelabel">{{user.firstName}}</label>
          <div class=" p-col-12 " [style.display]="editNameDisplay">
            <input (keydown.enter)="onSaveName()" class="p-col-8 p-md-6 " [(ngModel)]="user.firstName" maxlength="50"
              type="text" placeholder="Enter your name" pInputText>
            <button pButton [disabled]="nameloading" [class.spinner]="nameloading" class="p-col-3 p-md-3" label=Save
              type="button" (click)="onSaveName()" class="p-button-raised p-ml-2 save"></button>
          </div>
        </div>
      </div>
      <hr>

      <!-- gender -->
      <div id="gender">
        <div class="p-grid">
          <h5 class="p-col-9">Gender</h5>
          <button pButton [label]="genderbtnLabel" type="button" class="btn p-col-3" (click)="onGenderEdit()"></button>
          <label class="p-col-12" [style.display]="genderlabel">{{user.gender?user.gender:'Not Specified'}}</label>
          <div class="p-field p-col-12 " [style.display]="editGenderDisplay">
            <p-dropdown (keydown.enter)="onSaveGender()" [options]="genders" [(ngModel)]="gender" class="dropdown"
              placeholder="Select a gender" optionLabel="name">
            </p-dropdown>
            <button pButton label=Save [disabled]="genderloading" [class.spinner]="genderloading" type="button"
              class="p-button-raised p-ml-2 save" (click)="onSaveGender()"></button>
          </div>
        </div>

      </div>
      <hr>
      <!-- date -->
      <div id=dateOfbirth>
        <div class="p-grid">
          <h5 class="p-col-9">Date of Birth</h5>
          <button pButton [label]="datebtnLabel" type="button" class="btn p-col-3" (click)="ondateEdit()"></button>
          <label class="p-col-12" [style.display]="datelabel">{{ user.dob?user.dob:'Not Specified' }}</label>
          <div class=" p-col-12 " [style.display]="editDateDisplay">
            <input (keydown.enter)="onSaveDOB()" type="date" class="p-md-6 p-col-8 date" [(ngModel)]="DOB">
            <button pButton [disabled]="dateloading" [class.spinner]="dateloading" label=Save type="button"
              class="p-button-raised p-ml-2 save" (click)="onSaveDOB()"></button>
          </div>
        </div>
      </div>
      <hr>
      <!-- Email -->
      <div id=email>
        <div class="p-grid">
          <h5 class="p-col-12">Email</h5>
          <label class="p-col-12">{{user.email}}</label>
        </div>
      </div>
      <hr>
      <!-- phone  -->
      <div id=phone>
        <div class="p-grid">
          <h5 class="p-col-9">Phone</h5>
          <button pButton [label]="phonebtnLabel" type="button" class="btn p-col-3" (click)="onPhoneEdit()"></button>
          <label class="p-col-12" [style.display]="phonelabel">{{user.phone?user.phone:'Not Specified'}}</label>
          <div class=" p-col-12 " [style.display]="editPhoneDisplay">
            <input (keydown.enter)="onSavePhone()" class="p-col-8 p-md-6 " [(ngModel)]="user.phone" maxlength="20"
              type="text" placeholder="Enter Phone Number" pInputText>
            <button pButton [disabled]="phoneloading" [class.spinner]="phoneloading" class="p-col-4 p-mt-2" label=Save
              type="button" (click)=onSavePhone() class="p-button-raised p-ml-2 save"></button>
          </div>
        </div>
      </div>
      <hr>
      <div id=language>
        <div class="p-grid">
          <h5 class="p-col-9">Languages</h5>
          <button pButton [label]="langbtnLabel" type="button" class="btn p-col-3" (click)="onlanguageEdit()"></button>
          <label class="p-col-12" [style.display]="langlabel">{{user.language?user.language:'Not Specified'}}</label>
          <div class=" p-col-12 " [style.display]="editlangDisplay">
            <input (keydown.enter)=onSaveLanguage() class="p-col-8 p-md-6 " [(ngModel)]="user.language" maxlength="100"
              type="text" placeholder="Enter Languages" pInputText>
            <button pButton [disabled]="langloading" [class.spinner]="langloading" class="p-col-4 p-mt-2" label=Save
              type="button" (click)=onSaveLanguage() class="p-button-raised p-ml-2 save"></button>
          </div>
        </div>
      </div>

      <hr>
      <div id=location>
        <div class="p-grid">
          <h5 class="p-col-9">Location</h5>
          <button pButton [label]="locbtnLabel" type="button" class="btn p-col-3" (click)="onLocationEdit()"></button>
          <label class="p-col-12" [style.display]="loclabel">{{user.location?user.location:'Not Specified'}}</label>
          <div class=" p-col-12 " [style.display]="editlocDisplay">
            <input #profileloc class="p-col-8 p-md-6 " [(ngModel)]="user.location" maxlength="100"
              type="text" placeholder="Enter Location" pInputText>
            <button pButton [disabled]="locloading" [class.spinner]="locloading" class="p-col-4 p-mt-2" label=Save
              type="button" (click)=onSaveLocation() class="p-button-raised p-ml-2 save"></button>
          </div>
        </div>
      </div>

      <hr>
      <div id=work>
        <div class="p-grid">
          <h5 class="p-col-9">Work Details</h5>
          <button pButton [label]="workbtnLabel" type="button" class="btn p-col-3" (click)="onWorkEdit()"></button>
          <label class="p-col-12" [style.display]="worklabel">{{user.work?user.work:'Not Specified'}}</label>
          <div class=" p-col-12 " [style.display]="editworkDisplay">
            <input (keydown.enter)=onSaveWork() class="p-col-8 p-md-6 " [(ngModel)]="user.work" maxlength="200"
              type="text" placeholder="Enter Work Details" pInputText>
            <button pButton [disabled]="workloading" [class.spinner]="workloading" class="p-col-4 p-mt-2" label=Save
              type="button" (click)=onSaveWork() class="p-button-raised p-ml-2 save"></button>
          </div>
        </div>
      </div>

      <hr>
      <div id=about>
        <div class="p-grid">
          <h5 class="p-col-9">About</h5>
          <button pButton [label]="aboutbtnLabel" type="button" class="btn p-col-3" (click)="onAboutEdit()"></button>
          <label class="p-col-12" [style.display]="aboutlabel">{{user.about?user.about:'Not Specified'}}</label>
          <div class="p-col-12" [style.display]="editaboutDisplay">
            <textarea (keydown.enter)=onSaveAbout() [(ngModel)]="user.about" type="text"
              class="form-control p-col-12 p-md-10 p-lg-6" maxlength="500" placeholder="Enter Bio"
              style="height: 150px;"></textarea>
            <button pButton [disabled]="aboutloading" [class.spinner]="aboutloading" class="p-col-4 p-mt-2" label=Save
              type="button" (click)=onSaveAbout() class="p-button-raised p-mt-2 save"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>