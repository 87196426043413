import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { MegaMenuModule } from 'primeng/megamenu';
import { HeaderComponent } from './header/header.component';
import { InputTextModule } from 'primeng/inputtext';
import { FooterComponent } from './footer/footer.component';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DataViewModule } from 'primeng/dataview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ItemsComponent } from './items/items.component';
import { RatingModule } from 'primeng/rating';
import { CardModule } from 'primeng/card';
import { GalleriaModule } from 'primeng/galleria';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { MyItemsComponent } from './my-items/my-items.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { ComplaintRenterComponent } from './complaint-renter/complaint-renter.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ComplaintSupplierComponent } from './complaint-supplier/complaint-supplier.component';
import { SliderModule } from 'primeng/slider';
import { CancelOrderComponent } from './cancel-order/cancel-order.component';
import { AgmCoreModule } from '@agm/core';
import { HelpComponent } from './help/help.component';
import { NewSupplierComponent } from './new-supplier/new-supplier.component';
import { FiltersComponent } from './filters/filters.component';
import { FiltereditemsComponent } from './filtereditems/filtereditems.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ItemsComponent,
    MyItemsComponent,
    ForgetPasswordComponent,
    ProfileViewComponent,
    ComplaintRenterComponent,
    ComplaintSupplierComponent,
    CancelOrderComponent,
    HelpComponent,
    NewSupplierComponent,
    FiltersComponent,
    FiltereditemsComponent,
  ],
  imports: [
    CommonModule,
    MenubarModule,
    MegaMenuModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    DataViewModule,
    SplitButtonModule,
    RatingModule,
    CardModule,
    GalleriaModule,
    CalendarModule,
    FormsModule,
    DialogModule,
    DynamicDialogModule,
    InputNumberModule,
    InputTextareaModule,
    FileUploadModule,
    CarouselModule,
    TabMenuModule,
    ProgressSpinnerModule,
    TabViewModule,
    DropdownModule,
    CheckboxModule,
    RadioButtonModule,
    SliderModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebase.apiKey,
      libraries: ['places'],
    }),
  ],
  exports: [HeaderComponent, FooterComponent],
})
export class SharedModule {}
