import { Component, OnInit } from '@angular/core';
import { LoginsignupService } from '../../services/loginsignup.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { DialogService } from 'primeng/dynamicdialog';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  constructor(
    private _service: LoginsignupService,
    private auth: AngularFireAuth,
    private dialodeServies: DialogService,
    public _toast: SearchKeywordService
  ) {}
  //variable
  userEmail; //for email
  errmsg: string = '';
  loading: boolean = false;
  ifSent: boolean = false;

  ngOnInit(): void {}
  sendEmail() {
    this.loading = true;
    this.auth
      .fetchSignInMethodsForEmail(this.userEmail)
      .then((data) => {
        if (data) {
          this._service
            .forgot(this.userEmail)
            .then(() => {
              this.errmsg = '';
              this._toast.nextMessage(
                'Password Reset Link Sent to your email',
                'success'
              );
              this.loading = false;
              this.ifSent = true;
            })
            .catch(() => {
              this._toast.nextMessage('Email Does not Exist', 'error');
              this.loading = false;
            });
        } else {
          this._toast.nextMessage('Email Does not Exist', 'error');
          this.loading = false;
        }
      })
      .catch((err) => (this.errmsg = err));
  }

  close() {
    this.dialodeServies.dialogComponentRefMap.forEach((item) => item.destroy());
  }
}
