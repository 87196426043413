import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeCheckoutServiceService {

  constructor(private http:HttpClient) { }
  makePayment(data:any){
    console.log("DFTA_-----",data);
    return this.http.post(`${environment.firebaseFunctionUrl}/stripeCheckout`,data)
   
  }
}
