<p-progressSpinner *ngIf="loading"></p-progressSpinner>

<div *ngIf="!loading">
  <h1 class="title">List or find <b>Wanderlust Units</b></h1>
  <p class="subtitle">Find the gear you need for your next adventure</p>
  <div class="container searchContainer">
    <div class="p-grid p-nogutter searchbar">
      <div class="p-field p-col-12 p-md-3">
        <input #title (keydown.enter)="search()" class="titleInput" type="text" id="inputtext1" [(ngModel)]="searchTitle"
          maxlength="50" pInputText placeholder="What are you Looking for?">
      </div>
      <div class="p-field p-col-12 p-md-3">
        <div [style]="divStyle" class="p-grid p-nogutter inputIconGrid">
          <div class="p-col-10">
            <input #loc (input)="onchange()" (keydown.enter)="searchwithLoc()" (focusin)="focused()" (focusout)="focusout()" type="text"
              id="inputtext2" [(ngModel)]="searchLocation" maxlength="50" pInputText placeholder="Where to look?">
          </div>
          <div class="p-col-2 flexedend">
            <i class="fa fa-map-marker"></i>
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-3">
        <p-dropdown (keydown.enter)="search()" inputId="dropdown" [options]="catarr" [autoDisplayFirst]="false"
          [(ngModel)]="searchCategory" optionLabel="categoryName" placeholder="Categories">
        </p-dropdown>
      </div>
      <div class="p-col-12 p-md-3 flexed">
        <button (click)="search()" class="searchbtn" pButton label='SEARCH' icon="pi pi-search" iconPos="left"></button>
      </div>
    </div>
  </div>
  <div class="catdiv">
    <h3 class="cattitle">What Do You Want To Do</h3>
    <p class="subtitle">Find the gear you need for your next adventure</p>
    <div class="container categoryContainer">
      <p-dataView [value]="categories | async" layout='grid'>
        <ng-template let-data pTemplate="gridItem">
          <div class="p-col-12 p-md-6 p-lg-3 p-nogutter">
            <div class="catitemOuter">
              <div class="catitem" (click)="categoryclick(data.categoryName)">
                <img class="catimg" [src]="data.photo" alt="image">
                <p class="catName">{{ data.categoryName }}</p>
              </div>
            </div>
            <br>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
  <div class="trendingdiv">
    <h3 class="trendingtitle">Trending Listing</h3>
    <p class="trendingsubtitle">Find the gear you need for your next adventure</p>
    <div class="container caro-container">
      <p-carousel [value]="trending" [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptionswanted"
        circular="true" [autoplayInterval]="3000">
        <ng-template let-data pTemplate="item">
          <div class="outerCaroItem">
            <div class="caroitem">
              <div>
                <p class="price">${{data.price1}}</p>
                <button pButton type="button" icon='fa fa-heart' class="p-button-rounded"
                  [ngClass]='{ likebtnRed : ifLiked(data.id), likebtn: !ifLiked(data.id) }'
                  (click)='savedbtn(data.id)' type="button" icon='fa fa-heart'></button>
              </div>
              <div (click)='detail(data.id)' style="cursor: pointer;">
                <img *ngIf='data.photo' class="itemimg"
                [src]='data.photo[0]?data.photo[0]:pic' alt="image">
                <img *ngIf='!data.photo' class="itemimg" [src]='pic' alt="image"
                >
                <h5 class="itemtitle textOverflow saagColor">{{ data.title }}</h5>
                <span class="itemtitle">{{ data.type }}</span>
              </div>
              <div class="p-grid p-nogutter itemfooter">
                <div class="p-col-3 rating">
                  <p class="location"><i class="fa fa-star starIcon"></i>
                    {{ data.totalRating | number: '1.0-0' }}</p>
                </div>
                <div class="p-col-8">
                  <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                    {{ data.location }}</p>
                </div>
                <div class="p-col-1 detailPreview">
                  <i (click)='copyMessage(data.id)' class="fa fa-share-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
    <br>
    <div class="flexed"><button class="viewall" (click)="viewAll()" pButton label="View all Listing"></button></div>
  </div>

  <br><br><br>


  <div class="wanteddiv">
    <h3 class="cattitle">Wanted Listing</h3>
    <p class="subtitle">Find the gear you need for your next adventure</p>
    <div class="container caro-container">
      <p-carousel [value]="wanted" [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptionswanted"
        circular="true" [autoplayInterval]="3000">
        <ng-template let-data pTemplate="item">
          <div class="outerCaroItem">
            <div class="caroitem">
              <div>
                <p class="price">${{data.price1}}</p>
                <button pButton type="button" icon='fa fa-heart' class="p-button-rounded"
                  [ngClass]='{ likebtnRed : ifLiked(data.id), likebtn: !ifLiked(data.id) }'
                  (click)='savedbtn(data.id)' type="button" icon='fa fa-heart'></button>
              </div>
              <div (click)='detail(data.id)' style="cursor: pointer;">
                <img *ngIf='data.photo' class="itemimg" [src]='data.photo[0]?data.photo[0]:pic' alt="image">
                <img *ngIf='!data.photo' class="itemimg" [src]='pic' alt="image">
                <h5 class="itemtitle textOverflow saagColor">{{ data.title }}</h5>
                <span class="itemtitle">{{ data.type }}</span>
              </div>
              <div class="p-grid p-nogutter itemfooter">
                <div class="p-col-3 rating">
                  <p class="location"><i class="fa fa-star starIcon"></i>
                    {{ data.totalRating | number: '1.0-0' }}</p>
                </div>
                <div class="p-col-8">
                  <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                    {{ data.location }}</p>
                </div>
                <div class="p-col-1 detailPreview">
                  <i (click)='copyMessage(data.id)' class="fa fa-share-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
  <div class="howitworks">
    <div class="innerhowitworks">
      <h1>How it Works</h1>
      <p class="trendingsubtitle">Find the gear you need for your next adventure</p>
      <div class="container">
        <div class="p-grid p-nogutter trans">
          <div class="p-col-12 p-md-2 flexed">
            <div class="howitworksItem" (click)="findgear()">
              <div class="flexed"><i class="fa fa-compass"></i></div>
              <h6>Find Interesting Gear</h6>
            </div>
          </div>
          <div class="p-col-12 p-md-3 flexed">
            <p>---------</p>
          </div>
          <div class="p-col-12 p-md-2 flexed">
            <div class="howitworksItem" (click)="becomeSupplier()">
              <div class="flexed"><i class="pi pi-dollar"></i></div>
              <h6>Become a Supplier</h6>
            </div>
          </div>
          <div class="p-col-12 p-md-3 flexed">
            <p>---------</p>
          </div>
          <div class="p-col-12 p-md-2 flexed">
            <div class="howitworksItem" (click)="becomeSupplier()">
              <div class="flexed"><i class="fa fa-list-alt"></i></div>
              <h6>Make a Listing</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="subscription">
    <div class="container">
      <div class="row">
        <div class="col-6 flexed">
          <h4>WE HAVE HIGH STANDARDS FOR EMAIL TOO</h4>
        </div>
        <div class="col-6 flexed">
          <input [(ngModel)]="emailSubs" (keydown.enter)="emailEnter()" pInput class="email" type="email" maxlength="25"
            placeholder="Enter your Email">
        </div>
      </div>
    </div>
  </div>
</div>
