import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { SearchKeywordService } from './search-keyword.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginsignupService {
  public itemsCollection: AngularFirestoreCollection<any>;
  public savedItems: AngularFirestoreCollection<any>;
  subscription;

  constructor(
    public afs: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router,
    public _toast: SearchKeywordService,
    private ngZone: NgZone
  ) {
    this.itemsCollection = afs.collection<any>('Users');
    this.savedItems = afs.collection('SavedItems');
    this.subscription = new Array<Subscription>();
  }

  forgot(e) {
    return this.auth.sendPasswordResetEmail(e);
  }

  signout() {
    localStorage.clear();
    this.auth.signOut();
  }

  isSignin() {
    return this.auth.authState;
  }

  emailsignup(userName, userEmail, userPassword, r) {
    return this.auth
      .createUserWithEmailAndPassword(userEmail.trim(), userPassword)
      .then((data) => {
        this.itemsCollection
          .add({
            firstName: userName,
            email: userEmail,
            role: 'client',
            accountCreate: new Date(),
            receiveEmail: r,
            supplierRating: 0,
            renterRating: 0,
            uid: data.user.uid,
            paypalEmail: '',
            stripe_user_id: '',
          })
          .catch((error) => {
            this._toast.nextMessage(error.message, 'error');
          });

        this.savedItems
          .add({
            userID: data.user.uid,
            saved: [],
          })
          .then((docref) => {
            docref.update({ savedID: docref.id });
          });
      });
  }

  googlesu(r) {
    var provider = new auth.GoogleAuthProvider();
    return auth()
      .signInWithPopup(provider)
      .then((result) => {
        if (result.additionalUserInfo.isNewUser) {
          this.itemsCollection.add({
            firstName: result.user.displayName
              ? result.user.displayName
              : 'Name',
            email: result.user.email,
            role: 'client',
            accountCreate: new Date(),
            receiveEmail: r,
            supplierRating: 0,
            renterRating: 0,
            uid: result.user.uid,
            photo: result.user.photoURL ? result.user.photoURL : '',
            paypalEmail: '',
            stripe_user_id: '',
          });

          this.savedItems
            .add({
              userID: result.user.uid,
              saved: [],
            })
            .then((docref) => {
              docref.update({ savedID: docref.id });
            });
        }
        if (result.user) {
          this.ngZone.run(() => {
            let myUser;
            this.ngZone.run(() => {
              this.subscription.push(
                this.afs
                  .collection('Users', (ref) =>
                    ref.where('uid', '==', result.user.uid)
                  )
                  .valueChanges()
                  .subscribe((users) => {
                    myUser = users[0];
                    let EnableStatus = myUser.EnableStatus
                      ? myUser.EnableStatus
                      : 'Enabled';
                    if (EnableStatus == 'Disabled') {
                      this._toast.nextMessage(
                        'The User has been disabled by the Administration!',
                        'error'
                      );
                      this.auth.signOut();
                    } else if (myUser.role != 'client') {
                      this._toast.nextMessage(
                        'The User Role is not Allowed!',
                        'error'
                      );
                      this.auth.signOut();
                    } else {
                      this.router.navigateByUrl('home');
                    }
                  })
              );
            });
          });
        }
      })
      .catch((error) => {
        this._toast.nextMessage(error.message, 'error');
      });
  }

  fbsu(r) {
    var provider = new auth.FacebookAuthProvider();
    return auth()
      .signInWithPopup(provider)
      .then((result) => {
        if (result.additionalUserInfo.isNewUser) {
          this.itemsCollection.add({
            firstName: result.user.displayName
              ? result.user.displayName
              : 'Name',
            email: result.user.email
              ? result.user.email
              : result.user.phoneNumber,
            role: 'client',
            accountCreate: new Date(),
            receiveEmail: r,
            supplierRating: 0,
            renterRating: 0,
            uid: result.user.uid,
            photo: result.user.photoURL ? result.user.photoURL : '',
            paypalEmail: '',
            stripe_user_id: '',
          });

          this.savedItems
            .add({
              userID: result.user.uid,
              saved: [],
            })
            .then((docref) => {
              docref.update({ savedID: docref.id });
            });
        }
        if (result.user) {
          let myUser;
          this.ngZone.run(() => {
            this.subscription.push(
              this.afs
                .collection('Users', (ref) =>
                  ref.where('uid', '==', result.user.uid)
                )
                .valueChanges()
                .subscribe((users) => {
                  myUser = users[0];
                  let EnableStatus = myUser.EnableStatus
                    ? myUser.EnableStatus
                    : 'Enabled';
                  if (EnableStatus == 'Disabled') {
                    this._toast.nextMessage(
                      'The User has been disabled by the Administration!',
                      'error'
                    );
                    this.auth.signOut();
                  } else if (myUser.role != 'client') {
                    this._toast.nextMessage(
                      'The User Role is not Allowed!',
                      'error'
                    );
                    this.auth.signOut();
                  } else {
                    this.router.navigateByUrl('home');
                  }
                })
            );
          });
        }
      })
      .catch((error) => {
        this._toast.nextMessage(error.message, 'error');
      });
  }

  emailsignin(e, p) {
    let myUser;
    return this.auth.signInWithEmailAndPassword(e, p).then((data) => {
      this.subscription.push(
        this.afs
          .collection('Users', (ref) => ref.where('uid', '==', data.user.uid))
          .valueChanges()
          .subscribe((users) => {
            myUser = users[0];
            let EnableStatus = myUser.EnableStatus
              ? myUser.EnableStatus
              : 'Enabled';
            if (EnableStatus == 'Disabled') {
              this._toast.nextMessage(
                'The User has been disabled by the Administration!',
                'error'
              );
              this.auth.signOut();
            } else if (myUser.role != 'client') {
              this._toast.nextMessage('The User Role is not Allowed!', 'error');
              this.auth.signOut();
            } else {
              this.router.navigateByUrl('home');
            }
          })
      );
    });
  }
  currentUser() {
    return this.auth.currentUser;
  }
  ngOnDestroy() {
    for (let sub of this.subscription) {
      sub.unsubscribe();
    }
  }
}
