<footer>
  <div class="container-fluid p-p-md-3 p-p-lg-6 p-mt-lg-4">
    <div class="p-grid">

      <!-- logo Display -->
      <div class="p-col-12 p-md-4 p-p-md-0 p-lg-3">
        <div class="logo">

          <img class="img" src="../../../assets/images/logo.png" (click)="imgLogo()">
          <div class="logoContent">

            <span>Share Adventure</span><br>
            <span>and Gear</span>

          </div>
        </div>
      </div>

      <!-- footer Content -->
      <div class="p-col-12 p-md-12 p-p-md-0  p-lg-6 p-mt-4 p-mt-md-0">
        <div class="p-grid">
          <div class="p-col-12 p-md-4">
            <ul>
              <li><a href="https://saagsharetribe.wordpress.com/tag/saag/" target="_blank">ABOUT US</a></li>
              <li><a href="https://saagsharetribe.wordpress.com/tag/saag/" target="_blank">WHY US/OUR EXPERIENCE</a></li>
              <li><a href="https://saagsharetribe.wordpress.com/" target="_blank">BLOG</a></li>
            </ul>
          </div>
          <div class="p-col-12 p-md-4 ">
            <ul>
              <li><a href="https://saagsharetribe.wordpress.com/tag/outdoor-gears/" target="_blank">SHOPPING INFO</a></li>
              <li><a href="https://saagsharetribe.wordpress.com/" target="_blank">FAQ's</a></li>
              <li><a (click)="TermsAndConditions()">TERMS AND CONDITIONS</a></li>
            </ul>
          </div>
          <div class="p-col-12 p-md-4">
            <ul>
              <li><a (click)="PrivacyPolicy()">PRIVACY POLICY</a></li>
              <li><a (click)="contactUs()">CONTACT US</a></li>
              <li><a href="https://saagsharetribe.wordpress.com/" target="_blank">MANUALS</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- social Apps -->
      <div class="p-col-12 p-md-12 p-lg-3 p-p-md-1 p-mt-lg-3">
        <div class="p-grid socials">

          <div class="p-col-2 p-offset-3 p-offset-md-4 p-offset-lg-1">
            <a class="insta" href="https://www.instagram.com/shareadventureandgear/?hl=en" target="_blank"><i class="fa fa-instagram"></i></a>
          </div>
          <div class="p-col-2">
            <button (click)="facebookRedirect()" pButton type="button" class="p-button-rounded fb" icon="fa fa-facebook"></button>
          </div>
          <div class="p-col-2">
            <button (click)="twitterRedirect()" pButton type="button" class="p-button-rounded skype" icon="fa fa-twitter"></button>
          </div>
        </div>
      </div>
      <!-- payment methods -->
      <div class="p-col-12">
        <div class="payMethods">
          <a><i class="fa fa-paypal"></i> </a>
          <a><i class="fa fa-credit-card"></i> </a>
          <a><i class="fa fa-cc-visa"></i> </a>
          <a><i class="fa fa-cc-mastercard"></i> </a>
          <a><i class="fa fa-cc-stripe"></i> </a>
        </div>
      </div>
    </div>
  </div>
</footer>
