import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  imgLogo() {
    this.router.navigateByUrl('/home');
  }

  facebookRedirect() {
    window.open('https://www.facebook.com/','_blank');
  }

  twitterRedirect() {
    window.open('https://www.twitter.com/','_blank');
  }

  contactUs() {
    this.router.navigateByUrl('/feedback');
  }

  TermsAndConditions(){
    window.open('assets/html-pages/terms-and-conditions.html',"_self")
  }
  PrivacyPolicy(){
    window.open('assets/html-pages/privacy-policy.html',"_self")
  }
}
