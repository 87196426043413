import { MapsAPILoader } from '@agm/core';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  rangeValues: number[] = [1, 100];
  min: number = 1;
  max: number = 999;
  userRating: number = 0;
  searchLocation;
  lat;
  lng;
  autofill: boolean = false;
  types;
  type;
  subscriptions;
  startDate;
  endDate;
  typeEDIT: boolean = false;
  minStartDate = new Date();
  minEndDate = new Date();
  addloading: boolean = true;
  Men = ['men'];
  Women = ['women'];
  Children = ['children'];
  @ViewChild('filtersloc') searchElementRef: ElementRef;

  constructor(
    private dialodeServies: DialogService,
    public config: DynamicDialogConfig,
    public _fetch: FetchitemsService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _fetchData: FetchitemsService,
    private _filter: SearchKeywordService,
    private route: Router
  ) {
    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      _filter.sharedFilterObj.subscribe((obj) => {
        if (obj != '') {
          this.subscriptions.push(
            this._fetchData.fetchcategories().subscribe((data) => {
              this.types = data;
              let myObj = JSON.parse(obj);
              if (myObj.type != '') {
                this.typeEDIT = true;
                this.types.splice(
                  this.types
                    .map((e) => {
                      return e.categoryName;
                    })
                    .indexOf(myObj.type),
                  1
                );
                this.types.unshift({ categoryName: myObj.type });
                this.type = { categoryName: myObj.type };
              }
              this.rangeValues = [myObj.rangeStart, myObj.rangeEnd];
              this.Men = myObj.forMen ? ['men'] : [];
              this.Women = myObj.forWomen ? ['women'] : [];
              this.Children = myObj.forChildren ? ['children'] : [];
              this.userRating = myObj.rating;
              this.searchLocation = myObj.location;
              this.startDate = myObj.startDate ? new Date(myObj.startDate) : '';
              this.endDate = myObj.endDate ? new Date(myObj.endDate) : '';

              this.addloading = false;
            })
          );
        } else {
          this.subscriptions.push(
            this._fetchData.fetchcategories().subscribe((data) => {
              this.types = data;
              this.addloading = false;
            })
          );
        }
      })
    );
  }

  ngOnInit(): void {}

  close() {
    this.dialodeServies.dialogComponentRefMap.forEach((item) => item.destroy());
  }

  clear() {
    this.typeEDIT = false;
    this._filter.nextFilterObj('');
    this.rangeValues = [1, 100];
    this.Men = ['men'];
    this.Women = ['women'];
    this.Children = ['children'];
    this.userRating = 0;
    this.searchLocation = '';
    this.startDate = '';
    this.endDate = '';
    this.type = '';
  }

  onchange() {
    if (this.lng != undefined) {
      this.lng = undefined;
    }
    if (this.lat != undefined) {
      this.lat = undefined;
    }
  }

  focused() {
    if (!this.autofill) {
      this.autofill = true;
      var options = {
        types: ['(regions)'],
      };
      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          options
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.formatted_address) {
              this.searchLocation = place.formatted_address;
            }
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
          });
        });
      });
    }
  }

  startDateSelect() {
    this.minEndDate = new Date(this.startDate);
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    this.endDate = '';
  }

  submit() {
    let obj = {
      rangeStart: this.rangeValues[0],
      rangeEnd: this.rangeValues[1],
      forMen: this.Men.length ? true : false,
      forWomen: this.Women.length ? true : false,
      forChildren: this.Children.length ? true : false,
      rating: this.userRating,
      location: this.searchLocation ? this.searchLocation : '',
      type: this.type ? this.type.categoryName : '',
      startDate: this.startDate ? this.startDate : '',
      endDate: this.endDate ? this.endDate : '',
    };
    this._filter.nextFilterObj(JSON.stringify(obj));
    this.route.navigateByUrl('filtered');
    this.dialodeServies.dialogComponentRefMap.forEach((item) => item.destroy());
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
