<br>
<div class="container">
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
  <div *ngIf="!loading">
    <div class="p-grid p-nogutter">
      <div class="p-col-12 p-md-12 p-lg-12">
        <div class="gallery">
          <p-galleria [value]="pictures" showItemNavigators="true" [showThumbnails]="false" autoPlay="true"
            circular="true">
            <ng-template pTemplate="item" let-item>
              <img class="gallerypic" [src]="item" alt="item.alt" />
            </ng-template>
          </p-galleria>
          <img *ngIf="!pictures.length" class="galleryNopic" src="../../../assets/images/noImage.jpg" alt="item.alt" />
        </div>
      </div>

    </div>

    <div class="p-grid p-nogutter ">
      <div class="p-col-12 p-md-8 p-lg-8 p-mt-4 ">

        <h4 class="heading p-ml-2">Details</h4>
        <div class="description">
          <p>{{ mydata.details }}</p>
        </div>

      </div>

      <div class="p-col-12 p-md-4 p-pr-md-0 p-mt-3 p-mt-md-0 ">
        <h4 class="heading p-ml-2">{{ mydata.title }}</h4>
        <div class="itemdetails-view  ">
          <div class="intro">
            <p *ngIf="!mydata.priceResT"><b>Price:</b> ${{ mydata.price1 }}</p>
            <p *ngIf="mydata.priceResT"><b>Price for Day One:</b> ${{ mydata.price1 }}</p>
            <p *ngIf="mydata.priceResT"><b>Price for other Days:</b> ${{ mydata.priceResT }}</p>
            <p><b>Type:</b> {{ mydata.type }}</p>
            <p><b>Location:</b> {{ mydata.location }}</p>
            <div class="flexedBetween">
              <p><i *ngIf="mydata.forMen" class="fa fa-check-circle forIcon"></i><i *ngIf="!mydata.forMen"
                  class="fa fa-times-circle forIconCross"></i> Men</p>
              <p><i *ngIf="mydata.forWomen" class="fa fa-check-circle forIcon"></i><i *ngIf="!mydata.forWomen"
                  class="fa fa-times-circle forIconCross"></i> Women</p>
              <p><i *ngIf="mydata.forChildren" class="fa fa-check-circle forIcon"></i><i *ngIf="!mydata.forChildren"
                  class="fa fa-times-circle forIconCross"></i> Children</p>
            </div>
            <p-rating class="rating" readonly='false' cancel [ngModel]="mydata.totalRating"></p-rating><br>
            <div *ngIf="!isSameUser || !userID" class="saveddiv">
              <button *ngIf="!isSaved" (click)="savebtn(mydata.id)" class="savebtn" pButton type="button"
                label=" Add to Saved"><i class="fa fa-heart"></i></button>
              <button *ngIf="isSaved" (click)="unsavebtn(mydata.id)" class="savebtn" pButton type="button"
                label=" Remove from Saved"><i class="fa fa-trash"></i></button>
              <button [style]="{'margin-top':'10px'}" (click)="copyMessage(mydata.id)" class="savebtn" pButton
                type="button" label=" Share"><i class="fa fa-share"></i></button>
            </div>

            <div *ngIf="isSameUser && userID" class="p-grid">
              <div class="p-col-6">
                <button (click)="copyMessage(mydata.id)" class="savebtn" pButton type="button" label=" "><i
                    class="fa fa-share"></i></button>
              </div>
              <div class="p-col-6">
                <button (click)="editItem()" class="savebtn" pButton type="button" label=" "><i
                    class="fa fa-edit"></i></button>
              </div>
            </div>
          </div>

        </div>

        <div *ngFor="let userData of user | async" class="seller p-mt-3">
          <div class="p-grid p-nogutter">
            <div class="p-col-3"><img class="renterPhoto" [src]="defaultPic" alt="supplierphoto"></div>
            <div class="p-col-8">
              <h4 class="supname p-ml-3 p-mt-2   p-mt-md-0 p-mt-xl-4 ">{{ userData.firstName }}</h4>
            </div>
          </div><br>
          <p><b>About:</b> {{ userData.userDetail?userData.userDetail:'Not Specified' }}</p>
          <p><b>User Location:</b> {{ userData.userLocation?userData.userLocation:'Not Specified' }}</p>
          <p-rating class="rating" readonly='false' cancel [ngModel]="userData.supplierRating"></p-rating><br>
          <button pButton class="savebtn" type="button" label=" View Profile" (click)="viewProfile(userData.uid)"><i
              class="fa fa-user"></i></button>
          <button *ngIf="!isSameUser" [style]="{'margin-top':'10px'}" pButton class="savebtn" type="button"
            label=" Chat Seller" (click)="chatSeller(userData.uid)"><i class="fa fa-comment"></i></button>
        </div>
        <div *ngIf="!isLogin || !isSameUser" class="availability p-mt-3">
          <h4 class="heading">Check Availability</h4><br>
          <p-calendar [disabledDates]="disabledDatesStart" [minDate]="minDatestart" [(ngModel)]="startDate"
            [maxDate]="maxDate" (onSelect)="startDateSelect()" showIcon="true" [style]="{'width':'100%'}"
            placeholder='From'></p-calendar>
          <br><br>
          <p-calendar [disabledDates]="disabledDatesEnd" [disabled]="!startDate" [minDate]="startDate"
            [maxDate]="maxDate" [(ngModel)]="endDate" showIcon="true" [style]="{'width':'100%'}" placeholder='To'>
          </p-calendar><br><br>
          <button [disabled]="checkDisable" (click)="availability()" pButton type="button" label=" Reserve"><i
              *ngIf="checkDisable" class="pi pi-spin pi-spinner"></i><i *ngIf="!checkDisable"
              class="fa fa-shopping-cart"></i></button>
          <button *ngIf="!isLogin" pButton [style]="{'margin-top':'10px'}" class="savebtn" type="button" label=" Login" (click)="router.navigateByUrl('/login')"><i
            class="fa fa-sign-in"></i></button>
        </div>
      </div>
    </div>

    <agm-map [latitude]="lat" [longitude]="lng">
      <agm-marker [latitude]="lat" [longitude]="lng">
        <agm-info-window [isOpen]="true">
          <strong>{{label}}</strong>
        </agm-info-window>
      </agm-marker>
    </agm-map>

    <div class="p-mb-4">
      <h4 class="heading p-mt-6 p-mb-2">Comments</h4><br>
      <div *ngIf="userID" class="p-grid">
        <div class="p-ml-md-4 p-pr-md-4 p-col-12 p-md-8 p-lg-8">
          <div class="form-group">
            <label>Add a Comment:</label>
            <textarea (keydown.enter)="$event.target.blur();submitComment()" [(ngModel)]="commentNew" type="text"
              class="form-control" pInputTextarea autoResize="autoResize" maxlength="500"></textarea>
          </div>
          <div class="flexedend">
            <i (click)="submitComment()" class="fa fa-chevron-circle-right commentIcon"></i>
          </div>
        </div>
      </div>
      <p-dataView [value]="comments | async" layout='list'>
        <ng-template let-data pTemplate="listItem">
          <div class="p-ml-md-4 p-pr-md-4 p-col-12 p-md-8 p-lg-8">
            <div>
              <div class="comment p-grid p-nogutter p-p-2 p-pl-md-3 p-pt-md-2 p-pr-md-3">
                <div class="p-col-2 p-md-1">
                  <img class="commentPhoto" [src]="data.renterPhoto?data.renterPhoto:'../../../assets/images/dp.png'"
                    alt="dp">
                </div>
                <div class="p-col-9 p-md-10 p-ml-3">
                  <label class="cmntName">{{ data.renterName }}</label>
                  <span class="cdate">{{ data.commentDate | date : 'medium' }}</span>
                  <p class="cmntText">{{ data.comment }}</p>
                </div>
              </div>
            </div><br>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>