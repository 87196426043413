import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import {FetchitemsService} from '../../services/fetchitems.service';
import {LoginsignupService} from 'src/app/services/loginsignup.service';
import {environment} from '../../../environments/environment';
import {SearchKeywordService} from 'src/app/services/search-keyword.service';
import {DialogService} from 'primeng/dynamicdialog';
import {FiltersComponent} from '../filters/filters.component';
import {UpdateItemService} from 'src/app/services/update-item.service';
import {UtilsCommon} from "../../utils/utils";

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
  providers: [DialogService],
})
export class ItemsComponent implements OnInit {
  categories: Observable<any[]>;
  list;
  pic;
  totalRecords = 24;
  lastdoc;
  lastdocCheck;
  mydata;
  mainLoading: boolean;
  savedItems: Array<any> = [''];
  savedItemstemp;
  savedID;
  userID;
  filterArray: Observable<any[]>;
  searchWord;
  filters;
  filterBy;
  loc;
  cat;
  title;
  rangeValues: number[] = [0, 0];
  min: number = 0;
  max: number = 999;
  subscriptions;
  markers;
  isLogin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _fetch: FetchitemsService,
    public _login: LoginsignupService,
    public _toast: SearchKeywordService,
    private dialodeServies: DialogService,
    private _update: UpdateItemService
  ) {
    this.subscriptions = new Array<Subscription>();

    this.filters = [
      {name: 'Filter by Title'},
      {name: 'Filter by Location'},
      {name: 'Filter by Price'},
    ];
    this.filterBy = {name: 'Filter by Title'};
    this.subscriptions.push(
      _login.isSignin().subscribe((uid) => {
        if (uid) {
          this.userID = uid.uid;
          this.isLogin = true;
          var temp = _fetch.fetchSavedArray(uid.uid);
          this.subscriptions.push(
            temp.subscribe((data) => {
              this.savedItemstemp = data[0];
              this.savedItems = this.savedItemstemp.saved;
              this.savedID = this.savedItemstemp.savedID;
            })
          );
        }
      })
    );
    this.mainLoading = true;
    this.lastdoc = new Array();
    this.lastdocCheck = new Array();
    this.lastdoc.push(null);
    this.lastdocCheck.push(0);
    this.pic = '../../../assets/images/noImage.jpg';
    this.list = new Array();
    this.categories = _fetch.fetchcategories();
    this.subscriptions.push(
      this.categories.subscribe((data) => {
        for (let i of data) {
          this.list.push({
            label: i.categoryName,
            routerLink: '/items/' + i.categoryName,
          });
        }
      })
    );
  }

  detail(id) {
    if (this.isLogin) {
      this._update.increaseViewCount(id, this.userID);
    } else {
      this._update.increaseViewCount(id);
    }
    this.router.navigate(['/itemdetail', id]);
  }

  loadData(event) {
    this.mainLoading = true;
    if (this.route.snapshot.paramMap.get('search')) {
      if (this.route.snapshot.paramMap.get('searchtitle') == 'null') {
        this.title = '';
      } else {
        this.title = this.route.snapshot.paramMap.get('searchtitle');
      }
      if (this.route.snapshot.paramMap.get('searchloc') == 'null') {
        this.loc = '';
      } else {
        this.loc = this.route.snapshot.paramMap.get('searchloc');
        this.loc = UtilsCommon.removeZipCode(this.loc);
      }
      if (this.route.snapshot.paramMap.get('searchcat') == 'null') {
        this.cat = '';
      } else {
        this.cat = this.route.snapshot.paramMap.get('searchcat');
      }
      this.filterArray = this._fetch.fetchBySearchKeyword(
        this.title.toLowerCase(),
        this.loc.toLowerCase(),
        this.cat
      );
      console.log(this.filterArray);
      this.subscriptions.push(
        this.filterArray.subscribe((data) => {
          if (data.length) {
            this.mydata = data;
            this.markers = this._fetch.fetchLongLat(this.mydata);
            this.lastdoc[event.first / event.rows + 1] = this.mydata[
            this.mydata.length - 1
              ].payload.doc;
            this.mainLoading = false;
          } else {
            this.mydata = [];
            this.mainLoading = false;
          }
        })
      );
    } else if (this.route.snapshot.paramMap.get('keyword') == 'allListing') {
      this.filterArray = this._fetch.fetchAll(
        this.lastdoc[event.first / event.rows]
      );
      this.subscriptions.push(
        this.filterArray.subscribe((data) => {
          if (data.length) {
            this.mydata = data;
            this.markers = this._fetch.fetchLongLat(this.mydata);
            this.lastdoc[event.first / event.rows + 1] = this.mydata[
            this.mydata.length - 1
              ].payload.doc;
            this.mainLoading = false;
            this.subscriptions.push(
              this._fetch
                .fetchAll(this.lastdoc[event.first / event.rows + 1])
                .subscribe((data) => {
                  if (!this.lastdocCheck[event.first / event.rows + 1]) {
                    this.totalRecords += data.length;
                    this.lastdocCheck[event.first / event.rows + 1] = 1;
                  }
                })
            );
          } else {
            this.mainLoading = false;
            this.mydata = [];
          }
        })
      );
    } else if (this.list.indexOf(this.route.snapshot.paramMap.get('keyword'))) {
      this.filterArray = this._fetch.fetchitembycategory(
        this.route.snapshot.paramMap.get('keyword'),
        this.lastdoc[event.first / event.rows]
      );
      this.subscriptions.push(
        this.filterArray.subscribe((data) => {
          if (data.length) {
            this.mydata = data;
            this.markers = this._fetch.fetchLongLat(this.mydata);
            this.lastdoc[event.first / event.rows + 1] = this.mydata[
            this.mydata.length - 1
              ].payload.doc;
            this.mainLoading = false;
            this.subscriptions.push(
              this._fetch
                .fetchitembycategory(
                  this.route.snapshot.paramMap.get('keyword'),
                  this.lastdoc[event.first / event.rows + 1]
                )
                .subscribe((data) => {
                  if (!this.lastdocCheck[event.first / event.rows + 1]) {
                    this.totalRecords += data.length;
                    this.lastdocCheck[event.first / event.rows + 1] = 1;
                  }
                })
            );
          } else {
            this.mainLoading = false;
            this.mydata = [];
          }
        })
      );
    }
  }

  ngOnInit(): void {
  }

  savedbtn(id) {
    if (this.userID) {
      if (this.savedItems.indexOf(id) >= 0) {
        this.savedItems.splice(this.savedItems.indexOf(id), 1);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      } else {
        this.savedItems.push(id);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      }
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  ifLiked(id) {
    if (this.savedItems.indexOf(id) >= 0) {
      return true;
    } else return false;
  }

  filter() {
    if (this.filterBy.name == 'Filter by Title') {
      this.subscriptions.push(
        this.filterArray
          .pipe(
            map((items) =>
              items.filter((item) =>
                item.payload.doc
                  .get('title')
                  .toLowerCase()
                  .includes(this.searchWord.toLowerCase())
              )
            )
          )
          .subscribe((data) => {
            this.mydata = data;
          })
      );
    }
  }

  onPage() {
    window.scroll(0, 0);
  }

  searchFilters() {
    this.dialodeServies.open(FiltersComponent, {
      contentStyle: {height: 'fit-content', overflow: 'auto'},
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
    });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + '/itemdetail/' + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toast.nextMessage('Link Copied to Clipboard', 'success');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
