<br>
<div class="container">

  <h3>Security Info</h3><br>
  <!-- Update Password -->
  <div id="update-password">
    <div class=" p-grid">
      <h5 class="p-col-9">Forget Password</h5>
      <button pButton [label]="updateBtnLabel" type="p-button-text" class="  btn p-col-3"
        (click)="onUpdateClick()"></button>

      <div class="p-col-12 " [style.display]="updatePasswordDisplay">
        <label class="p-col-12">Current Password</label>
        <input class="p-col-12 p-md-6 " type="password" placeholder="Enter Current Passowrd" [(ngModel)]=oldPassowrd
          maxlength="50" pInputText><br>
        <label class="error">{{oldPassowrdError}}</label>
        <label class="p-col-12">New Password</label>
        <input class="p-col-12 p-md-6 " type="password" placeholder="Enter New Passowrd" [(ngModel)]=newPassword
          maxlength="50" pInputText><br>
        <label class="error">{{newPassowrdError}}</label>
        <label class="p-col-12">Confirm Password</label>
        <input class="p-col-12 p-md-6 " type="password" placeholder="Confirm Passowrd" [(ngModel)]=confirmPassword
          maxlength="50" pInputText>
        <!-- upadte Button -->
        <button pButton [disabled]="loading" [class.spinner]="loading" class="p-col-3 p-md-3" label=Update type="button"
          (click)="onUpdatePassowrd()" class="p-button-raised pbtn p-ml-md-2 p-mt-md-0 p-mt-2 save"></button><br>
        <label class="error">{{confirmPassowrdError}}</label>


      </div>
    </div>
  </div>
</div>