import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { UpdateItemService } from 'src/app/services/update-item.service';
import * as moment from 'moment';
import { ChatService } from 'src/app/services/chat.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-itemdetail',
  templateUrl: './itemdetail.component.html',
  styleUrls: ['./itemdetail.component.scss'],
})
export class ItemdetailComponent implements OnInit {
  public item;
  mydata;
  ordered;
  public user;
  userData;
  public comments: Observable<any[]>;
  pictures;
  defaultPic;
  loading: boolean = true;
  savedItems: Array<any> = [''];
  savedArray;
  savedID;
  isSaved: boolean = false;
  supplierID;
  userID;
  disabledDatesStart;
  disabledDatesEnd;
  minDatestart;
  minDateend;
  checkDisable: boolean = false;
  startDate: Date;
  endDate: Date;
  days: number = 0;
  maxDate: Date;
  price1;
  priceResT;
  isSameUser: boolean = true;
  isLogin : boolean = false;
  commentNew: string = '';
  isPresent: boolean;
  subscriptions;
  lat;
  lng;
  label;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _fetch: FetchitemsService,
    public _login: LoginsignupService,
    private _put: UpdateItemService,
    private _chat: ChatService,
    public _toast: SearchKeywordService
  ) {
    this.subscriptions = new Array<Subscription>();

    this.pictures = new Array();
    this.disabledDatesStart = new Array<any>();
    this.disabledDatesEnd = new Array<any>();

    /////////////////////////////////////////////////////////////////////////////////

    this.ordered = _fetch.fetchOrderedDates(
      this.route.snapshot.paramMap.get('id')
    );
    this.subscriptions.push(
      this.ordered.subscribe((data) => {
        for (let item of data) {
          if (item.endDate != '') {
            for (
              var m = moment(item.startDate.toDate());
              m.diff(item.endDate.toDate(), 'days') <= 0;
              m.add(1, 'days')
            ) {
              this.disabledDatesStart.push(new Date(m.toString()));
              this.disabledDatesEnd.push(new Date(m.toString()));
            }
          } else {
            this.disabledDatesStart.push(item.startDate.toDate());
            this.disabledDatesEnd.push(item.startDate.toDate());
          }
        }
      })
    );

    ///////////////////////////////////////////////////////////////////////////////

    this.defaultPic = '../../../assets/images/dp.png';
    this.item = _fetch.fetchbyitemid(this.route.snapshot.paramMap.get('id'));
    this.subscriptions.push(
      this.item.subscribe((data) => {
        this.mydata = data[0];
        this.lat = this.mydata.latitude;
        this.lng = this.mydata.longitude;
        this.label = this.mydata.title;
        if (this.mydata.startDate != '') {
          if (
            moment(new Date()).isBefore(
              moment(new Date(this.mydata.startDate.seconds * 1000)),
              'day'
            )
          ) {
            this.minDatestart = new Date(this.mydata.startDate.seconds * 1000);
          } else {
            this.minDatestart = new Date();
          }
        } else {
          this.minDatestart = new Date();
        }
        if (this.mydata.endDate != '') {
          this.maxDate = new Date(this.mydata.endDate.seconds * 1000);
        } else {
          this.maxDate = null;
        }

        /////////////////////////////////

        this.price1 = this.mydata.price1;
        this.priceResT = this.mydata.priceResT
          ? this.mydata.priceResT
          : this.mydata.price1;
        this.supplierID = this.mydata.userID;
        if (data[0]) {
          if (data[0].photo) {
            this.pictures = data[0].photo;
          }
          this.user = _fetch.fetchuserbyIdasync(this.supplierID);
          this.subscriptions.push(
            this.user.subscribe((data) => {
              this.userData = data[0];
              if (this.userData.photo) {
                this.defaultPic = this.userData.photo;
              }
            })
          );
          this.comments = _fetch.fetchcomment(data[0].id);
          this.subscriptions.push(
            _login.isSignin().subscribe((uid) => {
              if (uid) {
                this.isLogin = true;
                if (uid.uid != this.mydata.userID) {
                  this.isSameUser = false;
                }
                this.userID = uid.uid;
                this.savedArray = _fetch.fetchSavedArray(uid.uid);
                this.subscriptions.push(
                  this.savedArray.subscribe((data) => {
                    this.savedItems = data[0].saved;
                    this.savedID = data[0].savedID;
                    if (
                      this.savedItems.indexOf(
                        this.route.snapshot.paramMap.get('id')
                      ) >= 0
                    ) {
                      this.isSaved = true;
                    }
                    this.loading = false;
                  })
                );
              } else {
                this.isLogin = false;
                this.loading = false;
              }
            })
          );
        }
      })
    );
  }

  ngOnInit(): void {}

  viewProfile(id) {
    this.router.navigate(['/viewprofile', id]);
  }

  savebtn(id) {
    if (this.userID) {
      this.savedItems.push(id);
      this._fetch.updateSavedArray(this.savedID, this.savedItems);
      this.isSaved = true;
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  unsavebtn(id) {
    this.savedItems.splice(this.savedItems.indexOf(id), 1);
    this._fetch.updateSavedArray(this.savedID, this.savedItems);
    this.isSaved = false;
  }

  availability() {
    this.isPresent = false;
    if (this.startDate && this.endDate) {
      // if (this.userID) {
        this.checkDisable = true;
        var d = moment(this.endDate).diff(moment(this.startDate), 'days');
        if (this.startDate == this.endDate) {
          this.days = 1;
        } else if (moment(this.startDate).isSame(moment(new Date()), 'day')) {
          this.days = d + 2;
        } else {
          this.days = d + 1;
        }
        for (
          var m = moment(moment(this.startDate));
          m.diff(moment(this.endDate), 'days') <= 0;
          m.add(1, 'days')
        ) {
          for (let i = 0; i < this.disabledDatesStart.length; i++) {
            if (m.isSame(moment(this.disabledDatesStart[i]), 'day')) {
              this.isPresent = true;
            }
          }
        }
        if (!this.isPresent) {
          if(!this.isLogin){
            this._toast.nextMessage("Product is available in provided dates, Please login to continue.","success");
            this.checkDisable = false;
          }
          else{
            let total =
            (this.days - 1) * parseInt(this.priceResT) + parseInt(this.price1);
          let orderObj = {
            startDate: this.startDate,
            endDate: this.endDate,
            itemID: this.route.snapshot.paramMap.get('id'),
            renterID: this.userID,
            supplierID: this.supplierID,
            supplierName: this.userData.firstName,
            totalPrice: total,
            itemName: this.mydata.title,
          };
          this.startDate = null;
          this.endDate = null;
          this.checkDisable = false;
          this._toast.nextOrder(JSON.stringify(orderObj));
          this.router.navigateByUrl('payment');
          }
        } else {
          this._toast.nextMessage('Cannot make this selection...!', 'error');
          this.checkDisable = false;
        }
      // } else {
      //   this.router.navigateByUrl('/login');
      //   this.checkDisable = false;
      // }
    }
  }

  startDateSelect() {
    this.minDateend = this.startDate;
    this.endDate = null;
  }

  submitComment() {
    if (this.commentNew != '') {
      this._put.addComment(
        this.commentNew,
        this.userID,
        this.route.snapshot.paramMap.get('id')
      );
      this.commentNew = '';
    }
  }

  chatSeller(s_id) {
    this.loading = true;
    this._chat.nextMessage(s_id, this.route.snapshot.paramMap.get('id'));
    this.router.navigateByUrl('/chats');
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + '/itemdetail/' + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toast.nextMessage('Link Copied to Clipboard', 'success');
  }

  editItem() {
    this._toast.nextItemEdit(JSON.stringify(this.mydata));
    this.router.navigateByUrl('/additem');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
