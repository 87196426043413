import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginsignupService } from '../../services/loginsignup.service';

@Component({
  selector: 'app-login-security',
  templateUrl: './login-security.component.html',
  styleUrls: ['./login-security.component.scss'],
})
export class LoginSecurityComponent implements OnInit {
  //user
  user;

  //For loading
  loading = false;

  //password Input Variables
  oldPassowrd = '';
  newPassword = '';
  confirmPassword = '';
  //Ui  Buttons Variable
  //update
  updatePasswordDisplay = 'none';
  updateBtnLabel = 'Update';

  // Errors
  oldPassowrdError;
  newPassowrdError;
  confirmPassowrdError;
  subscriptions;
  //constructor
  constructor(private _loginServies: LoginsignupService) {
    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      _loginServies.isSignin().subscribe((data) => {
        this.user = data;
      })
    );
  }

  ngOnInit(): void {}

  ///text Button Upadte  click
  onUpdateClick() {
    if (this.updateBtnLabel === 'Update') {
      this.updateBtnLabel = 'Cancel';
      this.updatePasswordDisplay = '';
    } else {
      this.updateBtnLabel = 'Update';
      this.updatePasswordDisplay = 'none';
    }
  }
  // On Upadte click Listerner
  onUpdatePassowrd() {
    if (
      this.oldPassowrd != '' &&
      this.newPassword != '' &&
      this.confirmPassword != ''
    ) {
      this._loginServies
        .emailsignin(this.user.email, this.oldPassowrd)
        .then(() => this.upadtePassword(this.newPassword))
        .catch((err) => {
          this.oldPassowrdError = err;
          this.newPassowrdError = '';
          this.confirmPassowrdError = '';
        });
    }
  }
  //fuction to update password
  upadtePassword(newPass) {
    if (this.newPassword === this.confirmPassword) {
      this.loading = false;

      let u = this._loginServies.currentUser();
      u.then((data) => {
        data
          .updatePassword(newPass)
          .then(() => {
            this.newPassowrdError = '';
            this.oldPassowrdError = '';
            this.confirmPassowrdError = '';
            this.loading = false;
            this.oldPassowrd = '';
            this.newPassword = '';
            this.confirmPassword = '';
          })
          .catch((err) => {
            this.newPassowrdError = err;
            this.oldPassowrdError = '';
            this.confirmPassowrdError = '';
            this.loading = false;
          });
      });
    } else {
      this.confirmPassowrdError = 'New Or Confirm Passowrd Must Match';
      this.newPassowrdError = '';
      this.oldPassowrdError = '';
    }
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
