import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FiltereditemsComponent } from './filtereditems/filtereditems.component';
import { HelpComponent } from './help/help.component';
import { ItemsComponent } from './items/items.component';
import { MyItemsComponent } from './my-items/my-items.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';

const routes: Routes = [
  { path: 'items/', component: ItemsComponent },
  { path: 'items/:keyword', component: ItemsComponent },
  {
    path: 'items/:search/:searchtitle/:searchloc/:searchcat',
    component: ItemsComponent,
  },
  { path: 'myitems', component: MyItemsComponent },
  { path: 'myitems/:keyword', component: MyItemsComponent },
  { path: 'viewprofile', component: ProfileViewComponent },
  { path: 'viewprofile/:id', component: ProfileViewComponent },
  { path: 'help', component: HelpComponent },
  { path: 'filtered', component: FiltereditemsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule {}
