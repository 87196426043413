<div class="overlay" *ngIf="addloading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="bigdiv">
  <div class="header flexed">
    <h4>Supplier Registration</h4>
  </div>
  <div class="body">
    <p class="bold">
      For becoming a Supplier, you must need a Stripe Connect Account!
    </p>

    <div class="center">
      <button (click)="stripeOauth()" pButton label="Create Account"></button>
    </div>
  </div>
</div>
