<div class="container">
    <div *ngIf="!loading" class="bigDiv">
        <div class="flexed"><img (click)="backHome()" class="logo" src="../../../assets/images/logo.png" alt="logo"></div>
        <h3 class="heading">How can we Help you?</h3>
        <div class="flexed faqs">
            <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="faqs">
            <ul>
                <li *ngFor="let faq of faqs; let last = last">
                    <h6 class="question">{{ faq.question }}</h6>
                    <p>{{ faq.answer }}</p>
                    <hr *ngIf="!last">
                </li>
            </ul>
        </div>
    </div>
</div>