import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { environment } from '../../../environments/environment';
import { FiltersComponent } from '../filters/filters.component';
import { DialogService } from 'primeng/dynamicdialog';
import { UpdateItemService } from 'src/app/services/update-item.service';

@Component({
  selector: 'app-filtereditems',
  templateUrl: './filtereditems.component.html',
  styleUrls: ['./filtereditems.component.scss'],
  providers: [DialogService],
})
export class FiltereditemsComponent implements OnInit {
  pic;
  mydata;
  mainLoading: boolean = true;
  isLogin: boolean = false;
  savedItems: Array<any> = [''];
  savedItemstemp;
  savedID;
  userID;
  subscriptions;
  markers;
  lat;
  lng;
  filterObj;

  constructor(
    private router: Router,
    private _fetch: FetchitemsService,
    public _login: LoginsignupService,
    public _toast: SearchKeywordService,
    private dialodeServies: DialogService,
    private _update: UpdateItemService
  ) {
    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      _login.isSignin().subscribe((uid) => {
        this.subscriptions.push(
          _toast.sharedFilterObj.subscribe((obj) => {
            if (obj != '') {
              this.filterObj = JSON.parse(obj);
              _fetch.filtersSerch(this.filterObj).then((data) => {
                this.mydata = data;
                this.markers = this._fetch.fetchLongLat(this.mydata, 'true');
                this.mainLoading = false;
              });
            } else {
              this.mainLoading = false;
            }
          })
        );
        if (uid) {
          this.userID = uid.uid;
          this.isLogin = true;
          var temp = _fetch.fetchSavedArray(uid.uid);
          this.subscriptions.push(
            temp.subscribe((data) => {
              this.savedItemstemp = data[0];
              this.savedItems = this.savedItemstemp.saved;
              this.savedID = this.savedItemstemp.savedID;
            })
          );
        }
      })
    );
    this.pic = '../../../assets/images/noImage.jpg';
  }

  ngOnInit(): void {}

  searchFilters() {
    this.dialodeServies.open(FiltersComponent, {
      contentStyle: { height: 'fit-content', overflow: 'auto' },
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
    });
  }

  detail(id) {
    if (this.isLogin) {
      this._update.increaseViewCount(id, this.userID);
    } else {
      this._update.increaseViewCount(id);
    }
    this.router.navigate(['/itemdetail', id]);
  }

  savedbtn(id) {
    if (this.userID) {
      if (this.savedItems.indexOf(id) >= 0) {
        this.savedItems.splice(this.savedItems.indexOf(id), 1);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      } else {
        this.savedItems.push(id);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      }
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  ifLiked(id) {
    if (this.savedItems.indexOf(id) >= 0) {
      return true;
    } else return false;
  }

  onPage() {
    window.scroll(0, 0);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + '/itemdetail/' + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toast.nextMessage('Link Copied to Clipboard', 'success');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
