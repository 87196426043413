<br><br>
<div class="container">
    <agm-map *ngIf="!mainLoading" [latitude]="lat" [longitude]="lng">
        <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng">
            <agm-info-window [isOpen]="true">
                <strong>{{marker.label}}</strong>
            </agm-info-window>
        </agm-marker>
    </agm-map>
    <p-dataView [value]="mydata" [paginator]="true" [rows]="24" [loading]="mainLoading" layout="grid"
        (onPage)="onPage()">
        <p-header>
            <div class="flexed">
                <div class="divInlineBlock flexedcenter">
                    <button (click)="searchFilters()" pButton label=" Filters" type="button"
                        class="marginTop divInlineBlock btn"><i class="pi pi-sliders-v"></i></button>
                </div>
                <div>
                    <p-dataViewLayoutOptions class="marginLeft divInlineBlock"></p-dataViewLayoutOptions>
                </div>
            </div>
        </p-header>
        <ng-template let-data pTemplate="listItem">
            <div class="caroitemList">
                <div class="row">
                    <div class="col-sm-3">
                        <img *ngIf='data.photo' class="itemimgList" (click)='detail(data.id)'
                            [src]='data.photo[0]?data.photo[0]:pic' alt="image">
                        <img *ngIf='!data.photo' class="itemimgList" [src]='pic' alt="image" (click)='detail(data.id)'>
                    </div>
                    <div class="col-sm-5">
                        <h5 class="itemtitleList textOverflow">{{ data.title }}</h5>
                        <div class="rating">
                            <p class="location"><i class="fa fa-star starIcon"></i>
                                {{ data.totalRating | number: '1.0-0' }}</p>
                        </div>
                        <div>
                            <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                                {{ data.location }}</p>
                        </div>
                        <div class="detailPreview">
                            <i (click)='copyMessage(data.id)' class="fa fa-share-alt"></i>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <p class="price">${{data.price1}}</p>
                        <button pButton [ngClass]='{ likebtnRed : ifLiked(data.id), likebtn: !ifLiked(data.id) }'
                            (click)='savedbtn(data.id)' type="button" icon='fa fa-heart' type="button"
                            icon='fa fa-heart' class="p-button-rounded"></button>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template let-data pTemplate="gridItem">
            <div class="p-xs-12 p-sm-6 p-md-4 p-lg-4 p-xl-3 p-nogutter">
                <div *ngIf="data">
                    <br>
                    <div class="outerCaroItem">
                        <div class="caroitem">
                            <div>
                                <p class="price">${{data.price1}}</p>
                                <button pButton
                                    [ngClass]='{ likebtnRed : ifLiked(data.id), likebtn: !ifLiked(data.id) }'
                                    (click)='savedbtn(data.id)' type="button" icon='fa fa-heart'
                                    class="p-button-rounded"></button>
                            </div>
                            <img *ngIf='data.photo' class="itemimg" (click)='detail(data.id)'
                                [src]='data.photo[0]?data.photo[0]:pic' alt="image">
                            <img *ngIf='!data.photo' class="itemimg" [src]='pic' alt="image" (click)='detail(data.id)'>
                            <h5 class="itemtitle textOverflow">{{ data.title }}</h5>
                            <div class="p-grid p-nogutter itemfooter">
                                <div class="p-col-3 rating">
                                    <p class="location"><i class="fa fa-star starIcon"></i>
                                        {{ data.totalRating | number: '1.0-0' }}</p>
                                </div>
                                <div class="p-col-8">
                                    <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                                        {{ data.location }}</p>
                                </div>
                                <div class="p-col-1 detailPreview">
                                    <i (click)='copyMessage(data.id)' class="fa fa-share-alt"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div>