import { Component, OnInit } from '@angular/core';
import { LoginsignupService } from '../../services/loginsignup.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm;
  typePassword: string = 'password';
  typePasswordConfirm: string = 'password';
  loading: boolean = false;
  termsContent: boolean = false;

  constructor(
    private _siginUp: LoginsignupService,
    public fb: FormBuilder,
    private router: Router,
    private dialodeServies: DialogService,
    public _toast: SearchKeywordService
  ) {
    this.signupForm = fb.group({
      userName: ['', [Validators.required, Validators.maxLength(20)]],
      userEmail: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      userPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      userConfirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      receiveEmail: [false],
      terms: [false],
    });
  }

  ngOnInit(): void {}
  //ON SIGN UP CLICK
  SignUp() {
    this.loading = true;
    this._siginUp
      .emailsignup(
        this.username.value,
        this.email.value,
        this.password.value,
        this.receiveEmail.value
      )
      .then(() => {
        this._toast.nextMessage('Registered Successfully!', 'success');
        this.loading = false;
        this.router.navigateByUrl('/home');
        this.dialodeServies.dialogComponentRefMap.forEach((item) =>
          item.destroy()
        );
      })
      .catch((err) => {
        this._toast.nextMessage(err.message, 'error');
        this.loading = false;
      });
  }

  get username() {
    return this.signupForm.get('userName');
  }
  get email() {
    return this.signupForm.get('userEmail');
  }
  get password() {
    return this.signupForm.get('userPassword');
  }
  get confirmPassword() {
    return this.signupForm.get('userConfirmPassword');
  }
  get receiveEmail() {
    return this.signupForm.get('receiveEmail');
  }
  get terms() {
    return this.signupForm.get('terms');
  }

  close() {
    this.dialodeServies.dialogComponentRefMap.forEach((item) => item.destroy());
  }

  passView() {
    if (this.typePassword == 'password') {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }

  passConfirmView() {
    if (this.typePasswordConfirm == 'password') {
      this.typePasswordConfirm = 'text';
    } else {
      this.typePasswordConfirm = 'password';
    }
  }

  isTerms() {
    if (this.termsContent) {
      this.termsContent = false;
    } else {
      this.termsContent = true;
    }
  }
}
