<p-progressSpinner *ngIf="loading"></p-progressSpinner>

<div *ngIf="!loading">
    <div class="container">
        <div [style]="pointerEvents" class="p-grid p-nogutter">
            <div class="p-col-4">
                <div class="leftDivIcon flexedcenter"><i (click)="displayChats()"
                        class="pi pi-align-center display"></i></div>
                <div *ngIf="display" class="leftDiv">
                    <p-dataView [value]="myChats">
                        <ng-template let-data pTemplate="listItem">
                            <div (click)="chatClick(data.supplier, data.renter, data.itemID, data.chatID)"
                                [ngClass]='{ chatItem : !isSelected(data.chatID), chatItemSelected: isSelected(data.chatID) }'
                                class="p-grid">
                                <div class="p-xs-12 p-sm-5 p-md-4 p-lg-2 flexedcenter">
                                    <img class="dpChat" [src]="data.avatar" alt="dp">
                                </div>
                                <div class="p-xs-12 p-sm-7 p-md-8 p-lg-10 flexedspace">
                                    <p [ngClass]='{ notifyText: !data.seen }' class="displayName">{{ data.itemName }}
                                    </p>
                                    <i *ngIf="!data.seen" class="pi pi-circle-on notifyIcon"></i>
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>
                </div>
            </div>
            <div [ngClass]='{ fullWidth : !display }' class="p-col-8">
                <div *ngIf="noSelected" class="rightDiv flexedcenter">
                    <p>Select Chat to View</p>
                </div>
                <div *ngIf="!noSelected" class="rightDiv">
                    <div class="chatHeading flexedleft">
                        <img (click)="profile(userSelected.uid)" class="dpHeading" [src]="selectedUserImage"
                            alt="image">
                        <h5 class="chatHeadingText">{{ selectedUserName }}</h5>
                    </div>
                    <div class="mess" #scrollMe>
                        <p-dataView [value]="myMess">
                            <ng-template let-data pTemplate="listItem">
                                <div class="messageItem">
                                    <small
                                        *ngIf="!isMine(data.user._id)">{{ data.createdAt.seconds * 1000 | date : 'medium' }}</small>
                                    <div
                                        [ngClass]='{ floatRight : isMine(data.user._id) , floatLeft : !isMine(data.user._id)}'>
                                        <p
                                            [ngClass]='{ messageTextMine : isMine(data.user._id), messageText: !isMine(data.user._id) }'>
                                            {{ data.text }}</p>
                                    </div>
                                    <small class="rightAlign"
                                        *ngIf="isMine(data.user._id)">{{ data.createdAt.seconds * 1000 | date : 'medium' }}</small>
                                </div>
                            </ng-template>
                        </p-dataView>
                    </div>
                    <div class="inputMess flexedbottom">
                        <span class="p-input-icon-right">
                            <i (click)="$event.target.blur();send()" class="fa fa-chevron-circle-right msgSend"></i>
                            <input (keydown.enter)="send()" type="text" [style]="{ 'width':'50vw' }" pInputText
                                [(ngModel)]="newMessages" placeholder="Enter new Message">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>