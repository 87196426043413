import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { StripeconnectService } from '../../services/stripeconnect.service';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { Router } from '@angular/router';
import { LoginsignupService } from 'src/app/services/loginsignup.service';

@Component({
  selector: 'app-stripe-redirect',
  templateUrl: './stripe-redirect.component.html',
  styleUrls: ['./stripe-redirect.component.scss'],
})
export class StripeRedirectComponent implements OnInit {
  response;
  addloading: boolean = false;
  subscription;
  id;
  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private _http: HttpClient,
    private _stripeconnectService: StripeconnectService,
    private _fetch: FetchitemsService,
    private _loginServices: LoginsignupService,
    // public config: DynamicDialogConfig,
    // private dialodeServies: DialogService,
    private _toast: SearchKeywordService
  ) {
    this.subscription = new Array<Subscription>();
    this.subscription.push(
      _loginServices.isSignin().subscribe((data) => {
        console.log('DATA IS ========', data.uid);
        this.id = data.uid;
      })
    );
    // this.id = config.data.id;
  }

  stripeData() {
    var code = this.router.snapshot.queryParams.code;
    this._stripeconnectService.stripeConnect(code).subscribe((res) => {
      this.response = res;
      this.addloading = true;
      this.subscription.push(
        this._fetch
          .updateStripeUserId(this.response.stripe_user_id, this.id)
          .subscribe(() => {
            this.route.navigate(['/additem']);
          })
      );
    });
  }
  ngOnInit(): void {}
}
