import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { UpdateItemService } from 'src/app/services/update-item.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import 'src/assets/smtp.js'
import *as Template from '../../shared/email-templates/templates.js'
import { LoginsignupService } from 'src/app/services/loginsignup.service.js';
import {EmailServiceService} from 'src/app/services/email-service.service'
@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss'],
})
export class CancelOrderComponent implements OnInit {
  subscriptions;
  itemName:any;
  results:any
  orderID: String;
  orderItem;
  addloading: boolean = false;
  within48Hrs: boolean;
  productData: any
  userEmail: any
  constructor(
    private router: Router,
    private dialodeServies: DialogService,
    private _updateItem: UpdateItemService,
    public config: DynamicDialogConfig,
    public _fetch: FetchitemsService,
    private _loginServices: LoginsignupService,
    private emailService:EmailServiceService,
  ) {
    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      _loginServices.isSignin().subscribe(data => {
        this.userEmail = data;
      })
    )
    this.orderID = config.data.id;
    this.subscriptions.push(
      _fetch.fetchOrderDetails(this.orderID).subscribe((data) => {
        this.orderItem = data[0];
        if (
          moment(new Date(this.orderItem.startDate.seconds * 1000)).diff(
            moment(new Date()),
            'days'
          ) < 2
        ) {
          this.within48Hrs = true;
        } else {
          this.within48Hrs = false;
        }
      })
    );
  }

  ngOnInit(): void { }

  close() {
    this.dialodeServies.dialogComponentRefMap.forEach((item) => item.destroy());
  }

  submit() {
    this.addloading = true;
    // this.subscriptions.push(
    //   this._updateItem.cancelOrder(this.orderID).subscribe((data) => {
    //     this.subscriptions.push(
    //       data.subscribe((dat) => {
    //         this.subscriptions.push(
    //           dat.subscribe((da) => {
    //             this.subscriptions.push(
    //               da.subscribe((d) => {
    //                 this.subscriptions.push(
    //                   d.subscribe((dd) => {
    //                     dd.then(() => {
    //                       this.dialodeServies.dialogComponentRefMap.forEach(
    //                         (item) => item.destroy()
    //                       );
    //                       if (this.within48Hrs === true) {
    //                         this._fetch.fetchOrderDetails(this.orderID).subscribe(res=>{
    //                           this.productData = res[0];
    //                           var itemid = this.productData.itemID; 
    //                           this._fetch.fetchbyitemid(itemid).subscribe(data=>{
    //                             this.itemName = data[0];                       
    //                             var data1 = {
    //                               itemName:  this.itemName.title,
    //                               totalAmount: this.itemName.price1
    //                             }
    //                             var emailData={
    //                               email:this.userEmail.email,
    //                               message:Template.cancelOrderTemplate(data1)
    //                             }
    //                             this.emailService.emailNotification(emailData).subscribe(result =>{
    //                               this.results =result;
    //                             })
                                
    //                           })
    //                         })
                            

    //                       } else {
    //                         this._fetch.fetchOrderDetails(this.orderID).subscribe(res=>{
    //                           this.productData = res[0];
    //                           var itemid = this.productData.itemID; 
    //                           this._fetch.fetchbyitemid(itemid).subscribe(data=>{
    //                             this.itemName = data[0];                              
    //                             var data1 = {
    //                               itemName:  this.itemName.title,
    //                               totalAmount: this.itemName.price1
    //                             }
    //                             var emailData={
    //                               email:this.userEmail.email,
    //                               message:Template.cancelOrderTemplateNot48(data1)
    //                             }
    //                             this.emailService.emailNotification(emailData).subscribe(result =>{
    //                               this.results =result;
    //                             })
                                
    //                           })
    //                         })
    //                       }
    //                       this.redirectTo('myitems/orderHistory');
    //                     });
    //                   })
    //                 );
    //               })
    //             );
    //           })
    //         );
    //       })
    //     );
    //   })
    // );
    this._updateItem.cancelRefundOrder(this.orderID).subscribe((data)=>{
      this.subscriptions.push(
        data.subscribe((dat) => {
          this.subscriptions.push(
            dat.subscribe((da)=> {
              da.then(()=>{
                this.dialodeServies.dialogComponentRefMap.forEach(
                  (item) => item.destroy()
                );
                if (this.within48Hrs === true) {
                  this._fetch.fetchOrderDetails(this.orderID).subscribe(res=>{
                    this.productData = res[0];
                    var itemid = this.productData.itemID; 
                    this._fetch.fetchbyitemid(itemid).subscribe(data=>{
                      this.itemName = data[0];                       
                      var data1 = {
                        itemName:  this.itemName.title,
                        totalAmount: this.itemName.price1
                      }
                      var emailData={
                        email:this.userEmail.email,
                        message:Template.cancelOrderTemplateNot48(data1)
                      }
                      this.emailService.emailNotification(emailData).subscribe(result =>{
                        this.results =result;
                      })
                      
                    })
                  })
                  

                } else {
                  this._fetch.fetchOrderDetails(this.orderID).subscribe(res=>{
                    this.productData = res[0];
                    var itemid = this.productData.itemID; 
                    this._fetch.fetchbyitemid(itemid).subscribe(data=>{
                      this.itemName = data[0];                              
                      var data1 = {
                        itemName:  this.itemName.title,
                        totalAmount: this.itemName.price1
                      }
                      var emailData={
                        email:this.userEmail.email,
                        message:Template.cancelOrderTemplateNot48(data1)
                      }
                      this.emailService.emailNotification(emailData).subscribe(result =>{
                        this.results =result;
                      })
                      
                    })
                  })
                }
              })
            })
          )
        }))
    })
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
