import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  myChats;
  singleChat;
  myMess = [];
  newMessages;
  itemID: string = '';
  chatID: string = '';
  loading: boolean = true;
  supplierID: string = '';
  uid;
  selectedUserName;
  isScrolled: boolean = false;
  noSelected: boolean = true;
  userSelected;
  renterID: string = '';
  selectedUserImage;
  isRenter: boolean;
  subscriptions;
  display: boolean = true;
  pointerEvents = 'pointer-events: none';
  chatsListSubs: Subscription;

  constructor(
    private _chat: ChatService,
    public _user: LoginsignupService,
    public route: ActivatedRoute,
    private _fetch: FetchitemsService,
    private router: Router
  ) {
    this.subscriptions = new Array<Subscription>();
    if (window.innerWidth <= 575) {
      this.display = false;
    }

    this.subscriptions.push(
      _user.isSignin().subscribe((user) => {
        if (user) {
          this.uid = user.uid;
          _chat.fetchChats(user.uid).then((data) => {
            this.chatsListSubs = data.subscribe((chats) => {
              this.myChats = chats;
              this.myChats.sort(this.sortByProperty()).reverse();
              this.subscriptions.push(
                _chat.sharedItemID.subscribe((itemid) => {
                  if (itemid != '') {
                    this.itemID = itemid;
                    this.subscriptions.push(
                      _chat.sharedID.subscribe((supid) => {
                        if (supid != '') {
                          this.supplierID = supid;
                          this.renterID = user.uid;
                          this.subscriptions.push(
                            _chat
                              .getMessages(supid, user.uid, itemid, this.uid)
                              .subscribe((msgs) => {
                                this.singleChat = msgs;
                                this.myMess = this.singleChat.messages;
                                this.chatID = this.singleChat.chatID;
                                this.subscriptions.push(
                                  _fetch
                                    .fetchuserbyIdasync(supid)
                                    .subscribe((users) => {
                                      this.userSelected = users[0];
                                      this.selectedUserName = this.userSelected.firstName;
                                      this.selectedUserImage = this.userSelected
                                        .photo
                                        ? this.userSelected.photo
                                        : '../../../assets/images/dp.png';
                                      this.noSelected = false;
                                      _chat.nextMessage('', '');
                                      this.isScrolled = false;
                                      setTimeout(() => {
                                        this.pointerEvents =
                                          'pointer-events: visible';
                                        this.loading = false;
                                      }, 2000);
                                    })
                                );
                              })
                          );
                        }
                      })
                    );
                  } else {
                    setTimeout(() => {
                      this.pointerEvents = 'pointer-events: visible';
                      this.loading = false;
                    }, 2000);
                  }
                })
              );
            });
          });
        }
      })
    );
  }

  sortByProperty() {
    return (a, b) => {
      if (
        a.payload.doc.data().messages[a.payload.doc.data().messages.length - 1]
          .createdAt.seconds >
        b.payload.doc.data().messages[b.payload.doc.data().messages.length - 1]
          .createdAt.seconds
      )
        return 1;
      else if (
        a.payload.doc.data().messages[a.payload.doc.data().messages.length - 1]
          .createdAt.seconds <
        b.payload.doc.data().messages[b.payload.doc.data().messages.length - 1]
          .createdAt.seconds
      )
        return -1;

      return 0;
    };
  }

  @ViewChild('scrollMe') private scrollBottom: ElementRef;

  ngOnInit() {
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    if (!this.isScrolled) {
      this.scrollToBottom();
      this.isScrolled = true;
    }
  }

  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
    } catch (err) {}
  }

  send() {
    this.pointerEvents = 'pointer-events: none';
    let supplier, renter;
    if (this.uid == this.supplierID) {
      this.isRenter = false;
      supplier = this.uid;
      renter = this.renterID;
    } else {
      this.isRenter = true;
      supplier = this.supplierID;
      renter = this.renterID;
    }
    if (this.newMessages != '') {
      this.subscriptions.push(
        this._chat
          .newMessage({
            _id: new Date().valueOf(),
            createdAt: new Date(),
            from: this.uid,
            renter: renter,
            supplier: supplier,
            text: this.newMessages,
            isRenter: this.isRenter,
            itemID: this.itemID,
          })
          .subscribe((data) => {
            data.then(() => {
              if (this.myMess.length == 1) {
                this.subscriptions.push(
                  this._chat
                    .getMessages(supplier, renter, this.itemID, this.uid)
                    .subscribe((msgs) => {
                      this.singleChat = msgs;
                      this.myMess = this.singleChat.messages;
                      this.chatID = this.singleChat.chatID;
                    })
                );
              }
            });
            setTimeout(() => {
              this.pointerEvents = 'pointer-events: visible';
            }, 1000);
          })
      );
      this.newMessages = '';
    }
  }

  chatClick(supplier, renter, itemid, chatid) {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.pointerEvents = 'pointer-events: none';
    this.chatID = chatid;
    this.itemID = itemid;
    this.supplierID = supplier;
    this.renterID = renter;
    let id;
    if (supplier == this.uid) {
      this.isRenter = false;
      id = renter;
    } else {
      this.isRenter = true;
      id = supplier;
    }
    this.loading = true;
    this.subscriptions.push(
      this._chat
        .getMessages(supplier, renter, itemid, this.uid)
        .subscribe((msgs) => {
          this.singleChat = msgs;
          this.myMess = this.singleChat.messages;
          this.subscriptions.push(
            this._fetch.fetchuserbyIdasync(id).subscribe((users) => {
              this.userSelected = users[0];
              this.selectedUserName = this.userSelected.firstName;
              this.selectedUserImage = this.userSelected.photo
                ? this.userSelected.photo
                : '../../../assets/images/dp.png';
              this.loading = false;
              this.noSelected = false;
              this.isScrolled = false;
              if (window.innerWidth <= 575) {
                this.display = false;
              }
              setTimeout(() => {
                this.pointerEvents = 'pointer-events: visible';
              }, 500);
            })
          );
        })
    );
  }

  isSelected(chatid) {
    if (chatid == this.chatID) {
      return true;
    } else {
      return false;
    }
  }

  isMine(id) {
    if (id == this.uid) {
      return true;
    } else {
      return false;
    }
  }

  profile(id) {
    this.router.navigate(['/viewprofile', id]);
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  displayChats() {
    this.chatsListSubs.unsubscribe();
    if (this.display) {
      this.display = false;
    } else {
      this.display = true;
    }
  }
}
