import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdditemComponent } from './additem/additem.component';
import { ChatComponent } from './chat/chat.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { ItemdetailComponent } from './itemdetail/itemdetail.component';
import { LoginSecurityComponent } from './login-security/login-security.component';
import { LoginComponent } from './login/login.component';
import { PaymentComponent } from './payment/payment.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { SignupComponent } from './signup/signup.component';
import { StripeRedirectComponent } from './stripe-redirect/stripe-redirect.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'additem', component: AdditemComponent },
  { path: 'itemdetail/:id', component: ItemdetailComponent },
  { path: 'securitydetails', component: LoginSecurityComponent },
  { path: 'profile', component: ProfileInfoComponent },
  { path: 'chats', component: ChatComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'feedback/:email', component: FeedbackComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'stripe', component: StripeRedirectComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {}
