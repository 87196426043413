<div *ngIf="isLogin">
    <div class="container">
        <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
        <br>
        <div *ngIf="!noSaved || !isSavedTab">
            <p-dataView [value]="mydata" [paginator]="true" [rows]="24" [lazy]="true" [loading]="mainLoading"
                (onLazyLoad)="loadData($event)" [totalRecords]="totalRecords" layout="grid" (onPage)="onPage()">
                <p-header>
                    <div class="flexed">
                        <div class="flexedcenter">
                            <div *ngIf="filterBy.name=='Filter by Price'" class="divInlineBlock">
                                <p class="rangeValues">{{ rangeValues }}</p>
                                <p-slider (onChange)="handleChange($event)" class="range" [(ngModel)]="rangeValues"
                                    [range]="true" [animate]="true" [min]="min" [max]="max">
                                </p-slider>
                            </div>
                            <input *ngIf="filterBy.name!='Filter by Price'" type="search" class="divInlineBlock"
                                pInputText placeholder="Search" [(ngModel)]="searchWord" (input)='filter()'>
                            <p-dropdown class="divInlineBlock marginLeft" inputId="dropdown" [options]="filters"
                                [(ngModel)]="filterBy" optionLabel="name">
                            </p-dropdown>
                        </div>
                        <div class="divInlineBlock flexedcenter">
                            <button *ngIf="ifOrderedTab" class="marginLeft btn" (click)="orderNow()" pButton
                                label="Order Now!"></button>
                            <button *ngIf="isSuppliedTab" class="marginLeft btn" (click)="addListing()" pButton
                                label="Add a Listing"></button>
                            <p-dataViewLayoutOptions class="divInlineBlock marginLeft"></p-dataViewLayoutOptions>
                        </div>
                    </div>

                </p-header>
                <ng-template let-data pTemplate="gridItem">
                    <div class="p-xs-12 p-sm-6 p-md-4 p-lg-4 p-xl-3 p-nogutter">
                        <div *ngIf="data">
                            <br>
                            <div class="outerCaroItem">
                                <div class="caroitem">
                                    <div>
                                        <p class="price">${{data.payload.doc.get('price1')}}</p>
                                        <button pButton type="button" icon='fa fa-heart' class="p-button-rounded"
                                            [ngClass]='{ likebtnRed : ifLiked(data.payload.doc.get("id")), likebtn: !ifLiked(data.payload.doc.get("id")) }'
                                            (click)='savedbtn(data.payload.doc.get("id"))'></button>
                                    </div>
                                    <div (click)='detail(data.payload.doc.get("id"))' style="cursor: pointer;">
                                        <img *ngIf='data.payload.doc.get("photo")' class="itemimg"
                                            [src]='data.payload.doc.get("photo")[0]?data.payload.doc.get("photo")[0]:pic'
                                            alt="image">
                                        <img *ngIf='!data.payload.doc.get("photo")' class="itemimg" [src]='pic'
                                            alt="image">
                                        <div class="tagDiv" *ngIf="isHistoryTab && data.isCanceled">
                                            <h5 class="floatLeft">{{ data.payload.doc.get('title') }}</h5>
                                            <div class="tag">Cancelled</div>
                                        </div>
                                        <div class="tagDiv" *ngIf="(isSuppliedTab || isSavedTab) && data.isExpired">
                                            <h5 class="floatLeft">{{ data.payload.doc.get('title') }}</h5>
                                            <div class="tagExp">Expired</div>
                                        </div>
                                        <div class="tagDiv"
                                             *ngIf="ifRentedAwayTab && data.isConfirmed && !data.isCompleted && !data.isCanceled">
                                            <h5 class="floatLeft">{{ data.payload.doc.get('title') }}</h5>
                                            <div class="tagCompleted">Approved</div>
                                        </div>
                                        <div class="tagDiv"
                                            *ngIf="ifRentedAwayTab && data.isCompleted && !data.isCanceled">
                                            <h5 class="floatLeft">{{ data.payload.doc.get('title') }}</h5>
                                            <div class="tagCompleted">Completed</div>
                                        </div>
                                        <div class="tagDiv"
                                            *ngIf="ifRentedAwayTab && data.isCompleted && data.isCanceled">
                                            <h5 class="floatLeft">{{ data.payload.doc.get('title') }}</h5>
                                            <div class="tag">Cancelled</div>
                                        </div>
                                        <div *ngIf="ifOrderedTab && data.isConfirmed && !data.isCompleted && !data.isCanceled"
                                           class="tagCompleted">Approved</div>
                                        <div *ngIf="ifOrderedTab && !data.isConfirmed && !data.isCompleted && !data.isCanceled"
                                           class="tagExp">Waiting approval</div>
                                        <div *ngIf="(ifOrderedTab || !data.isConfirmed) && !data.isCanceled && !data.isExpired && !data.isCompleted">
                                            <h5 class="itemtitle textOverflow">{{ data.payload.doc.get('title') }}</h5>
                                        </div>
                                    </div>
                                    <div *ngIf="!ifOrderedTab && !isHistoryTab && !ifRentedAwayTab"
                                        class="p-grid p-nogutter itemfooter">
                                        <div class="p-col-3 rating">
                                            <p class="location ratenum"><i class="fa fa-star starIcon"></i>
                                                {{ data.payload.doc.get('totalRating') | number: '1.0-0' }}</p>
                                        </div>
                                        <div class="p-col-8">
                                            <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                                                {{ data.payload.doc.get('location') }}</p>
                                        </div>
                                        <div class="p-col-1 detailPreview">
                                            <i (click)='copyMessage(data.payload.doc.get("id"))'
                                                class="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                    <div *ngIf="ifOrderedTab || isHistoryTab || ifRentedAwayTab" class="totalPrice">
                                        <p><b>Total Price :</b> ${{ data.totalPrice*1.25 }}</p>
                                    </div>
                                    <div *ngIf="isHistoryTab">
                                        <div class="centeralign">
                                            <p class="orderDate">{{ data.startDate.seconds*1000 | date }}</p>
                                            <p class="orderDate" *ngIf="data.endDate.seconds!=data.startDate.seconds"> -
                                                {{ data.endDate.seconds*1000 |
                                                date }}</p>
                                        </div>
                                      <button *ngIf="!data.renterReview" [style]="{ 'width':'100%' }" pButton
                                              label="Order Feedback" class="btn"
                                              (click)="openComlaintRenterDialog(data.orderID)"></button>
                                    </div>
                                    <div *ngIf="ifOrderedTab">
                                        <button *ngIf="data.isBefore && data.isConfirmed" [style]="{ 'width':'100%' }" pButton
                                            label="Picked Up!" class="btn"
                                            (click)="completeOrder(data.orderID, data.payload.doc.get('id'),data.supplierID)"></button>
                                        <button *ngIf="!data.isBefore" [style]="{ 'width':'100%' }" pButton
                                            label="Cancel Order!" class="btnRed"
                                            (click)="cancelOrder(data.orderID, data.startDate)"></button>
                                        <div class="centeralign">
                                            <p class="orderDate">{{ data.startDate.seconds*1000 | date }}</p>
                                            <p class="orderDate" *ngIf="data.endDate.seconds!=data.startDate.seconds"> -
                                                {{ data.endDate.seconds*1000 |
                                                date }}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="ifRentedAwayTab">
                                        <div *ngIf="!data.isCompleted && !data.isCanceled && !data.isConfirmed"
                                             class="p-grid myGrid">
                                            <div class="p-col-4">
                                                <button class="spaceDown btn btn-approve" [style]="{ 'width':'100%' }" pButton
                                                        label="Approve"
                                                        (click)="approveOrder(data.orderID, data.payload.doc.get('id'), data.renterID)"></button>
                                            </div>
                                            <div class="p-col-4">
                                                <button class="spaceDown btn btnRed" [style]="{ 'width':'100%' }" pButton
                                                        label="Deny"
                                                        (click)="denyOrder(data.orderID, data.payload.doc.get('id'), data.renterID)"></button>
                                            </div>
                                            <div class="p-col-4">
                                                <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                        label="View Profile"
                                                        (click)="viewProfile(data.renterID)"></button>
                                            </div>
                                        </div>
                                        <div *ngIf="data.isCompleted && !data.isCanceled && !data.supplierReview"
                                            class="p-grid myGrid">
                                            <div class="p-col-6">
                                                <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                    label="Order Feedback"
                                                    (click)="completeOrderRented(data.orderID)"></button>
                                            </div>
                                            <div class="p-col-6">
                                                <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                    label="View Profile" (click)="viewProfile(data.renterID)"></button>
                                            </div>
                                        </div>
                                        <div *ngIf="(!data.isCompleted && !data.isCanceled && data.isConfirmed) || (data.isCompleted && data.isCanceled) || (data.supplierReview)"
                                            class="p-grid myGrid">
                                            <div class="p-col-12">
                                                <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                    label="View Profile" (click)="viewProfile(data.renterID)"></button>
                                            </div>
                                        </div>
                                        <div class="centeralign">
                                            <p class="orderDate">{{ data.startDate.seconds*1000 | date }}</p>
                                            <p class="orderDate" *ngIf="data.endDate.seconds!=data.startDate.seconds"> -
                                                {{ data.endDate.seconds*1000 |
                                                date }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-data pTemplate="listItem">
                    <div class="caroitemList">
                        <div class="row">
                            <div class="col-sm-3">
                                <img *ngIf='data.payload.doc.get("photo")' class="itemimgList"
                                    (click)='detail(data.payload.doc.get("id"))'
                                    [src]='data.payload.doc.get("photo")[0]?data.payload.doc.get("photo")[0]:pic'
                                    alt="image">
                                <img *ngIf='!data.payload.doc.get("photo")' class="itemimgList" [src]='pic' alt="image"
                                    (click)='detail(data.payload.doc.get("id"))'>
                                <div *ngIf="ifRentedAwayTab">
                                    <div *ngIf="!data.isCompleted && !data.isCanceled && !data.isConfirmed"
                                         class="p-grid myGrid">
                                        <div class="p-col-4">
                                            <button class="spaceDown btn btn-approve" [style]="{ 'width':'100%' }" pButton
                                                    label="Approve"
                                                    (click)="approveOrder(data.orderID, data.payload.doc.get('id'), data.renterID)"></button>
                                        </div>
                                        <div class="p-col-4">
                                            <button class="spaceDown btn btnRed" [style]="{ 'width':'100%' }" pButton
                                                    label="Deny"
                                                    (click)="denyOrder(data.orderID, data.payload.doc.get('id'), data.renterID)"></button>
                                        </div>
                                        <div class="p-col-4">
                                            <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                    label="View Profile"
                                                    (click)="viewProfile(data.renterID)"></button>
                                        </div>
                                    </div>
                                    <div *ngIf="data.isCompleted && !data.isCanceled && !data.supplierReview"
                                        class="p-grid myGrid">
                                        <div class="p-col-6">
                                            <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                label="Order Feedback"
                                                (click)="completeOrderRented(data.orderID)"></button>
                                        </div>
                                        <div class="p-col-6">
                                            <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                label="View Profile" (click)="viewProfile(data.renterID)"></button>
                                        </div>
                                    </div>
                                    <div *ngIf="(!data.isCompleted && !data.isCanceled && data.isConfirmed) || (data.isCompleted && data.isCanceled) || (data.supplierReview)"
                                        class="p-grid myGrid">
                                        <div class="p-col-12">
                                            <button class="spaceDown btn" [style]="{ 'width':'100%' }" pButton
                                                label="View Profile" (click)="viewProfile(data.renterID)"></button>
                                        </div>
                                    </div>
                                    <div class="centeralign">
                                        <p class="orderDate">{{ data.startDate.seconds*1000 | date }}</p>
                                        <p class="orderDate" *ngIf="data.endDate.seconds!=data.startDate.seconds"> - {{
                                            data.endDate.seconds*1000 | date
                                            }}</p>
                                    </div>
                                </div>
                                <div *ngIf="ifOrderedTab">
                                    <button *ngIf="data.isBefore && data.isConfirmed" [style]="{ 'width':'100%' }" pButton
                                        label="Picked Up!" class="btn"
                                        (click)="completeOrder(data.orderID, data.payload.doc.get('id'),data.payload.doc.get('supplierID'))"></button>
                                    <button *ngIf="!data.isBefore" [style]="{ 'width':'100%' }" pButton
                                        label="Cancel Order!" class="btnRed"
                                        (click)="cancelOrder(data.orderID, data.startDate)"></button>
                                    <div class="centeralign">
                                        <p class="orderDate">{{ data.startDate.seconds*1000 | date }}</p>
                                        <p class="orderDate" *ngIf="data.endDate.seconds!=data.startDate.seconds"> - {{
                                            data.endDate.seconds*1000 | date
                                            }}</p>
                                    </div>
                                </div>
                                <div *ngIf="isHistoryTab">
                                    <div class="centeralign">
                                        <p class="orderDate">{{ data.startDate.seconds*1000 | date }}</p>
                                        <p class="orderDate" *ngIf="data.endDate.seconds!=data.startDate.seconds"> - {{
                                            data.endDate.seconds*1000 | date
                                            }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div *ngIf="!ifOrderedTab && !isHistoryTab && !ifRentedAwayTab">
                                    <div (click)='detail(data.payload.doc.get("id"))' style="cursor: pointer;">
                                        <h5 class="itemtitleList textOverflowList">{{ data.payload.doc.get('title') }}
                                        </h5>
                                    </div>
                                    <div class="rating">
                                        <p class="location"><i class="fa fa-star starIcon"></i>
                                            {{ data.payload.doc.get('totalRating') | number: '1.0-0' }}</p>
                                    </div>
                                    <div>
                                        <p class="locationList"><i class="fa fa-map-marker locationIcon"></i>
                                            {{ data.payload.doc.get('location') }}</p>
                                    </div>
                                    <div class="detailPreview">
                                        <i (click)='copyMessage(data.payload.doc.get("id"))'
                                            class="fa fa-share-alt"></i>
                                    </div>
                                </div>
                                <div *ngIf="ifOrderedTab || isHistoryTab || ifRentedAwayTab" class="totalPrice">
                                    <div (click)='detail(data.payload.doc.get("id"))' style="cursor: pointer;">
                                        <h5 class="itemtitleList textOverflow">{{ data.payload.doc.get('title') }}</h5>
                                    </div>
                                    <div *ngIf="isHistoryTab && data.isCanceled" class="tag">Cancelled</div>
                                    <p><b>Total Price :</b> ${{ data.totalPrice }}</p>
                                </div>
                                <div *ngIf="(isSuppliedTab || isSavedTab) && data.isExpired" class="tagExp">Expired
                                </div>
                                <div *ngIf="(ifRentedAwayTab || ifOrderedTab) && data.isConfirmed && !data.isCompleted && !data.isCanceled"
                                     class="tagCompleted">Approved</div>
                                <div *ngIf="ifRentedAwayTab && data.isCompleted && !data.isCanceled"
                                    class="tagCompleted">Completed</div>
                                <div *ngIf="ifRentedAwayTab && data.isCompleted && data.isCanceled" class="tag">
                                    Cancelled</div>
                                <div *ngIf="ifOrderedTab && !data.isConfirmed && !data.isCompleted && !data.isCanceled"
                                   class="tagExp">Waiting approval</div>
                            </div>
                            <div class="col-sm-4">
                                <p class="price">${{data.payload.doc.get('price1')}}</p>
                                <button pButton type="button" icon='fa fa-heart' class="p-button-rounded"
                                    [ngClass]='{ likebtnRed : ifLiked(data.payload.doc.get("id")), likebtn: !ifLiked(data.payload.doc.get("id")) }'
                                    (click)='savedbtn(data.payload.doc.get("id"))'></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
        <div *ngIf="noSaved && isSavedTab && !mainLoading">
            <br>
            <div class="flexedcenteralways"><i class="fa fa-exclamation nosaved"></i></div><br>
            <div class="flexedcenteralways"><button class="btn" pButton (click)="findListing()"
                    label="Find Listing"></button>
            </div>
            <br>
        </div>
    </div>
</div>
