import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, combineLatest, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import {UtilsCommon} from "../utils/utils";

@Injectable({
  providedIn: 'root',
})
export class FetchitemsService {
  subscriptions;

  constructor(private afs: AngularFirestore) {
    this.subscriptions = new Array<Subscription>();
  }

  //To fetch userData by Its uid
  async getUserByUID(uid) {
    //get Reference
    var docref = await this.afs
      .collection('Users')
      .ref.where('uid', '==', uid)
      .get();
    // to Get document id From reference
    var docid = docref.docs[0].id;
    //return the data of user matching document id
    return await this.afs.collection('Users').doc(docid).valueChanges();
  }

  fetchitembycategory(name, last = 0) {
    return this.afs
      .collection('ItemList', (ref) =>
        ref
          .orderBy('endDate')
          .where('endDate', '>=', new Date())
          .where('type', '==', name)
          .startAfter(last)
          .limit(24)
      )
      .snapshotChanges();
  }

  fetchcategories() {
    return this.afs.collection('Categories').valueChanges();
  }

  fetchcomment(id) {
    let myComments;
    let myuser;
    return this.afs
      .collection('Comments', (ref) =>
        ref.orderBy('commentDate', 'desc').where('itemID', '==', id)
      )
      .valueChanges()
      .pipe(
        map((comments) => {
          myComments = comments;
          for (let item of myComments) {
            this.subscriptions.push(
              this.afs
                .collection('Users', (ref) =>
                  ref.where('uid', '==', item.userID)
                )
                .valueChanges()
                .subscribe((users) => {
                  myuser = users[0];
                  item.renterName = myuser.firstName;
                  item.renterPhoto = myuser.photo;
                })
            );
            item.commentDate = item.commentDate.toDate();
          }
          return myComments;
        })
      );
  }

  fetchuserbyIdasync(id) {
    return this.afs
      .collection('Users', (ref) => ref.where('uid', '==', id))
      .valueChanges();
  }

  fetchOrderedArray(id) {
    return this.afs
      .collection('OrderItems', (ref) =>
        ref.where('renterID', '==', id).where('isCompleted', '==', false)
      )
      .valueChanges();
  }

  fetchOrderedHistoryArray(id) {
    return this.afs
      .collection('OrderItems', (ref) =>
        ref.where('renterID', '==', id).where('isCompleted', '==', true)
      )
      .valueChanges();
  }

  fetchRentedAwayArray(id) {
    return this.afs
      .collection('OrderItems', (ref) => ref.where('supplierID', '==', id))
      .valueChanges();
  }

  async fetchOrderedOfUser(id, last = 0) {
    let list: Observable<any[]>;
    let mydata;
    let orderIDs = [];
    let startDs = [];
    let endDs = [];
    let price = [];
    let supplierIDs = [];
    let confirms = [];

    return this.afs
      .collection('OrderItems', (ref) =>
        ref.where('renterID', '==', id).where('isCompleted', '==', false)
      )
      .valueChanges()
      .pipe(
        map((data) => {
          if (data.length) {
            mydata = data.splice(last, 24);
            if (mydata[0]) {
              for (let i = 0; i < mydata.length; i++) {
                supplierIDs.push(mydata[i].supplierID);
                orderIDs.push(mydata[i].orderID);
                startDs.push(mydata[i].startDate);
                endDs.push(mydata[i].endDate);
                price.push(
                  mydata[i].totalPrice
                );
                confirms.push(mydata[i].isConfirmed ?? false);
              }
              list = this.afs
                .collection('ItemList', (ref) =>
                  ref.where('id', '==', mydata[0].itemID)
                )
                .snapshotChanges();
              for (let i = 1; i < mydata.length; i++) {
                list = combineLatest([
                  list,
                  this.afs
                    .collection('ItemList', (ref) =>
                      ref.where('id', '==', mydata[i].itemID)
                    )
                    .snapshotChanges(),
                ]).pipe(map(([one, two]) => one.concat(two)));
              }
              return list.pipe(
                map((data) => {
                  for (let i = 0; i < data.length; i++) {
                    data[i].supplierID = supplierIDs[i];
                    data[i].orderID = orderIDs[i];
                    data[i].startDate = startDs[i];
                    data[i].endDate = endDs[i];
                    data[i].totalPrice = price[i];
                    if (
                      moment(new Date(startDs[i].seconds * 1000)).isBefore(
                        new Date(),
                        'day'
                      ) ||
                      moment(new Date(startDs[i].seconds * 1000)).isSame(
                        new Date(),
                        'day'
                      )
                    ) {
                      data[i].isBefore = true;
                    } else {
                      data[i].isBefore = false;
                    }
                    data[i].isConfirmed = confirms[i];
                  }
                  return data;
                })
              );
            }
          } else {
            return new Observable<Array<any>>();
          }
        })
      );
  }

  async fetchOrderedHistoryOfUser(id, last = 0) {
    let list: Observable<any[]>;
    let mydata;
    let orderIDs = [];
    let startDs = [];
    let endDs = [];
    let price = [];
    let cancels = [];
    let renterReviews = [];

    return this.afs
      .collection('OrderItems', (ref) =>
        ref.where('renterID', '==', id).where('isCompleted', '==', true)
      )
      .valueChanges()
      .pipe(
        map((data) => {
          mydata = data.splice(last, 24);
          if (mydata[0]) {
            for (let i = 0; i < mydata.length; i++) {
              orderIDs.push(mydata[i].orderID);
              startDs.push(mydata[i].startDate);
              endDs.push(mydata[i].endDate);
              price.push(mydata[i].totalPrice);
              cancels.push(mydata[i].isCanceled ? mydata[i].isCanceled : false);
              renterReviews.push(mydata[i].renterReview ? mydata[i].renterReview : false);
            }
            list = this.afs
              .collection('ItemList', (ref) =>
                ref.where('id', '==', mydata[0].itemID)
              )
              .snapshotChanges();
            for (let i = 1; i < mydata.length; i++) {
              list = combineLatest([
                list,
                this.afs
                  .collection('ItemList', (ref) =>
                    ref.where('id', '==', mydata[i].itemID)
                  )
                  .snapshotChanges(),
              ]).pipe(map(([one, two]) => one.concat(two)));
            }
          }
          if (list) {
            return list.pipe(
              map((data) => {
                for (let i = 0; i < data.length; i++) {
                  data[i].orderID = orderIDs[i];
                  data[i].startDate = startDs[i];
                  data[i].endDate = endDs[i];
                  data[i].totalPrice = price[i];
                  data[i].isCanceled = cancels[i];
                  data[i].renterReview = renterReviews[i];
                }
                return data;
              })
            );
          } else {
            return list;
          }
        })
      );
  }

  async fetchRentedAwayOfUser(id, last = 0) {
    let list: Observable<any[]>;
    let mydata;
    let orderIDs = [];
    let renterIDs = [];
    let startDs = [];
    let endDs = [];
    let price = [];
    let completes = [];
    let cancels = [];
    let reviews = [];
    let confirms = [];

    return this.afs
      .collection('OrderItems', (ref) => ref.where('supplierID', '==', id))
      .valueChanges()
      .pipe(
        map((data) => {
          mydata = data.splice(last, 24);
          if (mydata[0]) {
            for (let i = 0; i < mydata.length; i++) {
              orderIDs.push(mydata[i].orderID);
              renterIDs.push(mydata[i].renterID);
              startDs.push(mydata[i].startDate);
              endDs.push(mydata[i].endDate);
              price.push(mydata[i].totalPrice);
              reviews.push(
                mydata[i].supplierReview ? mydata[i].supplierReview : false
              );
              cancels.push(mydata[i].isCanceled ? mydata[i].isCanceled : false);
              confirms.push(mydata[i].isConfirmed ? mydata[i].isConfirmed : false);
              if (mydata[i].isCompleted) {
                completes.push(true);
              } else {
                let tempDate = mydata[i].endDate
                  ? mydata[i].endDate
                  : mydata[i].startDate;
                if (
                  moment(new Date()).isAfter(
                    moment(new Date(tempDate.seconds * 1000))
                  )
                ) {
                  completes.push(true);
                } else {
                  completes.push(false);
                }
              }
            }
            list = this.afs
              .collection('ItemList', (ref) =>
                ref.where('id', '==', mydata[0].itemID)
              )
              .snapshotChanges();
            for (let i = 1; i < mydata.length; i++) {
              list = combineLatest([
                list,
                this.afs
                  .collection('ItemList', (ref) =>
                    ref.where('id', '==', mydata[i].itemID)
                  )
                  .snapshotChanges(),
              ]).pipe(map(([one, two]) => one.concat(two)));
            }
          }
          if (list) {
            return list.pipe(
              map((data) => {
                for (let i = 0; i < data.length; i++) {
                  data[i].orderID = orderIDs[i];
                  data[i].renterID = renterIDs[i];
                  data[i].startDate = startDs[i];
                  data[i].endDate = endDs[i];
                  data[i].totalPrice = price[i];
                  data[i].isCompleted = completes[i];
                  data[i].isCanceled = cancels[i];
                  data[i].isConfirmed = confirms[i];
                  data[i].supplierReview = reviews[i];
                }
                return data;
              })
            );
          } else {
            return list;
          }
        })
      );
  }

  fetchAll(last = 0) {
    return this.afs
      .collection('ItemList', (ref) =>
        ref
          .orderBy('endDate')
          .where('endDate', '>=', new Date())
          .startAfter(last)
          .limit(24)
      )
      .snapshotChanges();
  }

  fetchbyitemid(id) {
    let myItem;
    return this.afs
      .collection('ItemList', (ref) => ref.where('id', '==', id))
      .valueChanges()
      .pipe(
        map((items) => {
          myItem = items;
          myItem[0].startDate = myItem[0].startDate ? myItem[0].startDate : '';
          myItem[0].endDate = myItem[0].endDate ? myItem[0].endDate : '';
          myItem[0].forMen = myItem[0].forMen ? myItem[0].forMen : false;
          myItem[0].forWomen = myItem[0].forWomen ? myItem[0].forWomen : false;
          myItem[0].forChildren = myItem[0].forChildren
            ? myItem[0].forChildren
            : false;
          return myItem;
        })
      );
  }

  fetchBySearchKeyword(title, loc, cat) {
    let myItems = [];
    if (cat) {
      if (loc) {
        return this.afs
          .collection('ItemList', (ref) =>
            ref
              .orderBy('titleLower')
              .where('type', '==', cat)
          )
          .snapshotChanges()
          .pipe(
            map((items) => {
              for (let item of items) {
                if (
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isBefore(moment(new Date()), 'day') ||
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isSame(moment(new Date()), 'day')
                ) {
                  if (
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isAfter(moment(new Date()), 'day') ||
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isSame(moment(new Date()), 'day')
                  ) {
                    let locationWithoutZipCode = item.payload.doc.get('locationLower') as string;
                    locationWithoutZipCode = UtilsCommon.removeZipCode(locationWithoutZipCode);

                    let titleLower = item.payload.doc.get('titleLower') as string;
                    let detailLower = (item.payload.doc.get('details') as string).toLowerCase();
                    if (locationWithoutZipCode && locationWithoutZipCode.includes(loc) && (titleLower && titleLower.includes(title) || detailLower && detailLower.includes(title))) {
                      myItems.push(item);
                    }
                  }
                }
              }
              return myItems;
            })
          );
      } else {
        return this.afs
          .collection('ItemList', (ref) =>
            ref
              .orderBy('titleLower')
              .where('type', '==', cat)
          )
          .snapshotChanges()
          .pipe(
            map((items) => {
              for (let item of items) {
                if (
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isBefore(moment(new Date()), 'day') ||
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isSame(moment(new Date()), 'day')
                ) {
                  if (
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isAfter(moment(new Date()), 'day') ||
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isSame(moment(new Date()), 'day')
                  ) {
                    let titleLower = item.payload.doc.get('titleLower') as string;
                    let detailLower = (item.payload.doc.get('details') as string).toLowerCase();
                    if (titleLower && titleLower.includes(title) || detailLower && detailLower.includes(title))
                      myItems.push(item);
                  }
                }
              }
              return myItems;
            })
          );
      }
    } else {
      if (loc) {
        return this.afs
          .collection('ItemList', (ref) =>
            ref
              .orderBy('titleLower')
          )
          .snapshotChanges()
          .pipe(
            map((items) => {
              for (let item of items) {
                if (
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isBefore(moment(new Date()), 'day') ||
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isSame(moment(new Date()), 'day')
                ) {
                  if (
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isAfter(moment(new Date()), 'day') ||
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isSame(moment(new Date()), 'day')
                  ) {
                    let locationWithoutZipCode = item.payload.doc.get('locationLower') as string;
                    locationWithoutZipCode = UtilsCommon.removeZipCode(locationWithoutZipCode);

                    let titleLower = item.payload.doc.get('titleLower') as string;
                    let detailLower = (item.payload.doc.get('details') as string).toLowerCase();
                    if (locationWithoutZipCode && locationWithoutZipCode.includes(loc) && (titleLower && titleLower.includes(title) || detailLower && detailLower.includes(title))) {
                      myItems.push(item);
                    }
                  }
                }
              }
              return myItems;
            })
          );
      } else {
        return this.afs
          .collection('ItemList', (ref) =>
            ref
              .orderBy('titleLower')
          )
          .snapshotChanges()
          .pipe(
            map((items) => {
              for (let item of items) {
                if (
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isBefore(moment(new Date()), 'day') ||
                  moment(
                    new Date(item.payload.doc.get('startDate').seconds * 1000)
                  ).isSame(moment(new Date()), 'day')
                ) {
                  if (
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isAfter(moment(new Date()), 'day') ||
                    moment(
                      new Date(item.payload.doc.get('endDate').seconds * 1000)
                    ).isSame(moment(new Date()), 'day')
                  ) {
                    let titleLower = item.payload.doc.get('titleLower') as string;
                    let detailLower = (item.payload.doc.get('details') as string).toLowerCase();
                    if (titleLower && titleLower.includes(title) || detailLower && detailLower.includes(title))
                      myItems.push(item);
                  }
                }
              }
              return myItems;
            })
          );
      }
    }
  }

  async fetchSaved(savedarr) {
    let list: Observable<any[]>;
    if (savedarr.length > 0) {
      list = this.afs
        .collection('ItemList', (ref) => ref.where('id', '==', savedarr[0]))
        .snapshotChanges();
      for (let i = 1; i < savedarr.length; i++) {
        list = combineLatest([
          list,
          this.afs
            .collection('ItemList', (ref) => ref.where('id', '==', savedarr[i]))
            .snapshotChanges(),
        ]).pipe(map(([one, two]) => one.concat(two)));
      }
      return list.pipe(
        map((list) => {
          for (let item of list) {
            item.endDate = item.payload.doc.get('endDate')
              ? item.payload.doc.get('endDate')
              : '';
            if (item.endDate != '') {
              if (
                moment(new Date()).isAfter(
                  moment(new Date(item.endDate.seconds * 1000)),
                  'day'
                )
              ) {
                item.isExpired = true;
              } else {
                item.isExpired = false;
              }
            } else {
              item.isExpired = false;
            }
          }
          return list;
        })
      );
    } else {
      return list;
    }
  }

  fetchSupplied(uid, last = 0) {
    let myData;
    return this.afs
      .collection('ItemList', (ref) =>
        ref
          .orderBy('price1')
          .where('userID', '==', uid)
          .startAfter(last)
          .limit(24)
      )
      .snapshotChanges()
      .pipe(
        map((list) => {
          myData = list;
          for (let item of myData) {
            item.endDate = item.payload.doc.get('endDate')
              ? item.payload.doc.get('endDate')
              : '';
            if (item.endDate != '') {
              if (
                moment(new Date()).isAfter(
                  moment(new Date(item.endDate.seconds * 1000)),
                  'day'
                )
              ) {
                item.isExpired = true;
              } else {
                item.isExpired = false;
              }
            } else {
              item.isExpired = false;
            }
          }
          return list;
        })
      );
  }

  fetchRentedAway(uid, last = 0) {
    return this.afs
      .collection('OrderItems', (ref) =>
        ref
          .orderBy('orderID')
          .where('supplierID', '==', uid)
          .startAfter(last)
          .limit(24)
      )
      .snapshotChanges();
  }

  fetchSavedArray(uid) {
    return this.afs
      .collection('SavedItems', (ref) => ref.where('userID', '==', uid))
      .valueChanges();
  }

  updateSavedArray(id, arr) {
    this.afs.collection('SavedItems').doc(id).update({saved: arr});
  }

  fetchOrderedDates(itemID) {
    return this.afs
      .collection('OrderItems', (ref) =>
        ref.where('itemID', '==', itemID).where('isCanceled', '==', false)
      )
      .valueChanges();
  }

  fetchOrderDetails(id) {
    return this.afs
      .collection('OrderItems', (ref) => ref.where('orderID', '==', id))
      .valueChanges();
  }

  fetchLongLat(arr, filter = 'false') {
    let myArr = [];
    for (let item of arr) {
      let myItem;
      if (filter == 'false') {
        myItem = item.payload.doc.data();
        console.log("item:", myItem);
      } else {
        myItem = item;
      }
      myArr.push({
        label: myItem.title,
        itemId: myItem.id,
        lat: myItem.latitude,
        lng: myItem.longitude,
      });
    }
    return myArr;
  }

  getRandomInRange(from, to, fixed) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  }

  fetchFaqs() {
    return this.afs.collection('Help').doc('faqs').valueChanges();
  }

  fetchHelpVideo() {
    return this.afs.collection('Help').doc('urls').valueChanges();
  }

  isNewSupplier(id) {
    let myUser;
    return this.afs
      .collection('ItemList', (ref) => ref.where('userID', '==', id).limit(10))
      .get()
      .pipe(
        map((items) => {
          return this.afs
            .collection('Users', (ref) => ref.where('uid', '==', id))
            .valueChanges()
            .pipe(
              map((users) => {
                myUser = users[0];
                let stripe_user_id = myUser.stripe_user_id
                  ? myUser.stripe_user_id
                  : '';
                if (stripe_user_id != '') {
                  return false;
                } else {
                  return true;
                }
              })
            );
        })
      );
  }

  updatePaypalEmail(email, id) {
    return this.afs
      .collection('Users', (ref) => ref.where('uid', '==', id))
      .get()
      .pipe(
        map((users) => {
          let id = users.docs[0].id;
          return this.afs
            .collection('Users')
            .doc(id)
            .update({paypalEmail: email});
        })
      );
  }

  updateStripeUserId(stripe_user_id, id) {
    return this.afs
      .collection('Users', (ref) => ref.where('uid', '==', id))
      .get()
      .pipe(
        map((users) => {
          let id = users.docs[0].id;
          return this.afs
            .collection('Users')
            .doc(id)
            .update({stripe_user_id: stripe_user_id});
        })
      );
  }

  getEnvironmentVariables() {
    return this.afs.collection('Environment').doc('variables').valueChanges();
  }

  filtersSerch(obj) {
    let query;
    query = firebase.firestore().collection('ItemList');
    query = query.where('price1', '>=', obj.rangeStart);
    query = query.where('price1', '<=', obj.rangeEnd);
    query = query.where('forMen', '==', obj.forMen);
    query = query.where('forWomen', '==', obj.forWomen);
    query = query.where('forChildren', '==', obj.forChildren);
    // if (obj.location != '') {
    //   query = query.where('locationLower', '==', obj.location.toLowerCase());
    // }
    if (obj.type != '') {
      query = query.where('type', '==', obj.type);
    }
    return query
      .orderBy('price1')
      .orderBy('id')
      .get()
      .then((data) => {
        let result = [];
        for (let doc of data.docs) {
          if (obj.rating != 0 || obj.startDate != '' || obj.endDate != '') {
            if (obj.rating != 0) {
              if (
                doc.data().totalRating >= obj.rating &&
                doc.data().totalRating < obj.rating + 1
              ) {
                result.push(doc.data());
              }
            }
            if (obj.startDate != '') {
              if (
                moment(new Date(doc.data().startDate.seconds * 1000)).isSame(
                  moment(new Date(obj.startDate)),
                  'day'
                ) ||
                moment(new Date(doc.data().startDate.seconds * 1000)).isAfter(
                  moment(new Date(obj.startDate)),
                  'day'
                )
              ) {
                result.push(doc.data());
              }
            }
            if (obj.endDate != '') {
              if (
                moment(new Date(doc.data().endDate.seconds * 1000)).isSame(
                  moment(new Date(obj.endDate)),
                  'day'
                ) ||
                moment(new Date(doc.data().endDate.seconds * 1000)).isBefore(
                  moment(new Date(obj.endDate)),
                  'day'
                )
              ) {
                result.push(doc.data());
              }
            }
          } else {
            result.push(doc.data());
          }
        }

        if (obj.location != '') {
          let locationWithoutZipCode = UtilsCommon.removeZipCode(obj.location).toLowerCase();
          result = result.filter(item =>
            UtilsCommon.removeZipCode(item.locationLower).includes(locationWithoutZipCode)
          );
        }

        return result;
      });
  }

  fetchWantedTrending(lat, lng, result) {
    let myItems = [];

    return this.afs
      .collection('ItemList', (ref) =>
        ref.orderBy('endDate').where('endDate', '>=', new Date())
      )
      .get()
      .pipe(
        map((items) => {
          let myTrnding = [];
          items.docs.forEach((item) => {
            if (
              moment(new Date(item.data().startDate.seconds * 1000)).isBefore(
                moment(new Date())
              ) ||
              moment(new Date(item.data().startDate.seconds * 1000)).isSame(
                moment(new Date())
              )
            ) {
              let myItem = item.data();
              if (lat != 0 && lng != 0) {
                var radlat1 = (Math.PI * lat) / 180;
                var radlat2 = (Math.PI * myItem.latitude) / 180;
                var theta = lng - myItem.longitude;
                var radtheta = (Math.PI * theta) / 180;
                var dist =
                  Math.sin(radlat1) * Math.sin(radlat2) +
                  Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                dist = Math.acos(dist);
                dist = (dist * 180) / Math.PI;
                dist = dist * 60 * 1.1515;
                dist = dist * 1.609344;
                myItem.distance = dist;
              }
              myItems.push(myItem);
            }
          });
          myItems.filter((item) => {
            var pushIndex = result.findIndex((obj) => obj.type == item.type);
            if (pushIndex > -1) {
              result[pushIndex].items.push(item);
            }
          });

          result.forEach((cat) => {
            if (lat != 0 && lng != 0) {
              cat.items.sort((a, b) => {
                if (
                  a.viewCount > 0 &&
                  b.viewCount > 0 &&
                  a.ordersCount > 0 &&
                  b.ordersCount > 0
                ) {
                  return (
                    b.viewCount / b.distance -
                    a.viewCount / a.distance +
                    (b.ordersCount / b.distance - a.ordersCount / a.distance)
                  );
                } else {
                  return a.distance <= b.distance ? -1 : 1;
                }
              });
              if (cat.items.length) {
                myTrnding.push(cat.items[0]);
              }
            } else {
              cat.items.sort((a, b) => {
                return (
                  b.viewCount - a.viewCount + b.ordersCount - a.ordersCount
                );
              });
              if (cat.items.length) {
                myTrnding.push(cat.items[0]);
              }
            }
          });

          return {
            Wanted: myItems
              .sort(function (a, b) {
                return b.ordersCount - a.ordersCount;
              })
              .slice(0, 20),
            Trending: myTrnding,
          };
        })
      );
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
