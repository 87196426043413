<div *ngIf="!loading" class="container-fluid">
    <div class="p-grid p-nogutter">
        <div class="p-col-8 p-md-4 p-lg-3 flexed">
            <div>
                <img class="logo"
            src="../../../assets/images/logo.png"
            alt="logo" (click)="imgLogo()">
            <div class="saag">
                <p>Share Adventure</p>
                <p>and Gear</p>
            </div>
            </div>
        </div>
        <div class="p-col-0 p-md-0 p-lg-5 flexedleft">
            <p-menubar class="hide" [model]="menuitems"></p-menubar>
        </div>
        <div class="p-col-4 p-md-8 p-lg-4 flexedright">
            <div class="lastelements">
                <div class="flexedright" *ngIf="!islogin">
                    <button pButton routerLink='/login' type="button" label="Login"></button>
                    <button (click)="warning()" class="listItem" pButton routerLink='/login' type="button" label="Become a Supplier"></button>
                </div>
                <div *ngIf="islogin">
                    <div *ngFor='let u of user | async'>
                        <p-megaMenu [ngClass]='{ notify: !ifSeen, notnotify: ifSeen }'
                        [model]="items">
                            <img (click)="dpClick()" class="dpimg" [src]='u.photo?u.photo:dp' alt="pic">
                        </p-megaMenu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>