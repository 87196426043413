import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginsignupService } from './loginsignup.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  myID;
  private id = new BehaviorSubject('');
  private itemid = new BehaviorSubject('');
  sharedID = this.id.asObservable();
  sharedItemID = this.itemid.asObservable();
  subscriptions;

  constructor(
    private afs: AngularFirestore,
    public _user: LoginsignupService,
    public router: Router
  ) {
    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      _user.isSignin().subscribe((user) => {
        if (user) {
          this.myID = user.uid;
        }
      })
    );
  }

  nextMessage(id: string, itemid: string) {
    this.id.next(id);
    this.itemid.next(itemid);
  }

  ifUnseen(userID) {
    let myChats = [];
    let seen: boolean;
    let list1 = this.afs
      .collection('Chats', (ref) => ref.where('renterID', '==', userID))
      .snapshotChanges();

    let list2 = this.afs
      .collection('Chats', (ref) => ref.where('supplierID', '==', userID))
      .snapshotChanges();

    let list = combineLatest([list1, list2]).pipe(
      map(([one, two]) => one.concat(two))
    );

    return list.pipe(
      map((chats) => {
        seen = true;
        myChats = chats;
        for (let chat of myChats) {
          let arr = [];
          arr = chat.payload.doc.get('messages');
          for (let mess of arr) {
            if (mess.seen == false) {
              if (mess.user._id != userID) {
                seen = false;
              }
            }
          }
        }
        return seen;
      })
    );
  }

  async fetchChats(userID) {
    let myChats = [];
    let myUser;
    let myItem;
    let list1 = this.afs
      .collection('Chats', (ref) => ref.where('renterID', '==', userID))
      .snapshotChanges();

    let list2 = this.afs
      .collection('Chats', (ref) => ref.where('supplierID', '==', userID))
      .snapshotChanges();

    let list = combineLatest([list1, list2]).pipe(
      map(([one, two]) => one.concat(two))
    );

    return await list.pipe(
      map((chats) => {
        myChats = chats;
        for (let chat of myChats) {
          chat.seen = true;
          let arr = [];
          arr = chat.payload.doc.get('messages');
          for (let mess of arr) {
            if (mess.seen == false) {
              if (mess.user._id != userID) {
                chat.seen = false;
              }
            }
          }
          let uidTemp;
          if (this.myID == chat.payload.doc.get('supplierID')) {
            uidTemp = chat.payload.doc.get('renterID');
          } else {
            uidTemp = chat.payload.doc.get('supplierID');
          }
          this.subscriptions.push(
            this.afs
              .collection('Users', (ref) => ref.where('uid', '==', uidTemp))
              .valueChanges()
              .subscribe((user) => {
                myUser = user[0];
                let lastDate = chat.payload.doc.data().messages;
                chat.lastDate = lastDate[lastDate.length - 1].createdAt.seconds;
                chat.supplier = chat.payload.doc.get('supplierID');
                chat.renter = chat.payload.doc.get('renterID');
                chat.chatID = chat.payload.doc.get('ChatID');
                chat.displayName = myUser.firstName;
                chat.avatar = myUser.photo
                  ? myUser.photo
                  : '../../../assets/images/dp.png';
                chat.userID = myUser.uid;
                this.subscriptions.push(
                  this.afs
                    .collection('ItemList', (ref) =>
                      ref.where('id', '==', chat.payload.doc.get('itemID'))
                    )
                    .get()
                    .subscribe((items) => {
                      myItem = items.docs[0].data();
                      chat.itemName = myItem.title;
                      chat.itemID = myItem.id;
                    })
                );
              })
          );
        }
        return myChats;
      })
    );
  }

  getMessages(supplier, renter, item, uid) {
    let myChat;
    return this.afs
      .collection('Chats', (ref) =>
        ref
          .where('supplierID', '==', supplier)
          .where('renterID', '==', renter)
          .where('itemID', '==', item)
      )
      .snapshotChanges()
      .pipe(
        map((chats) => {
          if (chats.length) {
            myChat = chats[0];
            let arr: Array<any> = myChat.payload.doc.get('messages');
            for (let mess of arr) {
              if (mess.user._id != uid) {
                mess.seen = true;
              }
            }
            this.afs
              .collection('Chats')
              .doc(myChat.payload.doc.id)
              .update({ messages: arr });
            return { messages: arr, chatID: myChat.payload.doc.id };
          } else {
            return { messages: new Array<any>(), chatID: '' };
          }
        })
      );
  }

  newMessage(msg) {
    let myMessages = [];
    return this.afs
      .collection('Chats', (ref) =>
        ref
          .where('supplierID', '==', msg.supplier)
          .where('renterID', '==', msg.renter)
          .where('itemID', '==', msg.itemID)
      )
      .get()
      .pipe(
        map((chats) => {
          if (chats.docs.length) {
            return this.afs
              .collection('Chats')
              .doc(chats.docs[0].id)
              .update({
                messages: firebase.firestore.FieldValue.arrayUnion({
                  _id: new Date().valueOf(),
                  createdAt: msg.createdAt,
                  seen: false,
                  user: { _id: msg.from },
                  text: msg.text,
                }),
              });
          } else {
            myMessages.push({
              _id: new Date().valueOf(),
              createdAt: msg.createdAt,
              seen: false,
              user: { _id: msg.from },
              text: msg.text,
            });
            return this.afs
              .collection('Chats')
              .add({
                itemID: msg.itemID,
                messages: myMessages,
                renterID: msg.renter,
                supplierID: msg.supplier,
              })
              .then((docRef) => {
                docRef.update({ ChatID: docRef.id });
              });
          }
        })
      );
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
