<div class="overlay" *ngIf="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="header flexed">
  <h4>Enter Credentials</h4>
    <i class="fa fa-close" (click)="close()"></i>
</div>
<div class="form">
  <!-- TDF FORM -->
  <form #userForm="ngForm">
    <!-- Email -->
    <div class="form-group">
      <label>Email:</label>
      <input #email="ngModel" pInputText required type="email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
        [class.is-invalid]="email.invalid && email.touched" class="form-control" name="email" [(ngModel)]="userEmail" >
      <div *ngIf="email.errors && (email.invalid && email.touched)">
        <small class="text-danger" *ngIf="email.errors.required ">Email is required</small>
        <small class="text-danger" *ngIf="email.errors.pattern">Enter correct email </small>
      </div>
    </div>
    <div class="form-group" *ngIf="errmsg"><small style="color: red;">{{errmsg}}</small></div>
    <div class="flexedend">
      <button pButton type="button" class="p-button-rounded" label="Send Email"
      [disabled]="userForm.invalid || ifSent" (click)=sendEmail()></button>
    </div>
  </form>
</div>
