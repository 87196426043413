import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FetchitemsService } from '../../services/fetchitems.service';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { environment } from '../../../environments/environment';
import { MapsAPILoader } from '@agm/core';
import { UpdateItemService } from 'src/app/services/update-item.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public trending;
  wanted;
  public categories: Observable<any[]>;
  categoriesNames;
  loading: boolean = true;
  responsiveOptionswanted;
  pic;
  searchkey: string = '';
  isLogin: boolean = false;
  catarr;
  savedItems: Array<any> = [''];
  savedItemstemp;
  savedID;
  searchTitle: string = '';
  searchLocation: string = '';
  searchCategory;
  searchCategoryName: string = '';
  divStyle: string = '';
  subscriptions;
  autofill: boolean = false;
  emailSubs: string = '';
  lat;
  lng;
  userID;
  currentLat = 0;
  currentLng = 0;
  @ViewChild('loc') searchElementRef: ElementRef;
  @ViewChild('title') titleElementRef: ElementRef;

  constructor(
    private router: Router,
    private _fetch: FetchitemsService,
    public _login: LoginsignupService,
    public _toast: SearchKeywordService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _update: UpdateItemService
  ) {
    this.subscriptions = new Array<Subscription>();
    navigator.geolocation.getCurrentPosition((position) => {
      this.currentLat = position.coords.latitude;
      this.currentLng = position.coords.longitude;
    });

    this.categoriesNames = new Array();
    this.catarr = new Array();
    this.categories = _fetch.fetchcategories();
    this.subscriptions.push(
      this.categories.subscribe((data) => {
        this.catarr = data;
        var result = [];
        data.forEach((Category,index)=>{
          result.push({type:Category.categoryName,items:[]});
          if(data.length == index+1){
            this.subscriptions.push(
              _login.isSignin().subscribe((uid) => {
                _fetch
                  .fetchWantedTrending(this.currentLat, this.currentLng,result)
                  .subscribe((data) => {
                    this.trending = data.Trending;
                    this.wanted = data.Wanted;
                    this.loading = false;
                  });
                if (uid) {
                  this.userID = uid.uid;
                  this.isLogin = true;
                  var temp = _fetch.fetchSavedArray(uid.uid);
                  this.subscriptions.push(
                    temp.subscribe((data) => {
                      if (data[0]) {
                        this.savedItemstemp = data[0];
                        this.savedItems = this.savedItemstemp.saved;
                        this.savedID = this.savedItemstemp.savedID;
                      }
                    })
                  );
                }
              })
            );
          }
        })
      })
    );

    this.pic = '../../../assets/images/noImage.jpg';

    this.responsiveOptionswanted = [
      {
        breakpoint: '960px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '668px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '540px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {}

  detail(id) {
    if (this.isLogin) {
      this._update.increaseViewCount(id, this.userID);
    } else {
      this._update.increaseViewCount(id);
    }
    this.router.navigate(['/itemdetail', id]);
  }

  viewAll() {
    this.router.navigate(['/items', 'allListing']);
  }

  categoryclick(name) {
    this.router.navigate(['/items', name]);
  }

  savedbtn(id) {
    if (this.isLogin) {
      if (this.savedItems.indexOf(id) >= 0) {
        this.savedItems.splice(this.savedItems.indexOf(id), 1);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      } else {
        this.savedItems.push(id);
        this._fetch.updateSavedArray(this.savedID, this.savedItems);
      }
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  ifLiked(id) {
    if (this.savedItems.indexOf(id) >= 0) {
      return true;
    } else return false;
  }

  search() {
    this.searchCategoryName = this.searchCategory
      ? this.searchCategory.categoryName
      : '';
    if (
      this.searchTitle != '' ||
      this.searchLocation != '' ||
      this.searchCategoryName != ''
    ) {
      let title = this.searchTitle ? this.searchTitle : 'null';
      let loc = this.searchLocation ? this.searchLocation : 'null';
      let cat = this.searchCategoryName ? this.searchCategoryName : 'null';
      this.router.navigate(['/items', 'search', title, loc, cat]);
    } else {
      this.titleElementRef.nativeElement.focus();
    }
  }

  searchwithLoc() {
    if (
      (this.searchLocation != '' &&
        this.lat != undefined &&
        this.lng != undefined) ||
      this.searchLocation == ''
    ) {
      this.searchCategoryName = this.searchCategory
        ? this.searchCategory.categoryName
        : '';
      if (
        this.searchTitle != '' ||
        this.searchLocation != '' ||
        this.searchCategoryName != ''
      ) {
        let title = this.searchTitle ? this.searchTitle : 'null';
        let loc = this.searchLocation ? this.searchLocation : 'null';
        let cat = this.searchCategoryName ? this.searchCategoryName : 'null';
        this.router.navigate(['/items', 'search', title, loc, cat]);
      } else {
        this.titleElementRef.nativeElement.focus();
      }
    }
  }

  onchange() {
    if (this.lng != undefined) {
      this.lng = undefined;
    }
    if (this.lat != undefined) {
      this.lat = undefined;
    }
  }

  focused() {
    this.divStyle = 'border-bottom-style: solid;border-bottom-color: #ee6956;';
    if (!this.autofill) {
      this.autofill = true;
      var options = {
        types: ['(regions)'],
      };
      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          options
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.formatted_address) {
              this.searchLocation = place.formatted_address;
            }
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
          });
        });
      });
    }
  }

  focusout() {
    this.divStyle = '';
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + '/itemdetail/' + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toast.nextMessage('Link Copied to Clipboard', 'success');
  }

  becomeSupplier() {
    if (this.isLogin) {
      this.router.navigateByUrl('/additem');
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  findgear() {
    this.router.navigateByUrl('/items/allListing');
  }

  emailEnter() {
    if (this.emailSubs != '' || this.emailSubs != undefined) {
      if (this.ValidateEmail(this.emailSubs)) {
        this.router.navigate(['/feedback', this.emailSubs]);
      } else {
        this._toast.nextMessage('Enter correct Email to Continue!', 'error');
      }
    } else {
      this._toast.nextMessage('Enter Email to Continue!', 'error');
    }
  }

  ValidateEmail(input) {
    var mailformat = /^[[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]/;
    if (input.match(mailformat)) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
