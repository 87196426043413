import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { LoginsignupService } from '../../services/loginsignup.service';
import { FetchitemsService } from '../../services/fetchitems.service';
import { UpdateItemService } from '../../services/update-item.service';
import { Router } from '@angular/router';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { DialogService } from 'primeng/dynamicdialog';
import { NewSupplierComponent } from 'src/app/shared/new-supplier/new-supplier.component';

@Component({
  selector: 'app-additem',
  templateUrl: './additem.component.html',
  styleUrls: ['./additem.component.scss'],
  providers: [DialogService],
})
export class AdditemComponent implements OnInit {
  @ViewChild('search') searchElementRef: ElementRef;
  //object to hold user data
  userDetail;
  //object to hold user data
  itemObject;
  addloading = true;
  types;
  files = [];
  minStartDate = new Date();
  minEndDate = new Date();
  // to hold the uploaded images files
  uploadedImages;
  isLogin: boolean = false;
  filesLength: number = 0;
  isMoreFiles: boolean = false;
  subscriptions;
  latitude: number;
  longitude: number;
  itemEditID: string = '';
  itemEdit;
  previousPhotos = [];
  uid;
  newSupplier: boolean = false;
  fileLimit: number = 5;
  removedPics = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _login: LoginsignupService,
    private _fetchData: FetchitemsService,
    private _updateItem: UpdateItemService,
    private route: Router,
    public _toast: SearchKeywordService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private dialodeServies: DialogService
  ) {
    this.subscriptions = new Array<Subscription>();
    //Servies Call to fetch user Data
    this.subscriptions.push(
      this._login.isSignin().subscribe((data) => {
        if (data) {
          this.uid = data.uid;
          this.isLogin = true;
          this._fetchData.getUserByUID(data.uid).then((data) => {
            this.subscriptions.push(
              data.subscribe((data) => {
                this.userDetail = data;
              })
            );
          });
          //Servies call to fetch catagories
          this.subscriptions.push(
            this._fetchData.fetchcategories().subscribe((data) => {
              this.types = data;
              this.subscriptions.push(
                _toast.sharedItemEdit.subscribe((item) => {
                  if (item != '') {
                    this.itemEdit = JSON.parse(item);
                    this.types.splice(
                      this.types
                        .map((e) => {
                          return e.categoryName;
                        })
                        .indexOf(this.itemEdit.type),
                      1
                    );
                    this.types.unshift({ categoryName: this.itemEdit.type });
                    this.itemEditID = this.itemEdit.id;
                    this.informationHolder
                      .get('type')
                      .setValue({ categoryName: this.itemEdit.type });
                    this.informationHolder
                      .get('title')
                      .setValue(this.itemEdit.title);
                    this.informationHolder
                      .get('location')
                      .setValue(this.itemEdit.location);
                    this.informationHolder
                      .get('price')
                      .setValue(this.itemEdit.price1);
                    this.informationHolder
                      .get('description')
                      .setValue(this.itemEdit.details);
                    this.informationHolder
                      .get('startDate')
                      .setValue(
                        this.itemEdit.startDate
                          ? new Date(this.itemEdit.startDate.seconds * 1000)
                          : ''
                      );
                    this.informationHolder
                      .get('endDate')
                      .setValue(
                        this.itemEdit.endDate
                          ? new Date(this.itemEdit.endDate.seconds * 1000)
                          : ''
                      );
                    this.latitude = this.itemEdit.latitude;
                    this.longitude = this.itemEdit.longitude;
                    this.previousPhotos = this.itemEdit.photo;
                    this.filesLength = this.previousPhotos.length;
                    this.fileLimit -= this.filesLength;
                    let cat = [];
                    if (this.itemEdit.forMen) {
                      cat.push('men');
                    }
                    if (this.itemEdit.forWomen) {
                      cat.push('women');
                    }
                    if (this.itemEdit.forChildren) {
                      cat.push('children');
                    }
                    this.informationHolder.get('category').setValue(cat);
                    if (this.itemEdit.priceResT) {
                      this.informationHolder.get('nextdayPrice').setValue(true);
                      this.differentPrice.push(
                        this._formBuilder.control('', [
                          Validators.required,
                          Validators.min(0),
                          Validators.max(999999),
                        ])
                      );
                      this.informationHolder
                        .get('differentPrice')
                        .setValue([this.itemEdit.priceResT]);
                    }
                    _toast.nextItemEdit('');
                    this.addloading = false;
                  } else {
                    this.subscriptions.push(
                      _fetchData.isNewSupplier(this.uid).subscribe((data) => {
                        this.subscriptions.push(
                          data.subscribe((isNew) => {
                            if (isNew) {
                              this.newSupplier = true;
                              this.dialodeServies.open(NewSupplierComponent, {
                                contentStyle: {
                                  height: 'fit-content',
                                  overflow: 'auto',
                                },
                                baseZIndex: 10000,
                                showHeader: false,
                                closable: false,
                                data: { id: this.uid },
                              });
                            } else {
                              this.newSupplier = false;
                            }
                          })
                        );
                      })
                    );
                    this.addloading = false;
                  }
                })
              );
            })
          );
        }
      })
    );
  }

  ngOnInit(): void {
    var options = {
      types: ['(regions)'],
    };
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        options
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.formatted_address) {
            this.informationHolder
              .get('location')
              .setValue(place.formatted_address);
          }
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });
  }

  removeImage(url) {
    this.removedPics.push(url);
    this.previousPhotos.splice(this.previousPhotos.indexOf(url), 1);
    this.filesLength -= 1;
    if (this.fileLimit < 5) {
      this.fileLimit += 1;
    }
    if (this.filesLength > 5) {
      this.isMoreFiles = true;
    } else {
      this.isMoreFiles = false;
    }
  }

  onchange() {
    if (this.longitude != undefined) {
      this.longitude = undefined;
    }
    if (this.latitude != undefined) {
      this.latitude = undefined;
    }
  }

  //from values and validators
  informationHolder = this._formBuilder.group({
    title: ['', [Validators.required]],
    location: ['', Validators.required],
    type: ['', Validators.required],
    category: [['men', 'women', 'children'], Validators.required],
    price: [
      '',
      [Validators.required, Validators.min(0), Validators.max(999999)],
    ],
    description: ['', [Validators.required, Validators.maxLength(1000)]],
    differentPrice: this._formBuilder.array([]),
    nextdayPrice: [false],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  });

  //Picture Uploader
  picUploader(event) {
    this.uploadedImages = event.currentFiles;
    this.filesLength = event.currentFiles.length + this.previousPhotos.length;
    if (this.filesLength > 5) {
      this.isMoreFiles = true;
    } else {
      this.isMoreFiles = false;
    }
  }

  //Click Listeners
  async insertItem() {
    if (this.isLogin) {
      this.addloading = true;
      //Object To store item
      this.itemObject = {
        userID: this.userDetail.uid,
        userName: this.userDetail.firstName,
        title: this.informationHolder.value.title,
        titleLower: this.informationHolder.value.title.toLowerCase(),
        location: this.informationHolder.value.location,
        locationLower: this.informationHolder.value.location.toLowerCase(),
        type: this.informationHolder.value.type.categoryName,
        price1: this.informationHolder.value.price,
        priceResT: this.differentPrice.value[0]
          ? this.differentPrice.value[0]
          : '',
        details: this.informationHolder.value.description,
        forMen: this.informationHolder.value.category.includes('men')
          ? true
          : false,
        forWomen: this.informationHolder.value.category.includes('women')
          ? true
          : false,
        forChildren: this.informationHolder.value.category.includes('children')
          ? true
          : false,
        photo: [],
        priceType: '',
        segmentType: '',
        totalRating: 0,
        startDate: this.informationHolder.value.startDate
          ? new Date(this.informationHolder.value.startDate)
          : '',
        endDate: this.informationHolder.value.endDate
          ? new Date(this.informationHolder.value.endDate)
          : '',
        latitude: this.latitude,
        longitude: this.longitude,
        viewCount: 0,
        ordersCount: 0,
      };

      var urls = this._updateItem.uploadnewItem(this.uploadedImages);
      urls
        .then((value) => {
          this.itemObject.photo = value.concat(this.previousPhotos);
          if (this.itemEditID == '') {
            this._updateItem
              .insertItem(this.itemObject)
              .then((data) => {
                this._updateItem
                  .updateItem(data.id)
                  .then(() => {
                    this.addloading = false;
                    this._toast.nextMessage(
                      'Listing has been added successfully!',
                      'success'
                    );
                    this.route.navigate(['/itemdetail', data.id]);
                  })
                  .catch((err) => {
                    this.addloading = false;
                    this._toast.nextMessage(err.message, 'error');
                  });
              })
              .catch((err) => {
                this.addloading = false;
                this._toast.nextMessage(err.message, 'error');
              });
          } else {
            if (this.removedPics.length) {
              for (let pic of this.removedPics) {
                this._updateItem.deletepreviousPicture(pic);
              }
            }
            this._updateItem
              .EditItem(this.itemObject, this.itemEditID)
              .then(() => {
                this._toast.nextMessage(
                  'Listing has been edited successfully!',
                  'success'
                );
                this.route.navigate(['/itemdetail', this.itemEditID]);
              })
              .catch((err) => {
                this.addloading = false;
                this._toast.nextMessage(err.message, 'error');
              });
          }
        })
        .catch((err) => {
          this.addloading = false;
          this._toast.nextMessage(err.message, 'error');
        });

      this.informationHolder.reset();
      this.files = [];
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.route.navigateByUrl('/login');
    }
  }
  onOtherPriceClick(event) {
    if (event.checked === true) {
      if (!this.differentPrice.length) {
        this.differentPrice.push(
          this._formBuilder.control('', [
            Validators.required,
            Validators.min(0),
            Validators.max(999999),
          ])
        );
      }
    } else {
      this.differentPrice.removeAt(0);
    }
  }

  // To get  values of form
  get title() {
    return this.informationHolder.get('title');
  }
  get type() {
    return this.informationHolder.get('type');
  }
  get category() {
    return this.informationHolder.get('category');
  }
  get price() {
    return this.informationHolder.get('price');
  }
  get location() {
    return this.informationHolder.get('location');
  }
  get description() {
    return this.informationHolder.get('description');
  }
  get next_Price() {
    return this.informationHolder.get('nextdayPrice');
  }
  get differentPrice() {
    return this.informationHolder.get('differentPrice') as FormArray;
  }

  onRemove() {
    this.filesLength = this.filesLength - 1;
    this.fileLimit = 5 - this.previousPhotos.length;
    if (this.filesLength > 5) {
      this.isMoreFiles = true;
    } else {
      this.isMoreFiles = false;
    }
  }

  onClear() {
    this.filesLength = 0;
    this.fileLimit = 5 - this.previousPhotos.length;
    this.isMoreFiles = false;
  }

  startDateSelect() {
    this.minEndDate = new Date(this.informationHolder.value.startDate);
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
    this.informationHolder.get('endDate').setValue('');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
