module.exports = {
  NewTemplate(data) {
    return `
  <html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>
<p>Your request on SAAG for <b>${data.itemName}</b> has been sent out. Please wait for <b>${data.supplierName}</b> to confirm your booking.</p>
<h3>Payment Details</h3><br>
<table>
<tr>
<td><b>Item Name</b></td>
<td>${data.itemName}</td>
</tr>
<tr>
<td><b>Amount</b></td>
<td>$${(data.totalAmount).toFixed(2)}</td>
</tr>
<tr>
<td><b>Service Charges</b></td>
<td>$${(data.totalAmount * 0.15).toFixed(2)}</td>
</tr>
<tr>
<td><b>Tax</b></td>
<td>$${(data.totalAmount * 0.10).toFixed(2)}</td>
</tr>
<tr>
<td><b>Total Amount</b></td>
<td>$${(data.totalAmount * 1.25).toFixed(2)}  </td>
</tr>
<tr>
<td><b>Start Date</b></td>
<td>${(data.startDate.toLocaleDateString("en-US"))}</td>
</tr>
<tr>
<td><b>End Date</b></td>
<td>${(data.endDate.toLocaleDateString("en-US"))}</td>
</tr>
</table>
<p>Thanks for ordering,<br>
<b>SAAG Team</b>
</p>
</body>
</html>

`
  },
  SupplierTemplate(data) {
    return `<html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>
<p>Hi, <br>Following are details of your product and amount which has been transfered to your account </p>
<h3>Payment Details</h3><br>
<table>
<tr>
<td><b>Item Name</b></td>
<td>${data.itemName}</td>
</tr>
<tr>
<td><b>Amount</b></td>
<td>$${(data.totalAmount).toFixed(2)}</td>
</tr>
<tr>
<td><b>Start Date</b></td>
<td>${(data.startDate.toLocaleDateString("en-US"))}</td>
</tr>
<tr>
<td><b>End Date</b></td>
<td>${(data.endDate.toLocaleDateString("en-US"))}</td>
</tr>
</table>
<p>Thanks for supplying,<br>
<b>SAAG Team</b></p>
</body>
</html>   `
  },
  RenterOrderConfirmTemplate(data) {
    return `<html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>

<p>Your order for <b>${data.itemName}</b> has been confirmed for [RENTAL PERIOD: ${(data.startDate.toLocaleDateString("en-US"))} - ${(data.endDate.toLocaleDateString("en-US"))}]  </p>
<br/>
<p>Please log in to your SAAG account and start chatting with ${data.supplierName} to plan your gear pick-up logistics.</p>

<p>
<b>SAAG Team</b>
</p>
</body>
</html>   `
  },
  cancelOrderTemplateNot48(data) {
    return `<html>
    <head>
    <style>
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      td{
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
    background-color: #dddddd;
    }
    </style>
    </head>
  <body>
  <p>Hi, <br>You have cancelled the order so your total amount <b>excluding service charges and tax<b> will be reversed within few minutes</p>
  <h3>Order Cancel Details</h3><br>
  <table>
  <tr>
  <td><b>Item Name</b></td>
  <td>${data.itemName}</td>
  </tr>
  <tr>
  <td><b>Amount</b></td>
  <td>$${(data.totalAmount).toFixed(2)}</td>
  </tr>
  <tr>
  </table>
  <p>Thanks for your support,<br>
  <b>SAAG Team</b></p>
  </body>
  </html>   `
  },
  cancelOrderTemplate(data) {
    return `<html>
    <head>
    <style>
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }

      td{
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
    background-color: #dddddd;
    }
    </style>
    </head>
  <body>
  <p>Hi, <br>You have cancel the order and you will be charged 50% for the item. <b>Service Fees and tax is not Refundable<b> So your remaining amount will be reversed within few minutes</p>
  <h3>Order Cancel Details</h3><br>
  <table>
  <tr>
  <td><b>Item Name</b></td>
  <td>${data.itemName}</td>
  </tr>
  <tr>
  <td><b>Amount</b></td>
  <td>$${(((data.totalAmount * 50) / 100) + 0.25).toFixed(2)}</td>
  </tr>
  <tr>
  </table>
  <p>Thanks for your support,<br>
  <b>SAAG Team</b></p>
  </body>
  </html>   `
  },
  NewSupplierTemplateRented(data) {
    return `<html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>
<p>
<b>${data.renderName}</b> has booked the <b>${data.itemName}</b> from [${(data.startDate.toLocaleDateString("en-US"))} - ${(data.endDate.toLocaleDateString("en-US"))}].
</p>

<p>Please log in to your SAAG account <a target="_blank" href="${data.link}">Here</a> to confirm this rental by starting to chat with Sharmin Zirak and plan your gear pick-up logistics.</p>

<p>Thanks for supplying,<br>
<b>SAAG Team</b>
</p>
</body>
</html>   `
  },
  ErrorSupplierTransfer(data) {
    return `<html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>
<p>Hi Neda,<br>There is a pending payout that needs to be transferred manually.</p>
<h3><b>Supplier <b>Transfer <b>Payment <b>Failure:</h3><br>
<table>
<tr>
<td><b>Error Message</b></td>
<td>${data.errorMsg}</td>
</tr>
<td><b>Amount</b></td>
<td>$${(data.amount)}</td>
</tr>
<tr>
<td><b>OrderID</b></td>
<td>${(data.orderID)}</td>
</tr>
<tr>
<td><b>SupplierID</b></td>
<td>${(data.supplierID)}</td>
</tr>
<tr>
<td><b>Supplier Email</b></td>
<td>${(data.supplierEmail)}</td>
</tr>
<tr>
<td><b>Stripe Account ID</b></td>
<td>${(data.stripe_user_id)}</td>
</tr>
<tr>
</table>
<p>Thanks for supplying,<br>
Regards SAAG</p>
</body>
</html>   `
  },
  reviewRequestRenterTemplate(data) {
    return `<html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>
<p>Hi, <br>Following are the order details for your rented product:</p>
<h3>Payment Details</h3><br>
<table>
<tr>
<td><b>Item Name</b></td>
<td>${data.itemName}</td>
</tr>
<td><b>Start Date</b></td>
<td>${(data.startDate.toLocaleDateString("en-US"))}</td>
</tr>
<tr>
<td><b>End Date</b></td>
<td>${(data.endDate.toLocaleDateString("en-US"))}</td>
</tr>
</table>
<p>Please visit <a href="${data.url}">${data.url}</a> and click on "Order Feedback" button to leave a review.<br>
<p>Thanks for ordering,<br>
<b>SAAG Team</b></p>
</body>
</html>   `
  },
  reviewRequestSupplierTemplate(data) {
    return `<html>
  <head>
  <style>
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
  background-color: #dddddd;
  }
  </style>
  </head>
<body>
<p>Hi, <br>Following are the order details for your rented product:</p>
<h3>Payment Details</h3><br>
<table>
<tr>
<td><b>Item Name</b></td>
<td>${data.itemName}</td>
</tr>
<td><b>Start Date</b></td>
<td>${(data.startDate.toLocaleDateString("en-US"))}</td>
</tr>
<tr>
<td><b>End Date</b></td>
<td>${(data.endDate.toLocaleDateString("en-US"))}</td>
</tr>
</table>
<p>Please visit <a href="${data.url}">${data.url}</a> and click on "Order Feedback" button to leave a review.<br>
<p>Thanks for supplying,<br>
<b>SAAG Team</b></p>
</body>
</html>   `
  },
  renterOrderApprovalTemplate(data) {
    return `<html>
<body>
<p>Hi,
<br>We hope you're doing well! We wanted to inform you that your rental order for ${data.itemName}
 has been approved.<br>
<br>Thanks for ordering,
<br><b>SAAG Team</b>
</p>
</body>
</html>`
  },
  renterOrderDenialTemplate(data) {
    return `<html>
<body>
<p>Hi,
<br>We hope you're doing well! We wanted to inform you that your rental order for ${data.itemName}
 has been denied by the supplier. Your total amount will be reversed.<br>
<br>Thanks for ordering,
<br><b>SAAG Team</b>
</p>
</body>
</html>`
  },
  supplierOrderApprovalTemplate(data) {
    return `<html>
<body>
<p>Hi,
<br>We wanted to notify you that the rental order for ${data.itemName} has been approved.<br>
<br>Thanks for supplying,
<br><b>SAAG Team</b>
</p>
</body>
</html>`
  },
  supplierOrderDenialTemplate(data) {
    return `<html>
<body>
<p>Hi,
<br>We wanted to notify you that the rental order for ${data.itemName} has been denied.<br>
<br>Thanks for supplying,
<br><b>SAAG Team</b>
</p>
</body>
</html>`
  },
}
