import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { ComplaintRenterComponent } from '../complaint-renter/complaint-renter.component';
import { ComplaintSupplierComponent } from '../complaint-supplier/complaint-supplier.component';
import { environment } from '../../../environments/environment';
import { UpdateItemService } from 'src/app/services/update-item.service';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import * as moment from 'moment';
import 'src/assets/smtp.js'
import *as Template from '../../shared/email-templates/templates.js'
import {EmailServiceService} from 'src/app/services/email-service.service'
@Component({
  selector: 'app-my-items',
  templateUrl: './my-items.component.html',
  styleUrls: ['./my-items.component.scss'],
  providers: [DialogService],
})
export class MyItemsComponent implements OnInit {
  results:any;
  myOrder:any;
  userAccountId:any;

  productData:any;
  items: MenuItem[];
  pic;
  totalRecords = 24;
  lastdoc;
  lastdocCheck;
  mydata;
  mainLoading: boolean;
  ifOrderedTab: boolean;
  filterArray: Observable<any[]>;
  activeItem: MenuItem;
  savedID;
  savedArray;
  savedItems;
  isLogin: boolean = false;
  userID;
  searchWord;
  subscriptions;
  filters;
  ifRentedAwayTab: boolean;
  filterBy;
  noSaved: boolean = false;
  isSavedTab: boolean;
  isSuppliedTab: boolean;
  isHistoryTab: boolean;
  rangeValues: number[] = [0, 0];
  min: number = 0;
  max: number = 999;
  users: any;
  supplierEmail: any;
  startDate:any;
  endDate:any;
  orderResult:any;
  userEmail: string;
  constructor(
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private _fetch: FetchitemsService,
    public _logininfo: LoginsignupService,
    private dialodeServies: DialogService,
    public _toast: SearchKeywordService,
    private _put: UpdateItemService,
    private emailService:EmailServiceService
  ) {
    this.subscriptions = new Array<Subscription>();
    this.filters = [
      { name: 'Filter by Title' },
      { name: 'Filter by Location' },
      { name: 'Filter by Price' },
    ];
    this.filterBy = { name: 'Filter by Title' };
    this.subscriptions.push(
      _logininfo.isSignin().subscribe((uid) => {
        if (uid) {
          this.isLogin = true;
          this.userID = uid.uid;
          this.userEmail = uid.email;
        }
      })
    );

    this.items = [
      {
        label: 'My Orders',
        icon: 'pi pi-fw pi-home',
        command: () => {
          this.redirectTo('/myitems/ordered');
        },
      },
      {
        label: 'My Listings',
        icon: 'pi pi-fw pi-calendar',
        command: () => {
          this.redirectTo('/myitems/supplied');
        },
      },
      {
        label: 'Saved',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.redirectTo('/myitems/saved');
        },
      },
      {
        label: 'Rented Away',
        icon: 'pi pi-dollar',
        command: () => {
          this.redirectTo('/myitems/rentedAway');
        },
      },
      {
        label: 'Order History',
        icon: 'pi pi-check-circle',
        command: () => {
          this.redirectTo('/myitems/orderHistory');
        },
      },
    ];

    this.pic = '../../../assets/images/noImage.jpg';
    this.mainLoading = true;
    this.lastdoc = new Array();
    this.lastdocCheck = new Array();
    this.lastdoc.push(null);
    this.lastdocCheck.push(0);
    if (this.route.snapshot.paramMap.get('keyword') == 'ordered') {
      this.ifOrderedTab = true;
      this.isHistoryTab = false;
      this.isSuppliedTab = false;
      this.isSavedTab = false;
      this.ifRentedAwayTab = false;
      this.activeItem = this.items[0];
    } else if (this.route.snapshot.paramMap.get('keyword') == 'supplied') {
      this.ifOrderedTab = false;
      this.isHistoryTab = false;
      this.isSuppliedTab = true;
      this.isSavedTab = false;
      this.ifRentedAwayTab = false;
      this.activeItem = this.items[1];
    } else if (this.route.snapshot.paramMap.get('keyword') == 'saved') {
      this.ifOrderedTab = false;
      this.isHistoryTab = false;
      this.isSuppliedTab = false;
      this.isSavedTab = true;
      this.ifRentedAwayTab = false;
      this.activeItem = this.items[2];
    } else if (this.route.snapshot.paramMap.get('keyword') == 'rentedAway') {
      this.ifOrderedTab = false;
      this.isHistoryTab = false;
      this.isSuppliedTab = false;
      this.isSavedTab = false;
      this.ifRentedAwayTab = true;
      this.activeItem = this.items[3];
    } else if (this.route.snapshot.paramMap.get('keyword') == 'orderHistory') {
      this.ifOrderedTab = false;
      this.isHistoryTab = true;
      this.isSuppliedTab = false;
      this.isSavedTab = false;
      this.ifRentedAwayTab = false;
      this.activeItem = this.items[4];
    }
  }

  ngOnInit(): void { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  detail(id) {
    if (this.isLogin) {
      this._put.increaseViewCount(id, this.userID);
    } else {
      this._put.increaseViewCount(id);
    }
    this.router.navigate(['/itemdetail', id]);
  }

  loadData(event) {
    this.noSaved = false;
    this.mainLoading = true;

    this.savedArray = this._fetch.fetchSavedArray(this.userID);
    this.subscriptions.push(
      this.savedArray.subscribe((r_array) => {
        if (r_array[0]) {
          if (!r_array[0].saved.length) {
            this.noSaved = true;
          }
          this.savedItems = r_array[0].saved;
          this.savedID = r_array[0].savedID;

          if (this.route.snapshot.paramMap.get('keyword') == 'ordered') {
            this.subscriptions.push(
              this._fetch.fetchOrderedArray(this.userID).subscribe((array) => {
                this.totalRecords = array.length;
                this._fetch
                  .fetchOrderedOfUser(
                    this.userID,
                    this.lastdoc[event.first / event.rows]
                  )
                  .then((data) => {
                    this.subscriptions.push(
                      data.subscribe((dat) => {
                        if (dat && array.length) {
                          this.filterArray = dat;
                          this.subscriptions.push(
                            dat.subscribe((da) => {
                              this.mydata = [];
                              for (let item of da) {
                                if (item.type != 'modified') {
                                  this.mydata.push(item);
                                  this.lastdoc[event.first / event.rows + 1] =
                                    (event.first / event.rows + 1) * 24;
                                }
                              }
                              this.mainLoading = false;
                            })
                          );
                        } else {
                          this.mydata = [];
                          this.mainLoading = false;
                        }
                      })
                    );
                  });
              })
            );
          } else if (
            this.route.snapshot.paramMap.get('keyword') == 'supplied'
          ) {
            this.filterArray = this._fetch.fetchSupplied(
              this.userID,
              this.lastdoc[event.first / event.rows]
            );
            this.subscriptions.push(
              this.filterArray.subscribe((data) => {
                if (data.length) {
                  this.mydata = data;
                  this.lastdoc[event.first / event.rows + 1] = this.mydata[
                    this.mydata.length - 1
                  ].payload.doc;
                  this.mainLoading = false;
                  this.subscriptions.push(
                    this._fetch
                      .fetchSupplied(
                        this.userID,
                        this.lastdoc[event.first / event.rows + 1]
                      )
                      .subscribe((data) => {
                        if (!this.lastdocCheck[event.first / event.rows + 1]) {
                          this.totalRecords += data.length;
                          this.lastdocCheck[event.first / event.rows + 1] = 1;
                        }
                      })
                  );
                } else {
                  this.mainLoading = false;
                  this.mydata = [];
                }
              })
            );
          } else if (this.route.snapshot.paramMap.get('keyword') == 'saved') {
            this.totalRecords = r_array[0].saved.length;
            let tempArr = JSON.parse(JSON.stringify(r_array[0].saved));
            this._fetch
              .fetchSaved(tempArr.splice((event.first / event.rows) * 24, 24))
              .then((data) => {
                this.mainLoading = true;
                if (data) {
                  this.filterArray = data;
                  this.subscriptions.push(
                    data.subscribe((data) => {
                      if (data.length) {
                        this.mydata = data;
                      } else {
                        this.mydata = [];
                      }
                      this.mainLoading = false;
                    })
                  );
                } else {
                  this.mydata = [];
                  this.mainLoading = false;
                }
              });
          } else if (
            this.route.snapshot.paramMap.get('keyword') == 'rentedAway'
          ) {
            this.subscriptions.push(
              this._fetch
                .fetchRentedAwayArray(this.userID)
                .subscribe((array) => {
                  this.totalRecords = array.length;
                  this._fetch
                    .fetchRentedAwayOfUser(
                      this.userID,
                      this.lastdoc[event.first / event.rows]
                    )
                    .then((data) => {
                      this.subscriptions.push(
                        data.subscribe((dat) => {
                          if (dat) {
                            this.filterArray = dat;
                            this.subscriptions.push(
                              dat.subscribe((da) => {
                                this.mydata = da;
                                this.lastdoc[event.first / event.rows + 1] =
                                  (event.first / event.rows + 1) * 24;
                                this.mainLoading = false;
                              })
                            );
                          } else {
                            this.mydata = [];
                            this.mainLoading = false;
                          }
                        })
                      );
                    });
                })
            );
          } else if (
            this.route.snapshot.paramMap.get('keyword') == 'orderHistory'
          ) {
            this.subscriptions.push(
              this._fetch
                .fetchOrderedHistoryArray(this.userID)
                .subscribe((array) => {
                  this.totalRecords = array.length;
                  this._fetch
                    .fetchOrderedHistoryOfUser(
                      this.userID,
                      this.lastdoc[event.first / event.rows]
                    )
                    .then((data) => {
                      this.subscriptions.push(
                        data.subscribe((dat) => {
                          if (dat) {
                            this.filterArray = dat;
                            this.subscriptions.push(
                              dat.subscribe((da) => {
                                this.mydata = da;
                                this.lastdoc[event.first / event.rows + 1] =
                                  (event.first / event.rows + 1) * 24;
                                this.mainLoading = false;
                              })
                            );
                          } else {
                            this.mydata = [];
                            this.mainLoading = false;
                          }
                        })
                      );
                    });
                })
            );
          }
        }
      })
    );
  }

  savedbtn(id) {
    if (this.savedItems.indexOf(id) >= 0) {
      this.savedItems.splice(this.savedItems.indexOf(id), 1);
      this._fetch.updateSavedArray(this.savedID, this.savedItems);
    } else {
      this.savedItems.push(id);
      this._fetch.updateSavedArray(this.savedID, this.savedItems);
    }
  }

  ifLiked(id) {
    if (this.savedItems.indexOf(id) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  filter() {
    if (this.filterBy.name == 'Filter by Title') {
      this.subscriptions.push(
        this.filterArray
          .pipe(
            map((items) =>
              items.filter((item) =>
                item.payload.doc
                  .get('title')
                  .toLowerCase()
                  .includes(this.searchWord.toLowerCase())
              )
            )
          )
          .subscribe((data) => {
            this.mydata = data;
          })
      );
    } else if (this.filterBy.name == 'Filter by Location') {
      this.subscriptions.push(
        this.filterArray
          .pipe(
            map((items) =>
              items.filter((item) =>
                item.payload.doc
                  .get('location')
                  .toLowerCase()
                  .includes(this.searchWord.toLowerCase())
              )
            )
          )
          .subscribe((data) => {
            this.mydata = data;
          })
      );
    }
  }

  handleChange(event) {
    if (this.filterBy.name == 'Filter by Price') {
      this.subscriptions.push(
        this.filterArray
          .pipe(
            map((items) =>
              items.filter(
                (item) =>
                  item.payload.doc.get('price1') >= event.values[0] &&
                  item.payload.doc.get('price1') <= event.values[1]
              )
            )
          )
          .subscribe((data) => {
            this.mydata = data;
          })
      );
    }
  }

  completeOrder(id, itemid, supplierID) {
    this.mainLoading = true;
    this._put.pickedUpStripTransaction(id).subscribe((data) => {
      this.subscriptions.push(
        data.subscribe((dat) => {
          this.subscriptions.push(
            dat.subscribe((da)=> {
              da.then(()=>{
                this._put.increaseOrderCount(itemid);
                this._fetch.fetchuserbyIdasync(supplierID).subscribe((data) => {
                  this.users = data[0];
                  this.supplierEmail = this.users.email;
                  this._fetch.fetchbyitemid(itemid).subscribe((data) => {
                    if (this.productData) {
                      return;
                    }
                    this.productData = data[0];
                    this._fetch.fetchOrderDetails(id).subscribe((result) => {
                      this.orderResult = result[0];
                      this.startDate = new Date(this.orderResult.startDate.seconds * 1000);
                      this.endDate = new Date(this.orderResult.endDate.seconds * 1000);
                      var obj = {
                        itemName: this.productData.title,
                        totalAmount: this.orderResult.totalPrice * 1.25,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        url: `${environment.baseUrl}/myitems/rentedAway`,
                      };
                      var dataEmail = {
                        email: this.supplierEmail,
                        message: Template.SupplierTemplate(obj),
                      };
                      this.emailService.emailNotification(dataEmail).subscribe(result=>{
                        this.results = result;
                      });
                      var reviewRequestSupplierEmail = {
                        email: this.supplierEmail,
                        message: Template.reviewRequestSupplierTemplate(obj),
                      };
                      this.emailService.emailNotification(reviewRequestSupplierEmail).subscribe(result=>{
                        this.results = result;
                      });
                    });

                  });
                });
                this.mainLoading = false;
                this.dialodeServies.open(ComplaintRenterComponent, {
                  contentStyle: {height: 'fit-content', overflow: 'auto'},
                  baseZIndex: 10000,
                  showHeader: false,
                  closable: true,
                  data: {
                    id,
                    email: this.userEmail,
                  },
                });
              })
            },
            (err)=>{
                    this.mainLoading = false;
                    var ErrorString = err.error.message;
                    console.log(err.error.message);
                    if(err.error.message){
                      this._put.pickedUpStripeErrorTransaction(id,ErrorString).subscribe((data) => {
                        this.subscriptions.push(
                          data.subscribe((dat) => {
                            this.subscriptions.push(
                              dat.subscribe((da)=> {
                                da.then(() => {
                                  this._put.increaseOrderCount(itemid);
                                  this._fetch.fetchuserbyIdasync(supplierID).subscribe((data) => {
                                    this.users = data[0];
                                    this.supplierEmail = this.users.email;
                                    this._fetch.fetchbyitemid(itemid).subscribe((data) => {
                                      if (this.productData) {
                                        return;
                                      }
                                      this.productData = data[0];
                                      this._fetch.fetchOrderDetails(id).subscribe((result) => {
                                        this.orderResult = result[0];
                                        this.startDate = new Date(this.orderResult.startDate.seconds * 1000);
                                        this.endDate = new Date(this.orderResult.endDate.seconds * 1000);
                                        var obj = {
                                          itemName: this.productData.title,
                                          totalAmount: this.orderResult.totalPrice * 1.25,
                                          startDate: this.startDate,
                                          endDate: this.endDate,
                                          url: `${environment.baseUrl}/myitems/rentedAway`,
                                        };
                                        var dataEmail = {
                                          email: this.supplierEmail,
                                          message: Template.SupplierTemplate(obj),
                                        };
                                        this.emailService.emailNotification(dataEmail).subscribe(result => {
                                          this.results = result;
                                        });
                                        var reviewRequestSupplierEmail = {
                                          email: this.supplierEmail,
                                          message: Template.reviewRequestSupplierTemplate(obj),
                                        };
                                        this.emailService.emailNotification(reviewRequestSupplierEmail).subscribe(result => {
                                          this.results = result;
                                        });
                                      });
                                    });
                                  });
                                  this.mainLoading = false;
                                  this.dialodeServies.open(ComplaintRenterComponent, {
                                    contentStyle: { height: 'fit-content', overflow: 'auto' },
                                    baseZIndex: 10000,
                                    showHeader: false,
                                    closable: true,
                                    data: {
                                      id,
                                      email: this.userEmail
                                    },
                                  });
                                })
                              })
                            )
                          }))
                      })
                    }
                  })
          )
        }))
  })
  }

  openComlaintRenterDialog(id) {
    this.dialodeServies.open(ComplaintRenterComponent, {
      contentStyle: {height: 'fit-content', overflow: 'auto'},
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
      data: { id },
    });
  }

  cancelOrder(id, startDate) {
    if (
      moment(new Date(startDate.seconds * 1000)).isSame(
        moment(new Date()),
        'day'
      )
    ) {
      this.mainLoading = true;
      this._put.pickedUpStripTransaction(id).subscribe((data) => {
        this.subscriptions.push(
          data.subscribe((dat) => {
            this.subscriptions.push(
              dat.subscribe((da)=> {
                da.then(()=>{
                  this.mainLoading = false;
                })
              })
            )
          }))
      })
      this.dialodeServies.open(ComplaintRenterComponent, {
        contentStyle: { height: 'fit-content', overflow: 'auto' },
        baseZIndex: 10000,
        showHeader: false,
        closable: true,
        data: {
          id,
          email: this.userEmail
        },
      });
    } else {
      this.dialodeServies.open(CancelOrderComponent, {
        contentStyle: { height: 'fit-content', overflow: 'auto' },
        baseZIndex: 10000,
        showHeader: false,
        closable: true,
        data: { id: id },
      });
    }
  }

  completeOrderRented(id) {
    this.dialodeServies.open(ComplaintSupplierComponent, {
      contentStyle: { height: 'fit-content', overflow: 'auto' },
      baseZIndex: 10000,
      showHeader: false,
      closable: true,
      data: { id: id },
    });
  }

  viewProfile(id) {
    this.router.navigate(['/viewprofile', id]);
  }

  approveOrder(id, itemID, renterID) {
    this._put.approveOrder(id);
    this._fetch.fetchuserbyIdasync(renterID).subscribe(data => {
      this.users = data[0];
      this._fetch.fetchbyitemid(itemID).subscribe((item) => {
        const itemName = item[0].title ?? "Deleted Item";
        const renterEmail = {
          email: this.users.email,
          message: Template.renterOrderApprovalTemplate({
            itemName,
          }),
        };
        const supplierEmail = {
          email: this.userEmail,
          message: Template.supplierOrderApprovalTemplate({
            itemName,
          }),
        };
        this.emailService.emailNotification(renterEmail).subscribe();
        this.emailService.emailNotification(supplierEmail).subscribe();
      });
    });
  }

  async denyOrder(id, itemID, renterID) {
    this.mainLoading = true;
    const result = await this._put.denyOrder(id);
    if (result) {
      this._fetch.fetchuserbyIdasync(renterID).subscribe(users => {
        this.users = users[0];
        this._fetch.fetchbyitemid(itemID).subscribe((item) => {
          const itemName = item[0].title ?? 'Deleted Item';
          const renterEmail = {
            email: this.users.email,
            message: Template.renterOrderDenialTemplate({
              itemName,
            }),
          };
          const supplierEmail = {
            email: this.userEmail,
            message: Template.supplierOrderDenialTemplate({
              itemName,
            }),
          };
          this.emailService.emailNotification(renterEmail).subscribe();
          this.emailService.emailNotification(supplierEmail).subscribe();
        });
      });
    }
    this.mainLoading = false;
  }

  onPage() {
    window.scroll(0, 0);
  }

  findListing() {
    this.router.navigateByUrl('/items/allListing');
  }

  addListing() {
    this.router.navigateByUrl('/additem');
  }

  orderNow() {
    this.router.navigateByUrl('/items/allListing');
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.baseUrl + '/itemdetail/' + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toast.nextMessage('Link Copied to Clipboard', 'success');
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
