<div class="p-grid p-nogutter">
    <div class="overlay" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="paymentform p-grid p-nogutter">
            <div class="innerdiv p-col-12 p-sm-11 p-md-9 p-lg-7 p-xl-5">
                <div class="flex-container">
                    <i (click)="backHome()" class="fa fa-arrow-left backHome"></i>
                    <h3>Payment Details</h3><br>
                </div><br>
                <table>
                    <tr>
                        <td><b>Item Name</b></td>
                        <td>{{ itemName }}</td>
                    </tr>
                    <tr>
                        <td><b>Amount</b></td>
                        <td>${{ totalAmount | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td><b>Service Charges</b></td>
                        <td>${{ totalAmount*0.15 | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td><b>Tax</b></td>
                        <td>${{ totalAmount*0.10 | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td><b>Total Amount</b></td>
                        <td>${{ totalAmount*1.25 | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                        <td><b>Start Date</b></td>
                        <td>{{ startDate | date }}</td>
                    </tr>
                    <tr>
                        <td><b>End Date</b></td>
                        <td>{{ endDate | date }}</td>
                    </tr>
                </table><br>
                <div class="flexed"><button class="payBtn" pButton (click)="click()" type="submit" label="Pay"></button>
                </div>
            </div>
        </div>
    </div>
</div>