import { Component } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { NavigationStart, Router } from '@angular/router';
import { SearchKeywordService } from './services/search-keyword.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  val: number = 3;
  value: Date;
  showHead = true;

  constructor(
    private primengConfig: PrimeNGConfig,
    public router: Router,
    public messageService: MessageService,
    public _toast: SearchKeywordService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login') {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
    });
    _toast.sharedKeyword.subscribe((msg) => {
      _toast.sharedSeverity.subscribe((severity) => {
        if (msg != '') {
          if (severity == 'success') {
            setTimeout(() => {
              this.messageService.clear('myKey1');
              this.messageService.add({
                key: 'myKey1',
                severity: severity,
                summary: 'Success',
                detail: msg,
              });
            });
          } else if (severity == 'error') {
            setTimeout(() => {
              this.messageService.clear('myKey1');
              this.messageService.add({
                key: 'myKey1',
                severity: severity,
                summary: 'Error',
                detail: msg,
              });
            });
          } else if (severity == 'warn') {
            setTimeout(() => {
              this.messageService.clear('myKey1');
              this.messageService.add({
                key: 'myKey1',
                severity: severity,
                summary: 'Warning!',
                detail: msg,
              });
            });
          }
        }
      });
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
