import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  faqs;
  subscriptions;
  safeURL;
  loading: boolean = true;
  myURL;

  constructor(
    public _fetch: FetchitemsService,
    private _sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      _fetch.fetchFaqs().subscribe((faqs) => {
        this.faqs = faqs;
        this.faqs = this.faqs.faqs;
        this.subscriptions.push(
          _fetch.fetchHelpVideo().subscribe((url) => {
            this.myURL = url;
            this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
              this.myURL.url1
            );
            this.loading = false;
          })
        );
      })
    );
  }

  backHome() {
    this.router.navigateByUrl('/home');
  }

  ngOnInit(): void {}
}
