<div class="bigdiv">
  <div class="header flexed"></div>
  <div class="body">
    <div class="center">
      <button
        (click)="stripeData()"
        pButton
        label="Go back to becomming a supplier "
      ></button>
    </div>
  </div>
</div>
