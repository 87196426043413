import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { UpdateItemService } from 'src/app/services/update-item.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import 'src/assets/smtp.js'
import { LowerCasePipe } from '@angular/common';
import *as Template from '../../shared/email-templates/templates.js'
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import {EmailServiceService} from 'src/app/services/email-service.service';
import {StripeCheckoutServiceService} from 'src/app/services/stripe-checkout-service.service'
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  results: any
  order;
  startDate;
  endDate;
  loading: boolean = true;
  paymentHandler: any = null;
  token: string;
  subscriptions;
  itemName: string = '';
  totalAmount;
  response;
  payKey;
  username: string;
  renderFullName: string = '';
  password: string;
  userEmail: any;
  myVariables:any;
  supplierID:any;
  supplierEmail:any;
  publishablekey:any;
  constructor(
    public _order: SearchKeywordService,
    private _put: UpdateItemService,
    private router: Router,
    private _http: HttpClient,
    private route: ActivatedRoute,
    private _fetch: FetchitemsService,
    private _loginServices: LoginsignupService,
    private emailService: EmailServiceService,
    private checkout: StripeCheckoutServiceService
  ) {
    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      _loginServices.isSignin().subscribe(data => {
        this.userEmail = data
        _fetch.getUserByUID(data.uid).then((user) => {
          user.subscribe(data => {
            // @ts-ignore
            this.renderFullName = data.firstName;
          });
        });
      }));
    this.subscriptions.push(
      _fetch.getEnvironmentVariables().subscribe((vars) => {
        this.myVariables = vars;
        this.publishablekey = this.myVariables.publishablekey;
      }))
    this.loading = false;
  }

  ngOnInit(): void {
    this.invokeStripe();
    this.subscriptions.push(
      this._order.sharedOrderObj.subscribe((order) => {
        if (order != '') {
          this.order = JSON.parse(order);
          this.itemName = this.order.itemName;
          this.startDate = this.order.startDate;
          this.endDate = this.order.endDate;
          this.totalAmount = this.order.totalPrice;

          if (this.order.endDate != '') {
            localStorage.setItem('endDate', this.order.endDate);
          }

          localStorage.setItem('itemName', this.order.itemName);
          localStorage.setItem('startDate', this.order.startDate);
          localStorage.setItem('itemID', this.order.itemID);
          localStorage.setItem('renterID', this.order.renterID);
          localStorage.setItem('supplierID', this.order.supplierID);
          localStorage.setItem('supplierName', this.order.supplierName);
          localStorage.setItem('totalPrice', this.order.totalPrice);
        } else {
          this.itemName = localStorage.getItem('itemName');
          this.startDate = localStorage.getItem('startDate');
          this.endDate = localStorage.getItem('endDate');
          this.totalAmount = parseFloat(localStorage.getItem('totalPrice'));
        }
      })
    );
  }

  click() {
    if (this.itemName != '') {
      this.loading = false;
      const paymentHandler = (<any>window).StripeCheckout.configure({
        key: this.publishablekey,
        locale: 'auto',
        token: function (stripeToken: any) {
          paymentstripe(stripeToken);
        },
      });
      paymentHandler.open({
        name: 'Welcome To SAAG',
        description: 'Please Enter Your Card Details.',
        amount: this.totalAmount * 100 * 1.25 ,
      });
      const paymentstripe = (stripeToken: any) => {
        var data = {
          stripeToken:stripeToken,
          amount: this.totalAmount * 1.25
        }
        this.loading = true;
        this.checkout.makePayment(data).subscribe((dat: any) => {
          console.log("I am here with charge data", dat);
          if (dat.data == "success") {
            this.startDate = localStorage.getItem('startDate');
            this.endDate = localStorage.getItem('endDate');
            this.subscriptions.push(
              this._put
                .putOrderItem({
                  startDate: new Date(
                    localStorage.getItem('startDate')
                  ),
                  endDate: new Date(localStorage.getItem('endDate')),
                  itemID: localStorage.getItem('itemID'),
                  isCompleted: false,
                  renterID: localStorage.getItem('renterID'),
                  supplierID: localStorage.getItem('supplierID'),
                  totalPrice: localStorage.getItem('totalPrice'),
                  paymentObject: dat,
                  isCanceled: false,
                  canceledAfter48: false,
                  canceledBefor48: false,
                  supplierReview: false,
                })
                .subscribe((data) => {
                  data
                    .then(() => {
                      this.supplierID = localStorage.getItem('supplierID');
                      this._fetch.fetchuserbyIdasync(this.supplierID).subscribe(result => {
                        this.supplierEmail = result[0];
                        var supplierTemplateRented = {
                          renderName: this.renderFullName,
                          itemName: this.itemName,
                          startDate: new Date(this.startDate).toDateString(),
                          endDate: new Date(this.endDate).toDateString(),
                          link: environment.baseUrl + "/login"
                        }
                        var SupplierEmail = {
                          email: this.supplierEmail.email,
                          message: Template.NewSupplierTemplateRented(supplierTemplateRented)
                        }
                        this.emailService.emailNotification(SupplierEmail).subscribe(result => {
                          this.results = result;
                        })
                      })
                      var dataTemplate = {
                        supplierName: localStorage.getItem('supplierName'),
                        itemName: this.itemName,
                        totalAmount: this.totalAmount,
                        startDate: new Date(this.startDate).toDateString(),
                        endDate: new Date(this.endDate).toDateString(),
                      }
                      var dataEmail = {
                        email: this.userEmail.email,
                        message: Template.NewTemplate(dataTemplate)
                      }
                      this.emailService.emailNotification(dataEmail).subscribe(result => {
                        this.results = result;
                        if (this.results.data.message === 'sent') {
                          this._order.nextMessage('Reserved Successfully. An email is sent to you. Please check your email for order details',
                            'success');
                        }
                      })
                      localStorage.clear();
                      this.loading = false;
                      this.router.navigateByUrl('/myitems/ordered');
                    })
                    .catch((err) => {
                      this._order.nextMessage(err.message, 'error');
                      this.loading = false;
                    });
                })
            );
          }

        });
      };
    }
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.publishablekey,
          locale: 'auto',
          token: function (stripeToken: any) {
            console.log(stripeToken);
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }

  backHome() {
    if (localStorage.getItem('itemID') != null) {
      this.router.navigate(['/itemdetail', localStorage.getItem('itemID')]);
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
