<div class="bg">
    <div class="p-grid p-nogutter bigdiv">
        <div class="formDiv p-col-12 p-md-12 p-lg-12">
            <div class="overlay" *ngIf="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div class="flexedcenter">
                <h2>Feedback</h2>
            </div><br>
            <div><p class="para">We’re always working to improve the SAAG experience, so we’d love to hear what’s working and how we
                can do better.
                This isn’t a way to contact us, though. We can’t respond to feedback or bug reports individually. If
                you have a question or need help resolving a problem, you’ll find answers in our Help Center, or you
                can contact us.</p></div><br>
            <form class="form" [formGroup]="feedbackForm" (ngSubmit)="insertfeedback()">
                <div class="form-group">
                    <label>Topic:</label>
                    <input [class.is-invalid]="feedbackForm.get('Topic').invalid && feedbackForm.get('Topic').touched"
                        formControlName="Topic" type="text" class="form-control" pInputText>
                    <div *ngIf="feedbackForm.get('Topic').invalid && feedbackForm.get('Topic').touched">
                        <small *ngIf="feedbackForm.get('Topic').errors?.required" class="text-danger">Topic is
                            required</small>
                        <small *ngIf="feedbackForm.get('Topic').errors?.maxlength" class="text-danger">Topic must
                            be less than 50 characters.</small>
                    </div>
                </div>
                <div class="form-group">
                    <label>Feedback Description:</label>
                    <textarea
                        [class.is-invalid]="feedbackForm.get('feedBack').invalid && feedbackForm.get('feedBack').touched"
                        rows="4" formControlName="feedBack" type="text" class="form-control" pInputTextarea
                        autoResize="autoResize"></textarea>
                    <div *ngIf="feedbackForm.get('feedBack').invalid && feedbackForm.get('feedBack').touched">
                        <small *ngIf="feedbackForm.get('feedBack').errors?.required" class="text-danger">Description is
                            required</small>
                        <small *ngIf="feedbackForm.get('feedBack').errors?.maxlength" class="text-danger">Description
                            must
                            be less than 500 characters.</small>
                    </div>
                </div>
                <div class="flexedright"><button class="btn" pButton label="Submit" type="submit"
                        [disabled]="!feedbackForm.valid"></button></div>
            </form>
        </div>
    </div>
</div>