<div class="overlay" *ngIf="addloading">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="bigdiv">
    <div class="header flexed">
        <h4>Order Cancellation</h4>
        <i class="fa fa-close" (click)="close()"></i>
    </div>
    <div class="body">
        <ul *ngIf="within48Hrs">
            <li>If you cancel order, you will not be charged for the item</li>
            <li>Service Fees and tax is not Refundable</li>
        </ul>

        <ul *ngIf="!within48Hrs">
            <li>If you cancel order, you will not be charged for the item</li>
            <li>Service Fees and tax is not Refundable</li>
        </ul>
        <div class="flexedend"><button (click)="submit()" pButton label="Agree & Continue"></button>
        </div>
    </div>