import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FetchitemsService } from 'src/app/services/fetchitems.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { UpdateItemService } from 'src/app/services/update-item.service';

@Component({
  selector: 'app-complaint-supplier',
  templateUrl: './complaint-supplier.component.html',
  styleUrls: ['./complaint-supplier.component.scss'],
})
export class ComplaintSupplierComponent implements OnInit {
  selectedValue: string;
  disabled: boolean = false;
  uploadedImages = [];
  description: string = '';
  headerHeading: string;
  orderID;
  orderItem;
  userRating: number = 0;
  addloading = false;
  subscriptions;
  totalLength: number = 0;

  constructor(
    private dialodeServies: DialogService,
    private _updateItem: UpdateItemService,
    public config: DynamicDialogConfig,
    public _fetch: FetchitemsService,
    public _toast: SearchKeywordService
  ) {
    this.subscriptions = new Array<Subscription>();
    this.headerHeading = 'Feedback';
    this.orderID = config.data.id;
    this.subscriptions.push(
      _fetch
        .fetchOrderDetails(this.orderID)
        .subscribe((data) => (this.orderItem = data[0]))
    );
  }

  ngOnInit(): void {}

  close() {
    this.dialodeServies.dialogComponentRefMap.forEach((item) => item.destroy());
  }

  continue() {
    if (this.selectedValue == 'no') {
      this.disabled = true;
      this.headerHeading = 'File a Complaint';
      this._updateItem.supplierReviewed(this.orderID);
    } else if (this.selectedValue == 'yes') {
      this._updateItem.userRatingfromSupplier(
        this.orderItem.renterID,
        this.userRating
      );
      this._updateItem.supplierReviewed(this.orderID);
      this.dialodeServies.dialogComponentRefMap.forEach((item) =>
        item.destroy()
      );
    }
  }

  picUploader(event) {
    this.uploadedImages = event.currentFiles;
    this.totalLength = event.currentFiles.length;
  }

  submit() {
    if (
      this.description != '' &&
      this.totalLength <= 5 &&
      this.totalLength >= 1
    ) {
      this.addloading = true;
      var urls = this._updateItem.uploadnewItem(this.uploadedImages);
      urls.then((value) => {
        let obj = {
          photo: value,
          itemID: this.orderItem.itemID,
          complainAgainst: this.orderItem.renterID,
          userID: this.orderItem.supplierID,
          description: this.description,
          complainAs: 'supplier',
        };
        this._updateItem.insertComplaintSupplier(obj).then((data) => {
          this._updateItem.updateComplaintSupplier(data.id).then(() => {
            this._updateItem.userRatingfromSupplier(
              this.orderItem.renterID,
              this.userRating
            );
            this.addloading = false;
            this._toast.nextMessage(
              'Your Complain has been registered successfully!',
              'success'
            );
            this.dialodeServies.dialogComponentRefMap.forEach((item) =>
              item.destroy()
            );
          });
        });
      });
    }
  }

  onRemove() {
    this.totalLength -= 1;
  }
  onClear() {
    this.totalLength = 0;
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
