import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginsignupService } from 'src/app/services/loginsignup.service';
import { SearchKeywordService } from 'src/app/services/search-keyword.service';
import { UpdateItemService } from 'src/app/services/update-item.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  feedbackForm = this._formBuilder.group({
    Topic: ['', [Validators.required, Validators.maxLength(50)]],
    feedBack: ['', [Validators.required, Validators.maxLength(500)]],
    source: ['Web'],
    userID: [''],
  });
  loading: boolean = false;
  subscriptions;

  constructor(
    private _formBuilder: FormBuilder,
    public _login: LoginsignupService,
    private _updateItem: UpdateItemService,
    private _toast: SearchKeywordService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      _login.isSignin().subscribe((user) => {
        if (user) {
          this.feedbackForm.get('userID').setValue(user.uid);
        } else if (this.route.snapshot.paramMap.get('email')) {
          this.feedbackForm
            .get('userID')
            .setValue(this.route.snapshot.paramMap.get('email'));
        }
      })
    );
  }

  ngOnInit(): void {}

  insertfeedback() {
    if (this.feedbackForm.get('userID').value != '') {
      this.loading = true;
      this._updateItem
        .uploadFeedback(this.feedbackForm.value)
        .then(() => {
          this.feedbackForm.reset();
          this.loading = false;
          this._toast.nextMessage('Thank you for your Feedback!', 'success');
        })
        .catch((err) => {
          this.loading = false;
          this._toast.nextMessage(err.message, 'error');
        });
    } else {
      this._toast.nextMessage('You need to Login first!', 'warn');
      this.router.navigateByUrl('/login');
    }
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
