<div class="container">
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
  <div *ngIf="!loading">
    <div class="p-grid p-nogutter">
      <div class="p-col-12 p-md-4">
        <div class="leftdivinner">
          <div class="flexed">
            <img class="dp" [src]="user.photo?user.photo:'../../../assets/images/dp.png'" alt="dp">
          </div><br>
          <h5>Name</h5>
          <p>{{ user.firstName }}</p>
          <hr>
          <p class="textOverflow"><i class="fa fa-star starIcon"></i> {{ user.supplierRating | number: '1.1-1' }}</p>
          <p class="textOverflow"><i class="fa fa-map-marker locationIcon"></i> {{ user.userLocation?user.userLocation:'N/A' }}</p>
        </div>
      </div>
      <div class="p-col-12 p-md-8 rightdiv">
        <div class="divinner">
          <h3 class="hi">Hi, I'm {{ user.firstName }}</h3>
          <h6 class="joined">Joined in {{ user.accountCreate.seconds *1000 | date }}</h6>
          <h5>Email</h5>
          <p>{{ user.email }}</p>
          <hr>
          <h5>Gender</h5>
          <p>{{ user.gender?user.gender:'Not Specified' }}</p>
          <hr>
          <h5>About</h5>
          <p>{{ user.userDetail?user.userDetail:'Not Specified' }}</p>
          <hr>
          <h5>Languages</h5>
          <p class="lastP">{{ user.userLanguage?user.userLanguage:'Not Specified' }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<div *ngIf="!loading && !noSupplied">
  <div class="upperdiv">
    <div class="container containerbg">
      <h3 class="heading">Supplied Items of {{ user.firstName }}</h3>
      <p-carousel [value]="supplied" [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptionswanted"
        circular="true" [autoplayInterval]="3000">
        <ng-template let-data pTemplate="item">
          <div class="outerCaroItem">
            <div class="caroitem">
              <div>
                <p class="price">${{data.payload.doc.get('price1')}}</p>
                <button pButton type="button" icon='fa fa-heart' class="p-button-rounded"
                  [ngClass]='{ likebtnRed : ifLiked(data.payload.doc.get("id")), likebtn: !ifLiked(data.payload.doc.get("id")) }'
                  (click)='savedbtn(data.payload.doc.get("id"))'></button>
              </div>
              <img *ngIf='data.payload.doc.get("photo")' class="itemimg" (click)='detail(data.payload.doc.get("id"))'
                [src]='data.payload.doc.get("photo")[0]?data.payload.doc.get("photo")[0]:pic' alt="image">
              <img *ngIf='!data.payload.doc.get("photo")' class="itemimg" [src]='pic' alt="image"
                (click)='detail(data.payload.doc.get("id"))'>
              <h5 class="itemtitle textOverflowTitle">{{ data.payload.doc.get('title') }}</h5>
              <div class="p-grid p-nogutter itemfooter">
                <div class="p-col-3 rating">
                  <p class="location"><i class="fa fa-star starIcon"></i>
                    {{ data.payload.doc.get('totalRating') | number: '1.0-0' }}</p>
                </div>
                <div class="p-col-8">
                  <p class="location"><i class="fa fa-map-marker locationIcon"></i>
                    {{ data.payload.doc.get('location') }}</p>
                </div>
                <div class="p-col-1 detailPreview">
                  <i (click)='copyMessage(data.payload.doc.get("id"))' class="fa fa-share-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
  <br><br>
</div>