<div class="overlay" *ngIf="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="header flexed">
  <h4>Enter Credentials</h4>
  <i class="fa fa-close" (click)="close()"></i>
</div>
<!-- TDF FORM -->
<div class="form">
  <form #userForm="ngForm" [formGroup]="signupForm" (ngSubmit)="SignUp()">
    <div class="p-grid p-nogutter">
      <div class="p-col-12 p-md-6">
        <div class="form-group">
          <!-- Username -->
          <label>Username:</label>
          <input pInputText required type="text" [class.is-invalid]="username.invalid && username.touched"
            class="form-control" name="username" formControlName="userName">
          <small class="text-danger" [class.d-none]="username.valid || username.untouched">Username is required and must
            be less than 20 characters</small>
        </div>
        <!-- Password -->
        <div class="form-group">
          <label>Password:</label>
          <i *ngIf='typePassword=="password"' class="fa fa-eye-slash" id="togglePassword" (click)="passView()"></i>
          <i *ngIf='typePassword=="text"' class="fa fa-eye" id="togglePassword" (click)="passView()"></i>
          <input pInputText [type]="typePassword" [class.is-invalid]='password.invalid && password.touched'
            class="form-control" name="password" formControlName="userPassword">
          <div *ngIf="password.errors && (password.invalid && password.touched)">
            <small class="text-danger" *ngIf="password.errors.required ">Password is required</small>
            <small class="text-danger" *ngIf="password.errors.minlength">Password must have length equal or greater than 6
              characters
            </small>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6">

        <!-- Email -->
        <div class="form-group">
          <label>Email:</label>
          <input pInputText required type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            [class.is-invalid]="email.invalid && email.touched" class="form-control" name="email"
            formControlName="userEmail">
          <div *ngIf="email.errors && (email.invalid && email.touched)">
            <small class="text-danger" *ngIf="email.errors.required ">Email is required</small>
            <small class="text-danger" *ngIf="email.errors.pattern">Enter correct email </small>
          </div>
        </div>
        <div class="form-group">
          <label>Confirm Password:</label>
          <i *ngIf='typePasswordConfirm=="password"' class="fa fa-eye-slash" id="togglePassword"
            (click)="passConfirmView()"></i>
          <i *ngIf='typePasswordConfirm=="text"' class="fa fa-eye" id="togglePassword" (click)="passConfirmView()"></i>
          <input [type]="typePasswordConfirm" pInputText
            [class.is-invalid]='confirmPassword.invalid && confirmPassword.touched || signupForm.get("userPassword").value!=signupForm.get("userConfirmPassword").value'
            class="form-control" name="confirmPassword" formControlName="userConfirmPassword">
          <div *ngIf="confirmPassword.errors && (confirmPassword.invalid && confirmPassword.touched)">
            <small class="text-danger" *ngIf="confirmPassword.errors.required ">Confirm password is required</small>
            <small class="text-danger" *ngIf="confirmPassword.errors.minlength">Confirm Password must have length
              equal or greater
              than 6 characters
            </small>
          </div>
          <small class="text-danger"
            *ngIf='confirmPassword.touched && signupForm.get("userPassword").value!=signupForm.get("userConfirmPassword").value'>Password
            and Confirm
            Password must match </small>
        </div>
      </div>
    </div>

    <!-- receive email checkbox -->
    <!-- <div class="form-group">
      <p-checkbox class="receiveemail" label="Receive email from SAAG" formControlName="receiveEmail" [binary]="true">
      </p-checkbox>
    </div> -->
    <div class="form-group">
      <p-checkbox class="receiveemail" formControlName="terms" [binary]="true">
      </p-checkbox>
      <label class="terms">I have read and accepted <a (click)="isTerms()">Terms of Use and Privacy Policy</a></label>
    </div>
    <div *ngIf="termsContent" class="termsContent">
      <hr>
      <ul>
        <li>This is terms and privacy policy for SAAG</li>
        <li>This is terms and privacy policy for SAAG</li>
        <li>This is terms and privacy policy for SAAG</li>
        <li>This is terms and privacy policy for SAAG</li>
        <li>This is terms and privacy policy for SAAG</li>
        <li>This is terms and privacy policy for SAAG</li>
      </ul>
      <hr>
    </div>
    <div class="flexedend">
      <button pButton type="submit" class="p-button-rounded" icon="fa fa-user" label="Sign Up"
        [disabled]='userForm.invalid || signupForm.get("userPassword").value!=signupForm.get("userConfirmPassword").value || !terms.value'></button>
    </div>
  </form>

</div>